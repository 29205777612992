import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { formatDate } from "../../utils";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "localized_name",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Branches",
      dataIndex: "branches",
      key: "num_of_branches",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.branches.map(obj => obj.name).slice(0, 3).join(", ")}
        {record.branches.length - 3 > 0 ? ` ....+${record.branches.length - 3}` : ""}
      </div>
    },
    {
      title: "Creating Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => {
        return (
          <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
            <div>{formatDate(record.created_at, i18n.resolvedLanguage)}</div>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];