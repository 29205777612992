import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchSingleProduct = (id, t, i18n) => {
  return useQuery(["product", id], () => {
    const params = {};
    return apiClient
      ?.get(`products/${id}`, { params })
      .then(({ data }) => {
        const product = {
          ...data?.data,
          title: data?.data.localized_product_title.find(obj => obj.locale == i18n.resolvedLanguage)?.value,
          measurement_unit_title: data?.data.measurement_unit.localized_measurement_unit.find(obj => obj.locale == i18n.resolvedLanguage)?.value
        }
        return product;
      });
  });
};
