import React, { useEffect, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import DateRangePickerComponent from "../../Components/DateRangePicker/DateRangePicker";
import Input from "../../Components/Input/Input";


export default function BrandsFilters({ register, setDateRange }) {
  const [showBranches, setShowBranches] = useState(true);
  const [showStatus, setShowStatus] = useState(true);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if(selectionRange[0].startDate && selectionRange[0].endDate) {
      setDateRange(selectionRange.map(obj => {
        return {
          ...obj,
          startDate: new Date(obj.startDate).toISOString().slice(0, 19).replace('T', ' '),
          endDate: new Date(obj.endDate).toISOString().slice(0, 19).replace('T', ' '),
        }
      }));
    }
  }, [selectionRange]);

  const handleToggleBranches = () => {
    setShowBranches(!showBranches);
  };
  const handleToggleStatus = () => {
    setShowStatus(!showStatus);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Branches</span>
        {showBranches ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleBranches}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleBranches}
          />
        )}
      </div>
      {
        showBranches ? (<div className="w-full flex justify-between items-center px-2">
          <div className="w-[30%] overflow-hidden">
            <input
              className="w-full border border-[#FB2576] p-2 rounded"
              {...register("branchesStart")}
              type="number"
            />
          </div>
          <span>TO</span>
          <div className="w-[30%] overflow-hidden">
            <input
              className="border border-[#FB2576] w-full p-2 rounded"
              {...register("branchesEnd")}
              type="number"
            />
          </div>
        </div>) : null
      }

      <div className="flex justify-between items-center mb-4 p-3">
        <span>Status</span>
        {showStatus ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        )}{" "}
      </div>
      {showStatus ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={1}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Enable</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={0}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Disable</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}

      <div className="max-w-full overflow-hidden">
        <DateRange
          editableDateInputs={true}
          onChange={item => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectionRange}
          rangeColors={['#fb2576', '#fb2576', '#fb2576']}
        />
      </div>
    </div>
  );
}
