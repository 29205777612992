import React from 'react'
import {HiPlus} from 'react-icons/hi';

export default function TableHeader({header,btnName,handleModal}) {
  return (
    <div className='py-5 flex w-full justify-between items-center'>
      <div>
        <h1 className='text-3xl font-bold text-table-header-text'>{header}</h1>
      </div>
      <div>
        <button className='bg-primary-color py-2 px-4 rounded-lg text-white font-bold ' onClick={handleModal}>
            <div className='flex items-center'>
              {btnName} <HiPlus className='ml-2 font-bold'/>
            </div>
        </button>
      </div>
    </div>
  )
}
