import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addProduct = async(product) => {
  return await apiClient?.post(`products`, product);

};
export const useMutateAddProduct = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addProduct, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("all-products");
      navigate("/products");
    }
  });
};
