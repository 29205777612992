import { useMutation } from "react-query";
import apiClient from '../../services/apiClient'
import { useNavigate } from "react-router-dom";
const updatePassword =async (data) => {
  return await apiClient?.post(`users/update_password`,data);

};
export const useMutateUpdatePassword = () => {
  let navigate = useNavigate();

  return useMutation(updatePassword, {
    onSuccess: () => {
      localStorage.removeItem("update_password_token");
      navigate("/login");
    },
  });
};
