import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient'
const register = async(user) => {
  return await apiClient?.post(`auth/register`,user);

};
export const useMutateRegister = () => {
  let navigate = useNavigate();
  return useMutation(register, {
    onSuccess: (data) => {
      localStorage.setItem("userToken", data?.data.data.token);
      localStorage.removeItem("request_accepted");
      localStorage.removeItem("request_for_access_token");
      localStorage.removeItem("invitationToken");
      navigate("/");
    },

  });
};
