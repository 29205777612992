import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient'
const acceptInvitation = async(user) => {
  return await apiClient?.post(`user_invitations/accept`,user);

};
export const useMutateAcceptInvitation = () => {
  let navigate = useNavigate();
  return useMutation(acceptInvitation, {
    onSuccess: (data) => {
        localStorage.setItem("userToken", data?.data.data.token);
        localStorage.removeItem("request_for_access_token");
        localStorage.removeItem("invitationToken");
        localStorage.removeItem("request_accepted");
      navigate("/");
    },
 
  });
};

   // onError: (error) => {
    //   toast.error(error.response.data.message, {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    //   return error;
    // },