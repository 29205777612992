import React, { useEffect, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import DateRangePickerComponent from "../../Components/DateRangePicker/DateRangePicker";
import Input from "../../Components/Input/Input";


export default function FetchCustomerFilters({ register, setDateRange }) {
  const [showModules, setShowModules] = useState(true);
  const [showStatus, setShowStatus] = useState(true);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if(selectionRange[0].startDate && selectionRange[0].endDate) {
      setDateRange(selectionRange.map(obj => {
        return {
          ...obj,
          startDate: new Date(obj.startDate).toISOString().slice(0, 19).replace('T', ' '),
          endDate: new Date(obj.endDate).toISOString().slice(0, 19).replace('T', ' '),
        }
      }));
    }
  }, [selectionRange]);

  const handleToggleModules = () => {
    setShowModules(!showModules);
  };
  const handleToggleStatus = () => {
    setShowStatus(!showStatus);
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Modules</span>
        {showModules ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleModules}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleModules}
          />
        )}
      </div>
      {showModules ? (
        <ul className="">
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="">
                <input
                  className="accent-pink-500"
                  {...register("modules")}
                  type="checkbox"
                  value={1}
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Inframodern</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
            <div className="">
                <input
                  className="accent-pink-500"
                  {...register("modules")}
                  type="checkbox"
                  value={2}
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Trustree</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
            <div className="">
                <input
                  className="accent-pink-500"
                  {...register("modules")}
                  type="checkbox"
                  value={3}
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Inframenu</label>
              </div>
            </div>
          </li>
        </ul>
      ) : (
        ""
      )}
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Status</span>
        {showStatus ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        )}{" "}
      </div>
      {showStatus ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={1}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Enable</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={0}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Disable</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}

      <div className="max-w-full overflow-hidden">
        <DateRange
          editableDateInputs={true}
          onChange={item => setSelectionRange([item.selection])}
          moveRangeOnFirstSelection={false}
          ranges={selectionRange}
          rangeColors={['#fb2576', '#fb2576', '#fb2576']}
        />
      </div>
    </div>
  );
}
