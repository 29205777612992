import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./Locale/en.json";
import arTranslation from "./Locale/ar.json";
i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    resources: {
      ar: {
        translation: arTranslation,
    },
      en: {
        translation: enTranslation,
    },
    },
  });
  export default i18next;
