import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input/Input";
import LoginIcon from "../../Icons/LoginIcon/Login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useOAuthMutation } from "../../Hooks/reactQuery/useOAuthMutation";

export default function Auth() {
  let navigate = useNavigate();
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
  });

  const [searchParams] = useSearchParams();

  const { mutate } = useOAuthMutation();

  const onSubmit = (request, e) => {
    const oauthParams = {};
    [
      "client_id",
      "redirect_uri",
      "response_type",
      "grant_type",
      "state",
    ].forEach((type) => {
      oauthParams[type] = searchParams.get(type);
    });

    mutate({ ...request, ...oauthParams });
  };

  return (
    <div className="min-h-screen  ">
      <div className="flex flex-col">
        <BackToHomeAuth />
        <div className="flex-1 flex flex-col items-center">
          <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col  ">
            <div className="flex justify-center mt-10 items-center">
              <LoginIcon />
              <span className="ml-2 text-2xl font-medium">Login</span>
            </div>
            {/* login form */}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="border border-gray-300 rounded-lg p-5 mt-6 mb-7">
                <div className="mb-3">
                  <Input
                    name="email"
                    type="text"
                    label={t("email")}
                    fieldName={t("email")}
                    hint={t("login_email_hint")}
                    register={register}
                    errors={errors?.email}
                    required={true}
                    pattern={{
                      regex:
                        /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                      errorMessage: t("invalid_email"),
                    }}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="password"
                    fieldName={t("password")}
                    label={t("password")}
                    hint={t("login_password_hint")}
                    required={true}
                    minLength="8"
                    maxLength="16"
                    errors={errors?.password}
                  />
                </div>
              </div>
              <button
                className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
                type="submit"
                disabled={!isValid}
              >
                {t("login")}
              </button>
            </form>
            <div className="flex flex-col justify-center items-center mt-5">
              <span
                className="text-primary-color text-sm font-bold cursor-pointer"
                onClick={() => {
                  navigate("/forgetPassword");
                }}
              >
                {t("forget_password")}
              </span>
              <span
                className=" text-sm font-medium mt-5 cursor-pointer"
                onClick={() => {
                  navigate("/rfa");
                }}
              >
                {t("create_new_account")}
              </span>
            </div>
            {/* /register/token */}
          </div>
        </div>
      </div>
    </div>
  );
}
