import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";


const DropdownMenu = ({ 
  options, 
  buttonRef,
  showMenu, 
  setShowMenu,
  onOpen = () => {}, 
  onClose = () => {}, 
}) => {

  const {t, i18n} = useTranslation();

  const optionHeight = 45;
  const menuHeight = optionHeight * options.length;

  const menu = useRef(null);

  const [offset, setOffset] = useState(null);
  const [position, setPosition] = useState("bottom");

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setShowMenu(false);
    });
    document.addEventListener("click", e => {
      if(menu && menu.current && buttonRef && buttonRef.current) {
        if(!menu.current.contains(e.target) && !buttonRef.current.contains(e.target)){
          setShowMenu(false);
        }
      }
    }, true);

    return () => {
      document.removeEventListener("scroll", () => {
        setShowMenu(false);
      });
      document.removeEventListener("click", e => {
        if(menu && menu.current && buttonRef && buttonRef.current) {
          if(!menu.current.contains(e.target) && !buttonRef.current.contains(e.target)){
            setShowMenu(false);
          }
        }
      }, true);
    }
  }, []);

  useEffect(() => {
    if(showMenu) onOpen(); else onClose();

    if(buttonRef && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();

      const offset = { 
        top: rect.top, 
        left: i18n.resolvedLanguage === "ar" ? rect.left : rect.left - menu.current.offsetWidth, 
      };

      const offsetBottom = window.innerHeight - offset.top - 40;

      offset.bottom = offsetBottom;

      if(offsetBottom < menuHeight) setPosition("top");
      else setPosition("bottom");
      setOffset(offset);
    }
  }, [showMenu]);

  return (
    <ul className={`w-auto p-2 rounded ${showMenu ? "flex flex-col" : "none"} min-w-[150px] h-auto bg-[#fff] fixed shadow-lg z-[1000]`} ref={menu}
    style = {
        offset ? position == "bottom" ? {top: offset.top + 40, left: offset.left} 
        : {bottom: offset.bottom, left: offset.left } 
        : {}
    }
    onClick={e => e.stopPropagation()}
    >
    {!options || options.length < 1 ? <span onClick={e => e.stopPropagation()}>No Available Options</span> : null}
    {options.map(option => 
    <li className={`w-full border-t-1 border-slate-600 flex justify-start items-center cursor-pointer`} style={{height: `${optionHeight}px`}} onClick={
        (e) => {
            e.stopPropagation();
            option.action();
            setShowMenu(false);
        }
    }>
        <div className="mr-2">
        {option.icon}
        </div>
        <span className="">{option.text}</span>
    </li>
    )}
    </ul>
  );
};

export default DropdownMenu;
