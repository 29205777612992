import { useTranslation } from "react-i18next";
const BackIconArrow = () => {
    const { t, i18n } = useTranslation();

    return (
        <svg width="24" transform={`${i18n.resolvedLanguage==='ar'?"scale(-1 1)":"rotate(0deg)"}`} height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#FB2576" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13.2602 15.5302L9.74023 12.0002L13.2602 8.47021" stroke="#FB2576" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
    );
  };
  
  export default BackIconArrow;
 