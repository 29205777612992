import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetchMeasurementUnits } from "../../Hooks/reactQuery/useFetchMeasurementUnits";
import { useFetchSingleProduct } from "../../Hooks/reactQuery/useFetchSingleProduct";
import { useFetchVariants } from "../../Hooks/reactQuery/useFetchVariants";
import { useMutateEditProduct } from "../../Hooks/reactQuery/useMutateAddEditProduct";
import { languages } from "../../utils/constants";
import EditProductModal from "./editProductModal";

const EditProduct = ({ destroy, productId }) => {
  const {t, i18n} = useTranslation();
  const { data: product, isLoading: isProductLoading } = useFetchSingleProduct(productId, t, i18n);

  const defaultValues = {
    title: "",
    sku: "",
    upc: "",
    unit_of_measure_id: "",
    variants: [
      {
        variant_type_id: "",
        variant_option_id: "",
      }
    ],
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);
  const [hasVariations, setHasVariations] = useState(false);
  const [children, setChildren] = useState([]);
  const [removedImagesIds, setRemovedImagesIds] = useState([]);
  const [oldChildrenIds, setOldChildrenIds] = useState([]);
  const [validState, setValidState] = useState(true);
  const [files, setFiles] = useState([]);

  const { data: measurementUnits, isLoading: measurementUnitsLoading } = useFetchMeasurementUnits(t, i18n);


  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const { data: allVariants, isLoading: allVariantsLoading } = useFetchVariants(t, i18n);

  const addVariants = () => {
    const variantsArr = watch("variants");

    setChildren(old => old.map(obj => {
      const newVariants = [...obj.variants];
      for(let v of variantsArr) {
        if(newVariants.find(o => o.variant_type_id == v.variant_type_id && o.variant_option_id == v.variant_option_id)) {
          continue;
        }
        else {
          newVariants.push({variant_type_id: v.variant_type_id.toString(), variant_option_id: v.variant_option_id.toString()});
          setValidState(true);
        }
      }

      const title = obj.localized_product_title.map(titleObj => {
        const variantsTitleArray = [];
        for(const v of newVariants) {
          const variant_title = 
          allVariants?.variants?.find(vObj => vObj.id == v.variant_type_id)?.localized_variant_type?.find(lvObj => lvObj.locale == titleObj.locale)?.value;
          const option_title = allVariants?.variants?.find(oObj => oObj.id == v.variant_type_id)?.options?.find(oObj => oObj.id == v.variant_option_id)?.localized_variant_option?.find(voObj => voObj.locale == titleObj.locale)?.value;
          variantsTitleArray.push(`${variant_title}: ${option_title} `);
        }

        const vsTitle = `(${variantsTitleArray.join(", ")})`;

        return {
          locale: titleObj.locale,
          value: `${titleObj.value.split("(")[0]} ${vsTitle}`
        }
      });

      return {
        ...obj,
        localized_product_title: title,
        variants: newVariants,
        title: title.find(tObj => tObj.locale === i18n.resolvedLanguage)?.value 
      }
    }));
  }

  useEffect(() => {
    let newState = true;
    if(hasVariations && children.length < 1 && oldChildrenIds.length < 1) newState = false;
    if(Object.keys(localizedTitle).length < 1) newState = false;
    setValidState(newState);
  }, [children, hasVariations, localizedTitle, oldChildrenIds]);

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  useEffect(() => {
    if(product) {
      setValue("title", product.localized_product_title?.find(obj => obj.locale == languages[0].value)?.value);
      setValue("sku", product.sku, { shouldValidate: false });
      setValue("upc", product.upc, { shouldValidate: false });
      setValue("unit_of_measure_id", product.unit_of_measure_id.toString());
      clearErrors("sku");
      clearErrors("upc");
  
      setLocalizedTitle(old => {
        const newLocalizedTitle = {...old};
        for(const t of product.localized_product_title) {
          newLocalizedTitle[t.locale] = t.value;
        }
        return newLocalizedTitle;
      });
  
      if(product.children && product.children.length > 0) {
        setOldChildrenIds(product.children.map(c => c.id));
        setChildren(product.children.map(child => {
          return {
            id: child.id.toString(),
            localized_product_title: child.localized_product_title,
            title: child.localized_product_title.find(obj => obj.locale == i18n.resolvedLanguage)?.value,
            unit_of_measure_id: child.unit_of_measure_id.toString(),
            variants: child.variants.map(obj => {
              return {
                variant_type_id: obj.type_id.toString(),
                variant_option_id: obj.option_id.toString()
              }
            }),
            disabled: 0,
            sku: child.sku,
            upc: child.upc,
            measurement_unit: measurementUnits?.units?.find(obj => obj.id == child.unit_of_measure_id),
            parent_product_id: child.parent_product_id.toString()
          }
        }));
        setHasVariations(true);
      }
    }
  }, [product]);


  const { mutate, isLoading, isSuccess } = useMutateEditProduct();

  const onSubmit = async (request) => {
    const data = {
      id: product.id,
      title: JSON.stringify(Object.keys(localizedTitle).map(locale => {
        return {
          locale, 
          value: localizedTitle[locale]
        }
      })),
      sku: request.sku,
      upc: request.upc,
      unit_of_measure_id: request.unit_of_measure_id,
      removed_images_ids: JSON.stringify(removedImagesIds),
      locale: i18n.resolvedLanguage
    }

    if(children && children.length > 0) {
      data["children"] = JSON.stringify(children.map(obj => {
        const child = {
          ...obj,
          title: obj.localized_product_title.map(obj => ({locale: obj.locale, value: obj.value}))
        }
        delete child.localized_product_title;
        delete child.measurement_unit;
        delete child.customId;
        delete child.disabled;
        delete child.images;
        return child;
      }))
    }

    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    if(files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images_array", files[i]);
      }
    }

    mutate({id: product.id, edit:formData});
    destroy(false);
  };

  return (
    <div>
      <EditProductModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          children,
          setChildren,
          hasVariations,
          setHasVariations,
          isLoading,
          product,
          removedImagesIds,
          setRemovedImagesIds,
          isValid,
          isDirty,
          validState,
          files, 
          setFiles,
          addVariants
        }}
        destroy={destroy}
      />
    </div>
  );
};

export default EditProduct;
