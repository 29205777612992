import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { staticBaseUrl } from "../../utils/constants";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("title")}
      </div>,
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (text, record) => <div className="flex justify-start items-center" style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record?.images?.length > 0 ? <img className="max-w-[30px] max-h-[30px] mr-2" src={`${staticBaseUrl}${record?.images[0]?.img}`} /> : null}
        {record?.localizedTitle?.find(obj => obj.locale === (i18n.resolvedLanguage ? i18n.resolvedLanguage : "en"))?.value}
      </div>
    },
    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("sku")}
      </div>,
      dataIndex: "sku",
      key: "sku",
      render: (text, record) => {
        return (
          <div className="flex justify-start items-center" style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
            {text}
          </div>
        );
      },
      sorter: true
    },

    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("upc")}
      </div>,
      dataIndex: "upc",
      key: "upc",
      sorter: true,
      render: (text, record) => <div className="flex justify-start items-center" style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("measurement_unit")}
      </div>,
      dataIndex: "measurement_unit_title",
      key: "measurement_unit_title",
      sorter: true,
      render: (text, record) => <div className="flex justify-start items-center" style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];