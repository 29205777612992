import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchProductWasteCategories = (t, i18n) => {
  return useQuery(["product_waste_categories"], () => {
    const params = {};
    return apiClient
      ?.get(`products/waste-categories`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          wasteCategories: data?.data.rows,
        };
      });
  });
};
