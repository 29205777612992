import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { formatDate } from "../../utils";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "localized_name",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "num_of_brands",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.brands && record.brands?.length > 0 ? record.brands[0]?.name : ""}
      </div>
    },
    {
      title: "Opening Date",
      dataIndex: "opening_date",
      key: "opening_date",
      render: (text, record) => {
        return (
          <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
            <div>{formatDate(record.opening_date, i18n.resolvedLanguage)}</div>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];