import React, { useState, useEffect, useTransition } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { useMutateChangeProductStatus } from "../../Hooks/reactQuery/useMutateChangeProductStatus";
import { useFetchVariants } from "../../Hooks/reactQuery/useFetchVariants";
import VariantTypesFilters from "./variantTypesFilters";
import { useMutateEditVariantType } from "../../Hooks/reactQuery/useMutateEditVariantType";
import EditVariantType from "./editVariantType";
import ViewVariantType from "./viewVariantType";
import AddVariantType from "./addVariantType";
import UploadVariantTypes from "./uploadModal";

export default function VariationsTypes() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const { register, handleSubmit, control } = useForm({
        mode: "onSubmit",
    });

    const {mutate: mutateChangeStatus} = useMutateChangeProductStatus();


    const searchSelectArray = [
        {
            label: "Code",
            name: "code",
        },
        {
            label: "Title",
            name: "title",
        },
        {
            label: "All",
            name: "all",
        },
    ]

    const [editVariant, setEditVariantType] = useState(null);
    const [viewVariant, setViewVariant] = useState(null);

    const {mutate: mutateEditVariantType} = useMutateEditVariantType();


    const generateDropdownItems = (record) => [
        {
            key: "1",
            label: "Edit",
            icon: <CiEdit className="text-red-500" />,
            onClick: () => {
                setEditVariantType(record);
            },
        },
        {
            key: "2",
            label: record.disabled == 1 ? "Enable" : "Disable",
            icon: <AiOutlineEyeInvisible />,
            onClick: () => {
                mutateEditVariantType({id: record.id, edit: {
                    status: record.disabled ? "1" : "0",
                    locale: i18n.resolvedLanguage
                } });
            },
        },
    ];

    let pageSize = 20;
    const [columnHeaders, setColumnHeaders] = useState(getColumns(generateDropdownItems, t, i18n));
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [columnKey, setColumnKey] = useState("");
    const [order, setOrder] = useState("");
    const [variantTypes, setVariantTypes] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const handleTableChange = (pagination, filters, sorter) => {
        setOrder(sorter?.order);
        setColumnKey(sorter?.columnKey);
    };

    const [status, setStatus] = useState(null);

    const onSubmit = (request) => {
        const search = {};
        if(request.status && request.status.length == 1) setStatus(request.status[0]);
        else setStatus(null);

        if(request.searchSelect && request.searchinput) {
            let key = request.searchSelect;
            if(Array.isArray(request.searchSelect)) key = request.searchSelect[0].value;
            if(key.toLowerCase() == 'all') {
                for(const field of searchSelectArray.map(obj => obj.name).filter(obj => obj != 'all')) {
                    search[field] = request.searchinput;
                }
            }
            else {
                search[key] = request.searchinput;
            }
        }
        setSearch(JSON.stringify(search));
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const { data: variantTypesData, isLoading: variantTypesLoading } = useFetchVariants(
        t,
        i18n,
        status,
        currentPage,
        pageSize,
        search,
        columnKey,
        order
    );

    useEffect(() => {
        if(variantTypesData) {
            setVariantTypes(variantTypesData.variants);
            setTotalCount(variantTypesData.count);
        }
    }, [variantTypesData]);

    const [isAddVariantTypeOpen, setIsAddVariantTypeOpen] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    return (
        <div className="bg-inner-color h-full px-5 ">
        <TableHeader
            header={"Variations Types"}
            btnName={"Add New Variant Type"}
            handleModal={() => {
                setIsAddVariantTypeOpen(true);
            }}
        />
        <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
            <div className="flex justify-between items-center mb-5">
            <div className="flex-1">
                <SearchInput
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                searchSelectArray={searchSelectArray}
                />
            </div>
            <div className="flex">
                <FilterButton
                handleFilter={() => {
                    setIsOpenFilter(!isOpenFilter);
                }}
                />
                <TableMoreOptions setShowUploadModal={setShowUploadModal} />
            </div>
            </div>
            <Table
            rowKey={(record) => record.id}
            data={variantTypes}
            columns={columnHeaders}
            isLoading={variantTypesLoading}
            total={totalCount}
            currentPage={currentPage}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        if(event.target.tagName.toLowerCase() != "span" && event.target.tagName.toLowerCase() != "svg" && event.target.tagName.toLowerCase() != "li") {
                            setViewVariant(record);
                        }
                    },
                };
            }}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onChange={handleTableChange}
            />
        </div>
        <Filters
            setIsOpen={setIsOpenFilter}
            isOpen={isOpenFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            <VariantTypesFilters register={register} />
        </Filters>
        <Modal
            isOpen={isAddVariantTypeOpen}
            close={() => {
                setIsAddVariantTypeOpen(false);
            }}
        >
            <AddVariantType setIsOpen={setIsAddVariantTypeOpen} destroy={ () => setIsAddVariantTypeOpen(false) }/>
        </Modal>
        <Modal
            isOpen={editVariant !== null}
            close={() => {
                setEditVariantType(null);
            }}
        >
            <EditVariantType destroy={() => setEditVariantType(null)} record={editVariant} />
        </Modal>
        <Modal
            isOpen={viewVariant !== null}
            close={() => {
                setViewVariant(null);
            }}
        > 
            <ViewVariantType record={viewVariant} destroy={() => setViewVariant(null)} setEditVariantType={setEditVariantType} />
        </Modal>
        <Modal
            isOpen={showUploadModal}
            close={() => {
                setShowUploadModal(false);
            }}
        > 
            <UploadVariantTypes destroy={() => setShowUploadModal(null)} />
        </Modal>
        </div> 
    );
}
