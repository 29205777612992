const ProfileImg = ()=> {
    return (
        <svg width="33" height="42" viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.7491" cy="10.3409" r="10.3409" fill="#FB2576"/>
        <g clip-path="url(#clip0_368_31235)">
        <circle cx="16.75" cy="39.8862" r="16.25" fill="#FB2576"/>
        </g>
        <defs>
        <clipPath id="clip0_368_31235">
        <rect width="32.5" height="20.6818" fill="white" transform="translate(0.5 20.6816)"/>
        </clipPath>
        </defs>
        </svg>
        
    );
  };
  
  export default ProfileImg;
  