import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutateAddCustomer } from "../../Hooks/reactQuery/useMutateAddCustomer";
import AddCustomerModal from "./addCustomerModal";

const AddCustomer = ({ destroy }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: "",
    name: "",
    phone_numbers: [],
    emails: [],
    governorate: "",
    city: "",
    district: "",
    street: "",
    block: "",
    bul: "",
    other_info: ""
  }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });


  const { mutate, isLoading, isSuccess } = useMutateAddCustomer();

  const onSubmit = async (request) => {
    if(getValues("phone_numbers").length < 1) {
      toast.error("At least one phone number should be provided", {
        position: toast.POSITION.TOP_CENTER
      }); 
      return;
    }

    const data = {
      code: request.code,
      name: request.name,
      phone_numbers: request.phone_numbers,
      emails: request.emails.map(obj => obj.value),
      address: {
        governorate: request.governorate,
        city: request.city,
        district: request.district,
        street: request.street,
        block: request.block,
        bul: request.bul,
        other_info: request.other_info
      }
    }

    console.log(data);
    mutate(data);
    destroy();
  };

  return (
    <div>
      <AddCustomerModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          clearErrors,
          isLoading,
          isDirty,
          isValid
        }}
        destroy={destroy}
      />
    </div>
  );
};

export default AddCustomer;
