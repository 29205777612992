import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import AddNewProductModal from "./addNewProductModal";
import { useMutateAddProduct } from "../../Hooks/reactQuery/useMutateAddProduct";
import { useTranslation } from "react-i18next";
import { languages } from "../../utils/constants";

const defaultValues = {
  title: "",
  sku: "",
  upc: "",
  unit_of_measure_id: "",
  product_tag: "",
  category_id: "",
  waste_category: "",
  variants: [
    {
      variant_type_id: "",
      options: [],
    },
  ],
};

const AddNewProduct = ({ setIsOpen }) => {
  const { t, i18n } = useTranslation();
  const [localizedTitle, setLocalizedTitle] = useState([]);
  const [hasVariations, setHasVariations] = useState(false);
  const [children, setChildren] = useState([]);
  const [validState, setValidState] = useState(false);
  const [files, setFiles] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty, isValid },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    let newState = true;
    if (hasVariations && children.length < 1) newState = false;
    if (Object.keys(localizedTitle).length < 1) newState = false;
    setValidState(newState);
  }, [children, hasVariations, localizedTitle]);

  useEffect(() => {
    if (isDirty) {
      if (
        !Object.keys(localizedTitle).find(
          (key) => key === i18n.resolvedLanguage
        )
      )
        setError("title", {
          type: "custom",
          message: `You need to add a title in the currently selected language (${
            languages.find((obj) => obj.value === i18n.resolvedLanguage).label
          })`,
        });
    }
  }, [localizedTitle]);

  const { mutate, isLoading, isSuccess } = useMutateAddProduct();

  const onSubmit = async (request) => {
    const data = {
      title: JSON.stringify(
        Object.keys(localizedTitle).map((locale) => {
          return {
            locale,
            value: localizedTitle[locale],
          };
        })
      ),
      sku: request.sku,
      upc: request.upc,
      unit_of_measure_id: request.unit_of_measure_id,
      product_tag: request.product_tag,
      locale: i18n.resolvedLanguage,
      brand_ids: JSON.stringify(request.brands),
    };

    if (request.product_category && request.product_category.length > 0)
      data["category_id"] = request.product_category;
    if (request.waste_category && request.waste_category.length > 0)
      data["waste_category_id"] = request.waste_category;

    if (children && children.length > 0) {
      data["children"] = JSON.stringify(
        children.map((obj) => {
          const child = { ...obj };
          delete child.localized_product_title;
          delete child.measurement_unit;
          delete child.customId;
          delete child.disabled;
          delete child.images;
          return child;
        })
      );
    }

    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    if (request.images_array && request.images_array.length > 0) {
      for (let i = 0; i < request.images_array.length; i++) {
        formData.append("images_array", request.images_array[i]);
      }
    }

    mutate(formData);
    setIsOpen(false);
  };

  return (
    <div>
      <AddNewProductModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          children,
          setChildren,
          hasVariations,
          setHasVariations,
          isLoading,
          isDirty,
          isValid,
          validState,
        }}
        setIsOpen={setIsOpen}
        files={files}
        setFiles={setFiles}
      />
    </div>
  );
};

export default AddNewProduct;
