import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ViewBranch({ record, destroy, setEditBranch }) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  
  return (
    <div className="py-8">
      <div className="w-[1008px]">
        <div className="flex">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Branch Code:</span>
              <span className="ml-1 font-bold">{record?.code}</span>
            </div>
            {record?.localized_branch_name?.map(obj => <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Title In ({obj.locale}):</span>
              <span className="ml-1 font-bold">
                {obj.value}
              </span>
            </div>)}
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Phone:</span>
              <span className="ml-1 font-bold">
              {record?.phone_numbers?.map(obj => (obj.phone_number)).join(", ")}
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Brand:</span>
              <span className="ml-1 font-bold">{record?.brands[0]?.name}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Opening Date:</span>
              <span className="ml-1 font-bold">{record?.opening_date}</span>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <button
            className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
            >
            Ok
          </button>
          <button
            className={`w-40 border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={()=>{
              destroy();
              setEditBranch(record);
            }}
          >
            Edit
          </button>{" "}
        </div>          
      </div>
    </div>
  );
}
