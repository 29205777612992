import { useState, useEffect } from "react";
import BusinessImg from "../../Icons/BusinessImg/BusinessImg";
import { useTranslation } from "react-i18next";
const BusinessLogoInput = ({ register }) => {
  const { t, i18n } = useTranslation();
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));  
    }
  }, [selectedImage]);
  return (
    <div className="flex flex-col items-center">
      {imageUrl && selectedImage ? (
        <div className="w-16 bg-secondary-color rounded-lg h-16 flex justify-center items-center">
          <img
            src={imageUrl}
            alt={selectedImage.name}
            className="h-3/5 w-3/5"
          />
        </div>
      ) : 
      (
        <div className="w-16 bg-secondary-color rounded-lg h-16 flex justify-center items-center">
          <BusinessImg />
        </div>
      )}
      <div className="flex items-center mt-3 ">
        <label
          htmlFor="dropzone-file"
          className=" border-2 border-primary-color text-primary-color  py-1.5 px-14 rounded-lg font-semibold cursor-pointer"
        >
          {t("business_logo")}
          <input
            {...register("business_image", {
              onChange: (e) => {setSelectedImage(e.target.files[0])
              },
            })}
            type="file"
            className="hidden"
            id="dropzone-file"
            accept="image/*"
          />
        </label>
      </div>
    </div>
  );
};

export default BusinessLogoInput;
