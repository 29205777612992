import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addCustomer = async(obj) => {
  return await apiClient?.post(`/customers`, obj);

};
export const useMutateAddCustomer = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addCustomer, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("customers");
    }
  });
};
