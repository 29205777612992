import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchCustomers = (
  t, 
  i18n,
  status = null,
  pageNo = 1,
  pageSize = 100,
  search = null,
  columnKey = null,
  order = null,
  modules = null,
  startDate = null,
  endDate = null,
  ) => {
  return useQuery([
    "customers",
    status,
    pageNo,
    pageSize,
    search,
    columnKey,
    order,
    modules,
    startDate,
    endDate,
  ], () => {
    const params = {
      status,
      pageNo, 
      pageSize, 
      search, 
      startDate,
      endDate,
      modules,
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
    };
    return apiClient
      ?.get(`customers`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          customers: data?.data.rows
        }
      });
  });
};
