const LoginIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3 2H14.7C11.5 2 9.5 4 9.5 7.2V11.25H13.94L11.87 9.18C11.72 9.03 11.65 8.84 11.65 8.65C11.65 8.46 11.72 8.27 11.87 8.12C12.16 7.83 12.64 7.83 12.93 8.12L16.28 11.47C16.57 11.76 16.57 12.24 16.28 12.53L12.93 15.88C12.64 16.17 12.16 16.17 11.87 15.88C11.58 15.59 11.58 15.11 11.87 14.82L13.94 12.75H9.5V16.8C9.5 20 11.5 22 14.7 22H17.29C20.49 22 22.49 20 22.49 16.8V7.2C22.5 4 20.5 2 17.3 2Z" fill="#FB2576"/>
        <path d="M3.25 11.25C2.84 11.25 2.5 11.59 2.5 12C2.5 12.41 2.84 12.75 3.25 12.75H9.5V11.25H3.25Z" fill="#FB2576"/>
        </svg>
        
    );
  };
  
  export default LoginIcon;
  