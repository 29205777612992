import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from 'react-toastify';

const editVariantType = async(obj) => {
    return await apiClient?.patch(`products/variants/types/${obj.id}`, obj.edit);
};

export const useMutateEditVariantType = (cb = (data) => {}) => {
const queryClient = useQueryClient();
  return useMutation(editVariantType, {
    onSuccess: (data) => {
        cb(data);
        toast.info(data.data.message, {
          position: toast.POSITION.TOP_CENTER
        }); 
        queryClient.invalidateQueries("variants");
    },
  });
};
