import React from 'react';

const FilterButton = ({handleFilter}) => {
    return (
        <div className='cursor-pointer flex justify-center items-center border-2 border-primary-color py-1 px-5 rounded-lg text-primary-color text-md font-semibold'
        onClick={handleFilter}
        >
       Filter
       </div>
    );
}

export default FilterButton;
