import React, { useState, useEffect } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import { Dropdown, Menu } from "antd";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { useFetchAllUsers } from "../../Hooks/reactQuery/useFetchAllUsers";
import Table from "../../Components/Table/Table";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import FetchUserFilters from "./FetchUserFilters";
import { useNavigate } from "react-router-dom";
import { useMutateEditUser } from "../../Hooks/reactQuery/useMutateEditUser";
import Modal from "../../Components/Modal/Modal";
import AddNewUser from "./AddNewUser";
import ViewUser from "./ViewUser";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { staticBaseUrl } from "../../utils/constants";
import UploadUsers from "./uploadModal";

export default function Users() {
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();
  const [showUploadModal, setShowUploadModal] = useState(false);

  const navigate = useNavigate();
  const { register, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const searchSelectArray = [
    {
      label: "Code",
      name: "code",
    },
    {
      label: "Name",
      name: "user_name",
    },
    {
      label: "Email",
      name: "email",
    },
    {
      label: "Phone No.",
      name: "phone_number",
    },
    {
      label: "All",
      name: "all",
    },
  ]

  const pageSize = 20;
  const [currentPage, setCurrentPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [search, setSearch] = useState(null);
  const [modules, setModules] = useState(null);
  const [status, setStatus] = useState(null);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [currentDateRange, setCurrentDateRange] = useState(null);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const onSubmit = (request) => {
    const search = {};
    if(request.modules && request.modules.length > 0) setModules(JSON.stringify(request.modules));
    if(request.status && request.status.length == 1) setStatus(request.status[0]);
    if(request.searchSelect && request.searchinput) {
      let key = request.searchSelect;
      if(Array.isArray(request.searchSelect)) key = request.searchSelect[0].value;
      if(key.toLowerCase() == 'all') {
        for(const field of searchSelectArray.map(obj => obj.name).filter(obj => obj != 'all')) {
          search[field] = request.searchinput;
        }
      }
      else {
        search[key] = request.searchinput;
      }
    }
    setSearch(JSON.stringify(search));
    if(dateRange) setCurrentDateRange(dateRange[0]);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: usersData, isLoading: allUsersLoading } = useFetchAllUsers(
    currentPage,
    pageSize,
    modules,
    status,
    search,
    columnKey,
    order,
    currentDateRange?.startDate,
    currentDateRange?.endDate
  );

  useEffect(() => {
    if(usersData) {
      setUsers(usersData.users);
      setUsersCount(usersData.count);
    }
  }, [usersData]);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const {mutate} = useMutateEditUser();

  const generateDropdownItems = (record) => [
    {
      key: "1",
      label: record.status == 0 ? "Enable" : "Disable",
      icon: <AiOutlineEyeInvisible />,
      onClick: () => {
        mutate({userId: record.id, edit: {status: record.status == 0 ? '1' : '0'}});
      },
    },
    {
      key: "2",
      label: "Edit",
      icon: <CiEdit className="text-red-500" />,
      onClick: () => {
        navigate(`/editUser?id=${record.id}`);
      },
    },
  ];
  const [showViewModal, setShowViewModal] = useState(false);
  const [userId, setUserId] = useState();
  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (text, record) => {
        return (
          <div className="flex items-center" style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
            {record.img === null || record.img === "" ? (
              <div className="w-8 h-8 rounded-full flex justify-center items-center border-2 border-gray-300">
                <FaUserAlt />{" "}
              </div>
            ) : (
              <img
                src={staticBaseUrl + record.img}
                className="w-8 h-8 rounded-full "
              />
            )}
            <div className="ml-2">
              {record.first_name} {record.last_name}
            </div>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Authorized modules",
      dataIndex: "authorizedmodule",
      key: "authorizedmodule",
      sorter: true,
      render: (text, record) => {
        return (
          <div style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
            <div>
              {record.authorized_modules.map((authModule) => authModule.name).join(", ")}
            </div>
          </div>
        );
      },
    },
    {
      title: "Joining date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => {
        return (
          <div style={{opacity: record.status == "1" && !record.pending ? "1" : "0.5"}}>
            <div>{record.created_at}</div>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
            
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
  ];

  const [uploadedUsers, setUploadedUsers] = useState([]);

  return (
    <div className="bg-inner-color h-full px-5 ">
      <TableHeader
        header={"Users"}
        btnName={"Invite New User"}
        handleModal={() => {
          setIsOpen(true);
        }}
      />
      <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
        <div className="flex justify-between items-center mb-5">
          <div className="flex-1">
            <SearchInput
              register={register}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              searchSelectArray={searchSelectArray}
            />
          </div>
          <div className="flex">
            <FilterButton
              handleFilter={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
            />
            <TableMoreOptions setShowUploadModal={setShowUploadModal} />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          data={users}
          columns={columns}
          isLoading={allUsersLoading}
          total={usersCount}
          currentPage={currentPage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if(
                  event.target.tagName.toLowerCase() != "span" && event.target.tagName.toLowerCase() != "svg" && event.target.tagName.toLowerCase() != "li") {
                  setUserId(record.id);
                  setShowViewModal(true);
                }
              },
            };
          }}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onChange={handleTableChange}
        />
      </div>
      <Filters
        setIsOpen={setIsOpenFilter}
        isOpen={isOpenFilter}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      >
        <FetchUserFilters register={register} setDateRange={setDateRange} />
      </Filters>
      <Modal
        isOpen={isOpen}
        close={() => {
          setIsOpen(false);
        }}
      >
        <AddNewUser setIsOpen={setIsOpen} uploadedUsers={uploadedUsers} />
      </Modal>
      <Modal
        isOpen={showViewModal}
        close={() => {
          setShowViewModal(false);
        }}
      >
        <ViewUser userId={userId} setShowViewModal={setShowViewModal} />
      </Modal>
      <Modal
            isOpen={showUploadModal}
            close={() => {
                setShowUploadModal(false);
            }}
        > 
            <UploadUsers destroy={() => setShowUploadModal(null)} openAddModal={() => setIsOpen(true)} setUploadedUsers={setUploadedUsers} />
        </Modal>
    </div> 
  );
}
