import React, { useEffect, useState } from "react";
import ProductBoxSvg from "../../Icons/ProductBox/ProductBoxSvg";
import DollarCircleSvg from "../../Icons/DollarCircle/DollarCirlceSvg";
import Language from "../../Icons/Language/Language";
import HomeSvg from "../../Icons/HomeIcon/HomeSvg";
import ShopSvg from "../../Icons/ShopIcon/ShopSvg";
import ProfileSvg from "../../Icons/ProfileIcon/ProfileSvg";
import UsersSvg from "../../Icons/UsersIcon/UsersSvg";
import SettingSvg from "../../Icons/SettingIcon/SettingSvg";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import WorkSpaceSwitcher from "../WorkSpaceSwitcher/WorkSpaceSwitcher";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LangsDropDown from "../LangsDropDown/LangsDropDown";
import { BiChevronDown, BiChevronUp, BiLogOut } from "react-icons/bi";

export default function Test() {
  const [fillColor, setFillColor] = useState("");
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  
  //not in a state to prevent reading all this array of objs in every render as useState runs every render 
  let navItems = [
    {
      id: 1,
      name:t("home"),
      icon: HomeSvg,
      path: "/"
    },
    {
      id: 2,
      name:t("products"),
      icon: ProductBoxSvg,
      children: [
        {
          id: 1,
          name: "Products",
          path: "/products",
        },
        {
          id: 2,
          name: "Variations Types",
          path: "/variantTypes",
        },
        {
          id: 3,
          name: "Variations Options",
          path: "/variantOptions",
        },
        {
          id: 4,
          name: "Measurement Units",
          path: "/units",
        },
      ],
    },
    {
      id: 3,
      name: t("brands"),
      icon: ShopSvg,
      path: "/brands"
    },
    {
      id: 4,
      name: t("branches"),
      icon: ShopSvg,
      path: "/branches"
    },
    
    {
      id: 5,
      name:t("customers"),
      icon: ProfileSvg,
      path: "/customers",
    },

    {
      id: 6,
      name:t("users"),
      path:"/users",
      icon: UsersSvg,
    },

    {
      id: 7,
      name:t("taxes"),
      icon: DollarCircleSvg,
      path: "/taxes"
    },
    {
      id:8,
      name: i18n.resolvedLanguage === 'en' ? "English" : "العربية",
      icon: Language
    },
    {
      id: 9,
      name:t("setting"),
      icon: SettingSvg,
    }
  ];

  const [showLang,setShowLang]=useState(false)
  const [isActiveTab, setIsActiveTab] = useState();
  const [isHoveredTab, setIsHoveredTab] = useState(false);
  const [isExpandedSideBar, setIsExpandedSideBar] = useState(true);
  const [showChildren, setShowChildern] = useState(false);

  const handleChildern = () => {
    setShowChildern(old => !old);
  };

  useEffect(() => {
    console.log(showChildren);
  }, [showChildren]);


  return (
      <aside
        className={` shadow-[0_0_2px_rgba(0,0,0,0.25)]  h-full sticky top-0 transition-all duration-300 z-10  pt-4 ${
          isExpandedSideBar ? "w-64" : "w-16"
        } relative transition-all duration-300`}
      >
        {/* SideBar Toggle Button */}
        <button
          type="button"
          onClick={() => {
            setIsExpandedSideBar(!isExpandedSideBar);
          }}
          className={` absolute shadow text-pink-500 text-2xl cursor-pointer   top-20 z-30 ${i18n.resolvedLanguage === "en" ? " -right-8" : "-left-8"}  bg-white rounded-full p-1 m-4 flex justify-center items-center transition-all duration-300 transform
          }`}
        >
          {isExpandedSideBar ? (
            <RiArrowDropLeftLine />
          ) : (
            <RiArrowDropRightLine />
          )}
        </button>
        {/* Sidebar container  */}
        <div className="h-[650px] w-full flex flex-col items-center">
          <WorkSpaceSwitcher isExpandedSideBar={isExpandedSideBar} />
          <ul className="w-full px-3 mt-10 flex flex-col justify-between h-full">
            {/* Nav items  */}
            <div>
              {navItems.slice(0, 7).map((item) => {
                return (
                  <li
                    key={item.id}
                    className="cursor-pointer mb-2 relative"
                    // show children if exist on expanded sidebar by clicking of list item else by hovering (non expanded sidebar) 
                    onClick={() => {
                      setIsActiveTab(item.id);
                      setFillColor("#FB2576");
                      if (item.children && isExpandedSideBar) {
                        handleChildern();
                      }
                    }}
                    onMouseEnter={() => {
                      setIsHoveredTab(item.id);
                      setFillColor("#FB2576");
                      if (item.children && !isExpandedSideBar) {
                        handleChildern();
                      }
                    }}
                    onMouseLeave={() => {
                      setIsHoveredTab();
                      if (item.children && !isExpandedSideBar) {
                        setShowChildern(false);
                      }
                    }}
                  >
                <NavLink to={item.path}>
                  <div
                    className={`flex w-full p-2 items-center  ${
                      window.location.pathname == item.path || isHoveredTab === item.id
                        ? "bg-slate-100 text-black font-semibold  border-1 rounded-lg "
                        : "text-gray-500  font-medium "
                    }`}
                    >
                    <span className={`text-3xl ${i18n.resolvedLanguage==='en'?"mr-3":"ml-3"}`}>
                      {window.location.pathname == item.path || isHoveredTab === item.id ? (
                        <item.icon fillColor={fillColor} />
                      ) : (
                        <item.icon />
                      )}
                    </span>
                    {isExpandedSideBar && (
                      <span className="">{item.name}</span>
                    )}
                    {item.children && item.children.length > 0 ? <div className="flex justify-self-end items-center justify-end h-full flex-1">
                      {showChildren ? <BiChevronUp /> : <BiChevronDown />}
                    </div> : null}
                  </div>
                </NavLink>
                    {isExpandedSideBar && isActiveTab===item.id && item.children && showChildren ? (
                      <ul className={` mt-3 ${i18n.resolvedLanguage==='en'?"mr-auto":"ml-auto"} `}>
                        {item.children.map((subItem) => {
                          return (
                            <div onClick={e => e.stopPropagation()}>
                              <NavLink to={subItem.path}>
                                <li
                                  key={subItem.id}
                                  className={`px-11 p-2 text-gray-500 font-normal ${
                                    window.location.pathname == subItem.path ? "bg-slate-100 text-black font-semibold  border-1 rounded-lg "
                                    : "text-gray-500  font-medium"
                                  }`}
                                >
                                  {subItem.name}
                                </li>
                              </NavLink>
                            </div>
                          );
                        })}
                      </ul>
                    ) : !isExpandedSideBar &&
                      isHoveredTab === item.id &&
                      item.children &&
                      showChildren ? (
                      <ul className={`absolute bg-white shadow ${i18n.resolvedLanguage==='en'?"left-14 pl-4":"right-14 pr-4"} z-20 top-0 mb-4 text-gray-500 font-normal w-52 pt-2 `}>
                        {item.children.map((subItem) => {
                          return (
                            <div onClick={e => e.stopPropagation()}>
                              <NavLink to={subItem.path}>
                                <li className="pb-4" key={subItem.id}>
                                  {subItem.name}
                                </li>
                              </NavLink>
                            </div>
                          );
                        })}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </div>
            {/* Down Nav Items */}
            <div className="relative">
              {navItems.slice(7, navItems.length).map((item) => {
                return (
                  <li
                    key={item.id}
                    className="cursor-pointer mb-2"
                    onClick={() => {
                      setIsActiveTab(item.id);
                      setFillColor("#FB2576");
                      if(item.id===8){
                        setShowLang(!showLang)
                      }
                      else{
                        setShowLang(false)

                      }
                    }}
                    onMouseEnter={() => {
                      setIsHoveredTab(item.id);
                      setFillColor("#FB2576");
                    }}
                    onMouseLeave={() => {
                      setIsHoveredTab();
                    }}
                  >
                    <div
                      className={`flex w-full p-2 ${
                        isActiveTab === item.id || isHoveredTab === item.id
                          ? "bg-slate-100 text-black font-semibold  border-1 rounded-lg "
                          : "text-gray-500  font-medium  "
                      }`}
                    >
                      <span className={`text-3xl ${i18n.resolvedLanguage==='en'?"mr-3":"ml-3"}`}>
                        {isActiveTab === item.id || isHoveredTab === item.id ? (
                          <item.icon fillColor={fillColor} />
                        ) : (
                          <item.icon />
                        )}
                      </span>
                      {isExpandedSideBar && (
                        <span className=" ">{item.name}</span>
                      )}
                    </div>
                  </li>
                );
              })}
              {
                showLang ? <LangsDropDown/> : null

              }
            </div>
          </ul>
        </div>
      </aside>
  );
}
