import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";

import { VscSearch } from "react-icons/vsc";
import Input from "../../Components/Input/Input";
import { staticBaseUrl } from "../../utils/constants";
import { FaUserAlt } from "react-icons/fa";
import { useFetchNotifications } from "../../Hooks/reactQuery/useFetchNotifications";
import { formatDatePassed } from "../../utils";

export default function SystemLog() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

    const [notifications, setNotifications] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [term, setTerm] = useState(null);

    const onSearch = (e) => {
        if(e.target.value && e.target.value !== "") setTerm(e.target.value);
    }

    const { data: notificationsData, isLoading: notificationsLoading } = useFetchNotifications(
        t,
        i18n,
        term
    );

    useEffect(() => {
        if(notificationsData) {
            setNotifications(notificationsData.notifications);
            setTotalCount(notificationsData.count);
        }
    }, [notificationsData]);


    return (
        <div className="flex-col w-full h-auto p-8">
            <div className="flex w-full justify-between items-center py-2">
                <h1 className="text-3xl font-bold">System Log</h1>
                <Input
                    name="searchinput"
                    type="text"
                    icon={<VscSearch className={`text-gray-500`} />}
                    placeHolder={t("can_i_help_you")}
                    onChange={onSearch}
                />
            </div>

            <div className="flex-col w-full py-8">
                {notifications.map(row => (
                    <>
                        <div className="flex w-full h-[50px] justify-start items-between my-4">
                            <div className={`w-[50px] h-full flex items-center justify-start ${i18n.resolvedLanguage == "ar" ? "ml-6" : "mr-6"}`}>
                                {row.user?.img ? <img className="w-auto h-auto max-w-full max-h-full" src={staticBaseUrl + row.user.img} /> : 
                                <div className="w-[50px] h-[50px] bg-[#FFE8F0] rounded flex items-center justify-center">
                                    <FaUserAlt size="40" color="#FB2576"/>
                                </div>
                                }
                            </div>
                            <div className="flex-col flex-1 h-full justify-between items-start">
                                <h1 className="font-bold">
                                    {row.title}
                                </h1>
                                <h1>
                                    {row.text}
                                </h1>
                            </div>
                            <div className="h-full w-auto flex justify-end items-start">
                                {formatDatePassed(row.created_at, i18n.resolvedLanguage)}
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
}
