import React, { useState, useEffect, useTransition } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useFetchAllProducts } from "../../Hooks/reactQuery/useFetchAllProducts";
import { useTranslation } from "react-i18next";
import ProductsFilters from "./productsFilters";
import { useFetchMeasurementUnits } from "../../Hooks/reactQuery/useFetchMeasurementUnits";
import AddNewProduct from "./addNewProduct";
import EditProduct from "./editProduct";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { useMutateChangeProductStatus } from "../../Hooks/reactQuery/useMutateChangeProductStatus";
import ViewProduct from "./ViewProduct";
import UploadModal from "./uploadModal";

export default function Products() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const { register, handleSubmit, control } = useForm({
        mode: "onSubmit",
    });

    const {mutate: mutateChangeStatus} = useMutateChangeProductStatus();


    const searchSelectArray = [
        {
            label: "Title",
            name: "title",
        },
        {
            label: "Sku",
            name: "sku",
        },
        {
            label: "Upc",
            name: "upc",
        },
        {
            label: "Unit",
            name: "measurement_unit_title",
        },
        {
            label: "All",
            name: "all",
        },
    ]

    const [editProduct, setEditProduct] = useState(null);
    const [viewProduct, setViewProduct] = useState(null);

    const generateDropdownItems = (record) => [
        {
            key: "1",
            label: "Edit",
            icon: <CiEdit className="text-red-500" />,
            onClick: () => {
                setEditProduct(record.id);
            },
        },
        {
            key: "2",
            label: record.disabled == 1 ? "Enable" : "Disable",
            icon: <AiOutlineEyeInvisible />,
            onClick: () => {
                mutateChangeStatus({id: record.id, status: record.disabled, locale: i18n.resolvedLanguage});
            },
        },
    ];

    let pageSize = 20;
    const [columnHeaders, setColumnHeaders] = useState(getColumns(generateDropdownItems, t, i18n));
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [columnKey, setColumnKey] = useState("");
    const [order, setOrder] = useState("");
    const [products, setProducts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);


    const handleTableChange = (pagination, filters, sorter) => {
        setOrder(sorter?.order);
        console.log(sorter?.order);
        setColumnKey(sorter?.columnKey);
        console.log(sorter?.columnKey);
    };

    const [type, setType] = useState(null);
    const [measurementUnitIds, setMeasurementUnitIds] = useState(null);
    const [status, setStatus] = useState(null);

    const onSubmit = (request) => {
        const search = {};
        if(request.type && request.type.length == 1) setType(request.type[0]);
        else setType(null);
        
        if(request.status && request.status.length == 1) setStatus(request.status[0]);
        else setStatus(null);

        if(request.measurement_units && request.mesurement_units.length > 0) setMeasurementUnitIds(request.measurement_units);

        if(request.searchSelect && request.searchinput) {
            let key = request.searchSelect;
            if(Array.isArray(request.searchSelect)) key = request.searchSelect[0].value;
            if(key.toLowerCase() == 'all') {
                for(const field of searchSelectArray.map(obj => obj.name).filter(obj => obj != 'all')) {
                    search[field] = request.searchinput;
                }
            }
            else {
                search[key] = request.searchinput;
            }
        }
        setSearch(JSON.stringify(search));
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const { data: productsData, isLoading: allProductsLoading } = useFetchAllProducts(
        t,
        i18n,
        type,
        measurementUnitIds,
        status,
        currentPage,
        pageSize,
        search,
        columnKey,
        order,
    );

    useEffect(() => {
        console.log(productsData);
    }, [productsData]);


    const { data: measurementUnits, isLoading: measurementUnitsLoading } = useFetchMeasurementUnits(t, i18n);

    const [units, setUnits] = useState([]);

    useEffect(() => {
        if(measurementUnits) {
            setUnits(measurementUnits.units);
        }
    }, [measurementUnits]);


    useEffect(() => {
        if(productsData) {
            setProducts(productsData.products);
            setTotalCount(productsData.count);
        }
    }, [productsData]);

    const [isAddProductOpen, setIsAddProductOpen] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    return (
        <div className="bg-inner-color h-full px-5 ">
        <TableHeader
            header={"Products"}
            btnName={"Add New Product"}
            handleModal={() => {
                setIsAddProductOpen(true);
            }}
        />
        <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
            <div className="flex justify-between items-center mb-5">
            <div className="flex-1">
                <SearchInput
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                searchSelectArray={searchSelectArray}
                />
            </div>
            <div className="flex">
                <FilterButton
                handleFilter={() => {
                    setIsOpenFilter(!isOpenFilter);
                }}
                />
                <TableMoreOptions setShowUploadModal={setShowUploadModal} />
            </div>
            </div>
            <Table
            rowKey={(record) => record.id}
            data={products}
            columns={columnHeaders}
            isLoading={allProductsLoading}
            total={totalCount}
            currentPage={currentPage}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        if(event.target.tagName.toLowerCase() != "span" && event.target.tagName.toLowerCase() != "svg" && event.target.tagName.toLowerCase() != "li") {
                            setViewProduct(record.id);
                        }
                    },
                };
            }}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onChange={handleTableChange}
            />
        </div>
        <Filters
            setIsOpen={setIsOpenFilter}
            isOpen={isOpenFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            <ProductsFilters register={register} measurementUnits={units} />
        </Filters>
        <Modal
            isOpen={isAddProductOpen}
            close={() => {
                setIsAddProductOpen(false);
            }}
        >
            <AddNewProduct setIsOpen={setIsAddProductOpen} />
        </Modal>
        <Modal
            isOpen={editProduct !== null}
            close={() => {
                setEditProduct(null);
            }}
        >
            <EditProduct destroy={() => setEditProduct(null)} productId={editProduct} />
        </Modal>
        <Modal
            isOpen={viewProduct !== null}
            close={() => {
                setViewProduct(null);
            }}
        > 
            <ViewProduct productId={viewProduct} destroy={() => setViewProduct(null)} setEditProduct={setEditProduct} />
        </Modal>

        <Modal
            isOpen={showUploadModal}
            close={() => {
                setShowUploadModal(false);
            }}
        > 
            <UploadModal destroy={() => setShowUploadModal(null)} />
        </Modal>
        </div> 
    );
}
