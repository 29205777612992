import { useQuery } from "react-query";
import apiClient from '../../services/apiClient'

const fetchModules = async() => {
  return await apiClient?.get(`modules`);
};
export const useFetchModules=()=>{
  return useQuery('modules',fetchModules,{
    select:({data})=>{
      return data.data
    }
  }
  )
}
