import Select, { components } from "react-select";
import { useState } from "react";
import Selector from "../../Icons/Selector/Selector";
import { useTranslation } from "react-i18next";

export default function SelectMenu({
  Controller,
  control,
  name,
  label,
  placeholder,
  hint,
  Icon,
  fieldName,
  isLoading,
  isError,
  options,
  defaultValue,
  required,
  validate,
  errors,
  maxMenuHeight,
  changeValue: clearSelectedValue,
  icon,
  onChangeHandler = () => {},
  onOpenHandler = () => {},
}) {
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const DropdownIndicator = () => (
    <div className="px-3 py-2">{icon ? icon : <Selector />}</div>
  );
  const onOpen = () => {
    onOpenHandler();
    setOpen(true);
  };
  // Default styles
  const styles = {
    container: (provided, state) => ({
      ...provided,
      dir: i18n.resolvedLanguage === "en" ? "ltr" : "rtl",
      direction: i18n.resolvedLanguage === "en" ? "ltr" : "rtl",
    }),
    singleValue: (provided, state) =>({
      ...provided,
      color: icon&& '#FB2576',
      fontWeight:icon&&"600"
    }),
    control: (provided, state) => ({
      ...provided,
      color: icon&& '#FB2576',
      minHeight: "unset",
      border: icon ? "2px solid #9CA3AF" : "none",
      borderBottom:icon&&"none",
      outline: "none",
      boxShadow:
        state.menuIsOpen && errors
          ? "0 0 0 2px #EF4444"
          : errors
          ? "0 0 0 1px #FCA5A5"
          : state.menuIsOpen && icon
          ? "none"
          : state.menuIsOpen
          ? "0 0 0 2px #FB2576"
          :icon?"none"
          : "0 0 0 1px #D7DADF",
      transition: "none",
      maxHeight: "38px",
      overflow: "hidden",
      "&:hover": icon&&{
        border: "2px solid #97A6BA",
        borderBottom:icon&&"none",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#FEF9FC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 2000,
    }),
    menuList: (provided) => ({
      ...provided,
      overflow: "overlay",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
  };
  return (
    <div>
      <div className={label ? "mb-2" : ""}>
        {label && (
          <label
            htmlFor={name}
            className="text-sm text-body font-medium min-w-max"
          >
            {label}
          </label>
        )}
      </div>

      <div className={`${icon||placeholder?"" : ""}`}>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          rules={{
            required: required
              ? t("required_field", { field_name: fieldName ? fieldName : "" })
              : null,
            validate: validate && validate,
          }}
          render={({ field: { onChange, onBlur, value } }) => {
      
           return (
              <Select
                menuIsOpen={open}
                onMenuOpen={() => onOpen()}
                onMenuClose={() => setOpen(false)}
                maxMenuHeight={maxMenuHeight}
                isLoading={isLoading}
                options={options}
                onChange={(val) => {
                  onChange(val.value);
                  onChangeHandler(val.value);
                }}
                value={options ? options.find((c) => c.value === value) : ""}
                onBlur={(e) => onBlur(e)}
                defaultValue={defaultValue}
                placeholder={placeholder ? placeholder : ""}
                styles={styles}
                components={{
                  DropdownIndicator,
                }}
              />
            );
          }}
        />
      </div>
      {errors && (
        <p className="text-xs leading-5 font-medium text-error">
          {errors.message}
        </p>
      )}
      {hint && !errors && (
        <p className="mt-1 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </div>
  );
}
