import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addVariantOption = async(obj) => {
  return await apiClient?.post(`products/variants/options`, obj);

};
export const useMutateAddVariantOption = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addVariantOption, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("variant-options");
    }
  });
};
