import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import AddNewUserModal from "./AddNewUserModal";
import { useMutateAcceptInvitation } from "../../Hooks/reactQuery/useMutateSendInvitation";

const AddNewUser = ({ setIsOpen, uploadedUsers }) => {
  const defaultValues = {
    userData: uploadedUsers && uploadedUsers.length > 0 ? uploadedUsers.map(obj => {
      return {
        email: obj.email,
        job_title_id: "",
        brand_id: "",
        authorized_modules: []
      }
    }) : [
      {
        email: "",
        job_title_id: "",
        brand_id: "",
        authorized_modules: [],
      },
    ],
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    errors,
    watch,
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if(uploadedUsers && uploadedUsers.length > 0) {
      console.log(uploadedUsers);
      setValue("userData", uploadedUsers.map(obj => {
        return {
          email: obj.email,
          job_title_id: "",
          brand_id: "",
          authorized_modules: []
        }
      }));
    }
  }, [uploadedUsers]);

  useEffect(() => {
    console.log(watch("userData"));
  }, [watch("userData")]);

  const { mutate, isSuccess } = useMutateAcceptInvitation();
  const onSubmit = async (request) => {
    mutate(request.userData);
    if (isSuccess) {
      setIsOpen(false);
    }
  };
  return (
    <div>
      <AddNewUserModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
        }}
        setIsOpen={setIsOpen}
        // arrayLength={arrayLength}
      />
    </div>
  );
};

export default AddNewUser;
