import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ViewCustomer({ record, destroy, setEditCustomer }) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  
  return (
    <div className="py-8">
      <div className=" w-[1008px]">
        <div className="flex">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Code:</span>
              <span className="ml-1 font-bold">{record.code}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Name:</span>
              <span className="ml-1 font-bold">
                {record.name}
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Phone:</span>
              <span className="ml-1 font-bold">
                {record?.customer_phone_numbers[0]?.phone_number}, {" "}
                {record.customer_phone_numbers.length - 1 > 0 ? ` ....+${record.customer_phone_numbers.length - 1}` : ""}
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Email:</span>
              <span className="ml-1 font-bold">
                {record?.customer_emails[0]?.email}, {" "}
                {record.customer_emails.length - 1 > 0 ? ` ....+${record.customer_emails.length - 1}` : ""}
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Source:</span>
              <span className="ml-1 font-bold">
                {record.module.name}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <button
            className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
            onClick={destroy}
            >
            Ok
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={()=>{
              destroy();
              setEditCustomer(record);
            }}
          >
            Edit
          </button>{" "}
        </div>          
      </div>
    </div>
  );
}
