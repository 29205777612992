import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchNotifications = (
  t, 
  i18n,
  search = null
) => {
  return useQuery([
    "notifications",
    search
  ], () => {
    const params = {
      search
    };
    return apiClient
      ?.get(`/users/notifications`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          notifications: data?.data.rows
        }
      });
  });
};
