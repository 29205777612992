import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";

const uploadVariantTypes = async(data) => {
  return await apiClient?.post(`products/variants/types/upload`, data);

};
export const useMutateUploadVariantTypes = (setReport) => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(uploadVariantTypes, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("variants");
      setReport(data.data.message);
    }
  });
};
