import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { Controller } from "react-hook-form";
import { languages } from "../../utils/constants";
import apiClient from "../../services/apiClient";

export default function EditTaxModal({
  destroy,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  setLocalizedTitle,
  localizedTitle,
  clearErrors,
  isLoading,
  record,
  isValid,
  isDirty
}) {

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(languages[0].value);

  const title = watch('title');

  useEffect(() => {
    const langTitle = localizedTitle[language] ? localizedTitle[language] : "";
    setValue(`title`, langTitle);
    clearErrors(`title`);
  }, [language]);

  useEffect(() => {
    if(title && title != '') setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      newLocalizedTitle[`${language}`] = title;
      return newLocalizedTitle;
    });
    else setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      delete newLocalizedTitle[`${language}`];
      return newLocalizedTitle;
    });
  }, [title]);

  const generateCode = () => {
    clearErrors("code");
    setValue("code", Date.now().toString(36) + Math.random().toString(36).substr(2), { shouldDirty: true, shouldValidate: true })
  }

  return (
    <>
      <div className="py-8">
        <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
          Edit {record.title}
        </h1>
        <div className="bg-[#fff] p-2 flex rounded-md flex-col">
          <div className="w-[48%] relative">
            <span className={`absolute top-[35%] left-[85%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
            ${Object.keys(localizedTitle).length > 0 ? "bg-emerald-300	text-emerald-700" : "bg-red-300 text-red-600"}`}>{Object.keys(localizedTitle).length}</span>
            <SelectMenu
              name={"language"}
              fieldName={"language"}
              hint={"Select Language"}
              label={"Language"}
              placeholder={""}
              placeHolder={""}
              defaultValue={{label: "English", value: "en"}}
              options={languages}
              onChangeHandler={(val) => setLanguage(val)}
              Controller={Controller}
              control={control}
            />
          </div>
          <div className="w-full">
            <Input
              register={register}
              type="text"
              name="title"
              fieldName={"title"}
              label={`Tax Title In ${languages.find(lang => lang.value == language).label}`}
              placeholder={""}
              hint={`Enter Tax Title In ${languages.find(lang => lang.value == language).label}`}
              required={true}
              errors={errors?.title}
            />
          </div>
          <div className="w-full relative">
            <Input
              register={register}
              type="text"
              name="code"
              fieldName={"code"}
              label={`Code`}
              placeholder={""}
              hint={`Enter Tax Code`}
              required={true}
              validate={
                {
                  validate: async (value) => {
                    if(record.code !== value) {
                      if(value.length < 1) return "Please Enter A Valid Code";
                      const { data } = await apiClient.get(
                        "/taxes/code",
                        { params: { code: value } }
                      );
                      if(data.data?.exists) return "Code Must Be Unique";
                      else return null;
                    }
                    return null;
                  }
                }
              }
              errors={errors?.code}
            />
            <button 
              className={`absolute top-[35%] text-[#FB2576] ${i18n.resolvedLanguage === "ar" ? "left-[5px]" : "right-[5px]"}`}
              onClick={generateCode}
            >
              Generate
            </button>
          </div>
          <div className="w-full">
            <SelectMenu
              name={"type"}
              fieldName={"type"}
              hint={"Select Tax Type"}
              label={"Type"}
              placeholder={""}
              isLoading={false}
              placeHolder={""}
              required={true}
              options={
                [
                  {
                    label: "Percentage",
                    value: "percentage"
                  },
                  {
                    label: "Value",
                    value: "value"
                  }
                ]
              }
              Controller={Controller}
              control={control}
            />
          </div>
          <div className="w-full">
            <Input
              register={register}
              type="text"
              name="value"
              fieldName={"value"}
              label={`Value`}
              placeholder={""}
              hint={`Enter Tax Value`}
              required={true}
              errors={errors?.value}
            />
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || isLoading ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? "...Loading" : "Edit"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={() => destroy()}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
    </>   
  );
}
