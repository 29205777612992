export const formatDatePassed = (date) => {
    let date1 = new Date(date);
    let today = new Date();
    let difference = Math.abs(today - date1);
  
    let diff = Math.floor(difference / (1000 * 60 * 60 * 24)); 
  
    if (diff >= 2) {
      return date.split("T")[0];
    }
    if (diff >= 1 && diff < 2)
      return "Yesterday";
    diff = Math.floor(difference / (1000 * 60 * 60));
    if (diff >= 1 && diff < 2)
      return "1h";
    if (diff >= 2 && diff < 3)
      return "2h";
    if (diff >= 3 && diff <= 10)
      return  diff + "h";
    if (diff >= 11)
      return  diff + "h";
    diff = Math.floor(difference / (1000 * 60)); 
    if (diff < 1)
      return "1m";
    if (diff >= 1 && diff < 2)
      return "1m";
    if (diff >= 2 && diff < 3)
      return "2m";
    if (diff >= 3 && diff <= 10)
      return diff + "m";
    if (diff >= 11)
      return diff + " m";
    return diff;
};

export const formatDate = (isoDate, lang) => {
    if(!isoDate) return "-";
    return new Date(isoDate).toLocaleDateString(lang == "en" ? 'en-US' : 'ar-EG', {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
    });
};