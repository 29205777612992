import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchBranches = (
  t, 
  i18n,
  status = null,
  pageNo = 1,
  pageSize = 100,
  search = null,
  columnKey = null,
  order = null,
  brandIds = null,
  startDate = null,
  endDate = null,
  ) => {
  return useQuery([
    "branches",
    status,
    pageNo,
    pageSize,
    search,
    columnKey,
    order,
    brandIds,
    startDate,
    endDate,
  ], () => {
    const params = {
      status,
      pageNo, 
      pageSize, 
      search, 
      startDate,
      endDate,
      brand_ids: brandIds && brandIds.length > 0 ? JSON.stringify(brandIds) : null,
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
    };
    return apiClient
      ?.get(`branches`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          branches: data?.data.rows.map(obj => {
            return {
              ...obj,
              name: obj.localized_branch_name?.find(obj => obj.locale == i18n.resolvedLanguage)?.value,
              brands: obj.brands.map(brand => {
                return {
                  ...brand, 
                  name: brand.localized_brand_name.find(obj => obj.locale == i18n.resolvedLanguage)?.value
                }
              })
            }
          })
        }
      });
  });
};
