import React from "react";
import ReactDOM from "react-dom";
const portalRoot = document.getElementById("portal-root");
export default function Modal({ isOpen, close, children }) {
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <div
      className="fixed h-full w-screen top-0 bottom-0 right-0 left-0 bg-black/50 z-50 flex justify-center items-center"
    >
      <div
        className="bg-white px-5 rounded-md shadow-m min-w-[60%] max-w-[80%] max-h-[80%] overflow-y-scroll "
        onClick={(e) => {
          // do not close modal if anything inside modal content is clicked
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </div>,
    portalRoot
  );
}
