import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <div className="w-full flex justify-center items-center">
            <Dropdown
              menu={{
                items: generateDropdownItems(record),
              }}
            >
              <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
            </Dropdown>
          </div>
        );
      },
    },
];