import React from "react";
import BackIcon from "../../Icons/BlackBackIcon/BlackBackIcon";
import {  useFieldArray, Controller } from "react-hook-form";
import Trash from "../../Icons/Trash/Trash";
import { useFetchModules } from "../../Hooks/reactQuery/useFetchModules";
import SelectMenu from "../../Components/Input/Select";
import { BiPlus } from "react-icons/bi";

export default function TestSecondModal({ setIsOpenModal, nestIndex, control  }) {
    const permessions = [
    {
      name: "admin",
      label: "Admin",
    },
    {
      name: "editor",
      label: "Editor",
    },
    {
      name: "viewer",
      label: "Viewer",
    },
  ];
  const { data: modules, isLoading: modulesLoading } = useFetchModules();
  const { fields, remove, append } = useFieldArray({
    control,
    name: `userData[${nestIndex}].authorized_modules`
  });

  return (
    <div className="w-[1008px] py-8">
      <div className="flex items-center cursor-pointer" onClick={()=>setIsOpenModal(false)}>
        <span className="mr-3 mt-1">
          <BackIcon />{" "}
        </span>
        <h1 className="text-xl font-semibold text-[#0C2248] mb-0 ">
          Back to User Invitation
        </h1>
      </div>
      <div className={`border border-[#FB2576]/20 rounded mt-6 `}>
        <div className="bg-secondary-color flex items-center justify-between py-5 px-7">
          <div className="flex-1">#</div>
          <div className="flex-1">Module</div>
          <div className="flex-1">Permession</div>
        </div>
        <form >
          <ul>
            {fields.map((item, index) => {
              return (
                <li key={item.id}>
                  <div className="flex mt-3 items-center px-7">
                    <div>{index + 1}</div>
                    <div className="flex-1 ml-5">
                      <Controller
                        render={({ field }) => (
                          <SelectMenu
                            {...field}
                            isLoading={modulesLoading}
                            options={
                              modules &&
                              modules.map((item) => {
                                return {
                                  label: item.name,
                                  value: item.id.toString(),
                                };
                              })
                            }
                            Controller={Controller}
                            control={control}
                          />
                        )}
                        name={`userData[${nestIndex}].authorized_modules[${index}].module_id`}
                        control={control}
                        defaultValue={item.module_id} // make sure to set up defaultValue
                      />
                    </div>
                    <div className="w-4/12 ml-5">
                      <Controller
                        render={({ field }) => (
                          <SelectMenu
                            {...field}
                            options={
                              permessions &&
                              permessions.map((item) => {
                                return {
                                  label: item.label,
                                  value: item.name,
                                };
                              })
                            }
                            Controller={Controller}
                            control={control}
                          />
                        )}
                        name={`userData[${nestIndex}].authorized_modules[${index}].permission`}
                        control={control}
                        defaultValue={item.permission} // make sure to set up defaultValue
                      />
                    </div>

                    <div
                      className="cursor-pointer ml-3"
                      onClick={() => remove(index)}
                    >
                      <Trash />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </form>
      </div>

      <button
        className="mt-5"
        onClick={() => {
          append({ module_id: "", permission: "" });
        }}
      >
        <div className="flex">
          <div className="bg-primary-color w-6 h-6 rounded-lg flex justify-center items-center text-white text-lg">
            <BiPlus />
          </div>
          <span className="ml-3 text-primary-color font-semibold text-md">
            Add New Module
          </span>
        </div>
      </button>
      <div className="mt-10 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={()=>{
            setIsOpenModal(false)
          
          }}
        >
          Create
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={() => setIsOpenModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
