import { useMutation } from "react-query";
import apiClient from "../../services/apiClient";
import { createSearchParams, useNavigate } from "react-router-dom";
import { apps } from "../../utils/apps";
const login = async (payload) => {
  const { app, next, ...user } = payload;
  const params = createSearchParams();
  app && params.set("app", app);
  next && params.set("next", next);
  return await apiClient?.post(
    `auth/login${params.size ? "?" + params.toString() : ""}`,
    user
  );
};

export const useMutateLogin = () => {
  let navigate = useNavigate();
  return useMutation(login, {
    onSuccess: (data) => {
      if (data?.data.data.app && apps[data?.data.data.app]) {
        const params = createSearchParams();
        data?.data.data.token && params.set("token", data?.data.data.token);
        data?.data.data.next && params.set("next", data?.data.data.next);
        data?.data.data.id && params.set("id", data?.data.data.id);
        window.location.href = `${
          apps[data?.data.data.app]
        }/authenticated?${params.toString()}`;
        return;
      }
      localStorage.setItem("userToken", data?.data.data.token);
      localStorage.removeItem("request_for_access_token");
      localStorage.removeItem("invitationToken");
      localStorage.removeItem("request_accepted");
      navigate("/");
    },
  });
};
