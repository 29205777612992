import React, {useEffect, useState} from "react";
import { useFetchUser } from "../../Hooks/reactQuery/useFetchUser";
import Input from "../../Components/Input/Input";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useMutateEditUser } from "../../Hooks/reactQuery/useMutateEditUser";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaUserAlt } from "react-icons/fa";

export default function EditUser() {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('id'); 
  const [selectedImage, setSelectedImage] = useState(null);
  const [canEdit, setCanEdit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const { data: user, isLoading: userLoading, isError: isUserDataError} = useFetchUser(userId);
  const navigate = useNavigate();

  useEffect(() => {
    if(isUserDataError) {
      navigate("/");
    }
  }, [isUserDataError]);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    control,
    watch,
    getValues,
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "all",
  });

  const localizedJobTitle = user?.job_title.localized_job_title;
  const currentLang = localizedJobTitle?.find(
    (y) => i18n.resolvedLanguage == y.locale
  );

  const {mutate} = useMutateEditUser((data) => {
    setIsLoading(false);
    toast.info(data.data.message, {
      position: toast.POSITION.TOP_CENTER
    }); 
    setTimeout(() => {
      navigate("/");
    }, 2000);
  });

  const onSubmit=(request)=>{
    setCanEdit(false);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("user_image", request.user_image[0]);

    console.log(request.user_image);

    for (let key in request) {
      if(key != 'user_image') formData.append(key, request[key]);
    }

    mutate({userId, edit: formData});
  }

  return (
    <>
      {user ? (
        <div className="flex flex-col  h-full items-center justify-center">
          <div className="w-16  h-16  rounded-lg">
            {(!user.img || user.img === '') && !selectedImage ?               
              <div className="w-12 h-12 rounded-full flex justify-center items-center border-2 border-gray-300">
                <FaUserAlt />
              </div> : 
              <img
              src={
                selectedImage ?
                URL.createObjectURL(selectedImage) 
                : user.img && user.img !== '' ? `${process.env.REACT_APP_STATIC_BASE_PATH}${user.img}` 
                : ""
              }
              className="rounded-lg w-full h-full"
              alt=""
            />}
          </div>
          <label
            htmlFor="dropzone-file"
            className=" border-2 my-2 border-primary-color text-primary-color text-sm py-1.5 px-6 rounded-lg font-normal cursor-pointer"
          >
            Change your profile picture
            <input
              {...register("user_image", {
                onChange: (e) => setSelectedImage(e.target.files[0]),
              })}
              type="file"
              className="hidden"
              id="dropzone-file"
              accept="image/*"
            />
          </label>
          <div className="flex flex-col items-center">
            <span className="font-semibold text-black ">Code</span>
            <span className="text-gray-400">#{user.code}</span>
          </div>
          <div className="border border-gray-300 rounded-lg p-5 mt-4 mb-7 w-[50%]">
            <div className="flex w-full mb-3">
              <div className={`flex-1`}>
                <Input
                  name="first_name"
                  type={"text"}
                  label={t("first_name")}
                  fieldName={t("first_name")}
                  register={register}
                  value={user.first_name}
                  required={true}
                  maxLength={20}
                  minLength={3}
                />
              </div>
              <div className="flex-1 ml-3">
                <Input
                  name="last_name"
                  type={"text"}
                  label={t("last_name")}
                  fieldName={t("last_name")}
                  register={register}
                  value={user.last_name}
                  required={true}
                  maxLength={20}
                  minLength={3}
                />{" "}
              </div>
            </div>
            <div className="flex w-full mb-3">
              <div className={`flex-1`}>
                <Input
                  name="email"
                  type="text"
                  label={t("email")}
                  fieldName={t("email")}
                  placeHolder={user.email}
                //   register={register}
                  isDisabled={true}
                />{" "}
              </div>
              <div className="flex-1 ml-3">
                <Input
                  value={user.phone_number}
                  register={register}
                  type="text"
                  name="phone_number"
                  fieldName={t("phone")}
                  label={t("phone")}
                  required={true}
                  maxLength="50"
                  pattern={{
                    regex: /^[0-9]*$/,
                    errorMessage: t("no_spaces_just_numbers"),
                  }}
                  errors={errors?.phone_number}
                />{" "}
              </div>
            </div>
            <div className={`w-[50%] `}>
              <Input
                name="jobTitle"
                type="text"
                label={"Job title"}
                fieldName={t("jobTitle")}
                placeHolder={currentLang.value}
                // register={register}
                isDisabled={true}
              />{" "}
            </div>
          </div>
          {/* <button onClick={handleSubmit(onSubmit)}>test</button> */}
          <div className="flex">
            <button
              className={
                `
                w-40 border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold
                ${!canEdit ? ' cursor-not-allowed focus:outline-none disabled:opacity-4' : ''}
                `
              }
              disabled={!canEdit}
              onClick={handleSubmit(onSubmit)}
            >
              {isLoading ? 'Loading ...' : 'Edit'}
            </button>
            <button
              className={`w-40  border border-primary-color bg-white text-primary-color py-2 mx-2 rounded-lg font-semibold`}
              onClick={() => navigate("/")}
            >
              cancel
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
