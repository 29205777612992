import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateAddBrand } from "../../Hooks/reactQuery/useMutateAddBrand";
import { useMutateEditBrand } from "../../Hooks/reactQuery/useMutateEditBrand";
import { languages } from "../../utils/constants";
import EditBrandModal from "./editBrandModal";

const EditBrand = ({ destroy, record }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: record?.code,
    title: record?.localized_brand_name?.find(obj => obj.locale == languages[0].value)?.value,
    vision: record?.vision ? record?.vision : "",
    mission: record?.mission ? record?.mission : "",
    brand_category: record?.category_id?.toString() ? record?.category_id?.toString() : "",
    branches: record?.branches?.length > 0 ? record?.branches?.map(obj => obj.id.toString()) : []
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  useEffect(() => {
    if(record) {
      setValue("title", record.localized_brand_name?.find(obj => obj.locale == languages[0].value)?.value);
      setValue("vision", record.vision ? record.vision: "");
      setValue("mission", record.mission ? record.mission: "");
      setValue("brand_category", record.category_id?.toString() ? record.category_id?.toString() : "");
      setValue("branches", record?.branches?.length > 0 ? record?.branches?.map(obj => obj.id.toString()) : []);
    }
  }, [record]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  const { mutate, isLoading, isSuccess } = useMutateEditBrand();

  const onSubmit = async (request) => {
    const editData = {
      title: JSON.stringify(Object.keys(localizedTitle).map(locale => {
        return {
          locale, 
          value: localizedTitle[locale]
        }
      })),
      code: request.code,
      branch_ids: JSON.stringify(request.branches),
      category_id: request.brand_category,
      vision: request.vision,
      mission: request.mission,
      locale: i18n.resolvedLanguage
    };
    const formData = new FormData();
    for (let key in editData) {
      formData.append(key, editData[key]);
    }

    if(files && files.length > 0) {
      formData.append("brand_logo", files[0]);
    }

    const data = {
      id: record.id,
      edit: formData
    }

    mutate(data);
    destroy();
  };

  return (
    <div>
      <EditBrandModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          isValid,
          isDirty,
          files,
          setFiles
        }}
        record={record}
        destroy={destroy}
      />
    </div>
  );
};

export default EditBrand;
