import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";

const changeStatus = async(obj) => {
  return await apiClient?.put(`/products/${obj.id}/status`, {status: obj.status, locale: obj.locale});

};
export const useMutateChangeProductStatus = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(changeStatus, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("all-products");
    },
  });
};