import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchVariants = (
  t, 
  i18n,
  status = null,
  pageNo = 1,
  pageSize = 100,
  search = null,
  columnKey = null,
  order = null
  ) => {
  return useQuery([
    "variants",
    status,
    pageNo,
    pageSize,
    search,
    columnKey,
    order
  ], () => {
    const params = {
      status,
      pageNo, 
      pageSize, 
      search, 
      locale: i18n.resolvedLanguage,
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
    };
    return apiClient
      ?.get(`products/variants`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          variants: data?.data.rows.map(obj => {
            return {
              ...obj,
              title: obj.localized_variant_type.find(obj => obj.locale == i18n.resolvedLanguage).value,
              options: obj.options.map(option => {
                return {
                  ...option,
                  title: option.localized_variant_option.find(localizedOption => localizedOption.locale == i18n.resolvedLanguage).value
                }
              })
            }
          })
        }
      });
  });
};
