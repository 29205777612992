import React from "react";
import { useNavigate } from "react-router-dom";

export default function ViewTax({ record, destroy, setEditTax }) {

  const navigate = useNavigate();
  
  return (
    <div className="py-8">
        <div className="w-[1008px]">
          <div className="flex">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Code:</span>
              <span className="ml-1 font-bold">{record.code}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Type:</span>
              <span className="ml-1 font-bold">{record.type}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Value:</span>
              <span className="ml-1 font-bold">{record.value}</span>
            </div>
            {record.localized_tax_title.map(obj => <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Name In ({obj.locale}):</span>
              <span className="ml-1 font-bold">
                {obj.value}
              </span>
            </div>)}
          </div>
        </div>
        <div className="mt-6 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={destroy}
          >
          Ok
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={()=>{
            destroy();
            setEditTax(record);
          }}
        >
          Edit
        </button>{" "}
      </div>          
    </div>
    </div>
  );
}
