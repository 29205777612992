import React from "react";

export default function Filters({ children, isOpen, handleSubmit, onSubmit }) {
  if (!isOpen) return null;

  return (
    <div className="w-[20%] h-auto  rounded-md border border-[#fb2576] border-opacity-25 bg-white absolute right-[20px] top-[220px] z-[999]">
      {children}
      <div className="flex justify-center items-center p-3">
        <button
          className="bg-primary-color w-[180px] py-2 rounded-lg text-white font-bold "
          onClick={handleSubmit(onSubmit)}
        >
          <div className=" w-full flex justify-center items-center">
            <span>Apply</span>
          </div>
        </button>
      </div>
    </div>
  );
}
