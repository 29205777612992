import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <div className="flex items-center justify-center w-full">
            <Dropdown
              menu={{
                items: generateDropdownItems(record),
              }}
            >
              <FiMoreVertical className="text-xl cursor-pointer text-primary-color" />
            </Dropdown>
          </div>
        );
      },
    },
];