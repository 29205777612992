import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

// const fetchUsers = async (pageNo, pageSize) => {
//   return await apiClient?.get(`users?pageNo=${pageNo}&pageSize=${pageSize}`,);
// };
// export const useFetchAllUsers = (pageNo, pageSize) => {
//   return useQuery("all-users", fetchUsers(pageNo, pageSize), {
//     select: ({ data }) => {
//       return data.data;
//     },
//   });
// };
export const useFetchAllUsers = (pageNo, pageSize, modules, status,search, columnKey, order, startDate, endDate) => {
  return useQuery([
    "all-users", 
      pageNo, 
      pageSize, 
      modules, 
      status,
      search,
      columnKey, 
      order,
      startDate,
      endDate
    ], () => {
    const params = { 
      pageNo, 
      pageSize, 
      modules, 
      status, 
      search, 
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
      startDate,
      endDate
    };
    return apiClient
      ?.get(`users`, {
        params,
      })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          users: data?.data.rows
        }
      });
  });
};
