import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient'
const sendIvitation = async(user) => {
  return await apiClient?.post(`user_invitations`,user);
};
export const useMutateAcceptInvitation = () => {
  let navigate = useNavigate();

  return useMutation(sendIvitation, {
    onSuccess: (data) => {
      navigate("/users");
    },
  });
};