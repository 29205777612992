import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateEditTax } from "../../Hooks/reactQuery/useMutateEditTax";
import { languages } from "../../utils/constants";
import EditTaxModal from "./editTaxModal";

const EditTax = ({ destroy, record}) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: record?.code ? record?.code : "",
    title: record?.title ? record?.title : "",
    type: record?.type ? record?.type?.toString() : "",
    value: record?.value ? record?.value : ""
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  useEffect(() => {
    if(record) {
      setValue("title", record.localized_tax_title?.find(obj => obj.locale == languages[0].value)?.value);
      setValue("code", record.code, { shouldValidate: true });
      setValue("type", record.type.toString());
      setValue("value", record.value);
    }
  }, [record]);

  const { mutate, isLoading, isSuccess } = useMutateEditTax();

  const onSubmit = async (request) => {
    const data = {
      id: record.id,
      edit: {
        title: Object.keys(localizedTitle).map(locale => {
          return {
            locale, 
            value: localizedTitle[locale]
          }
        }),
        code: request.code,
        type: request.type,
        value: request.value,
        locale: i18n.resolvedLanguage
      }
    }

    mutate(data);
    destroy();
  };

  return (
    <div>
      <EditTaxModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          isValid,
          isDirty
        }}
        destroy={destroy}
        record={record}
      />
    </div>
  );
};

export default EditTax;
