import React from "react";
import { useFetchUser } from "../../Hooks/reactQuery/useFetchUser";
import Table from "../../Components/Table/Table";
import userImg from '../../Icons/user.jpg';
import { useNavigate } from "react-router-dom";
import EditUser from "../EditUser/EditUser";
import { staticBaseUrl } from "../../utils/constants";

export default function ViewBrand({ record, destroy, setEditBrand }) {

  const navigate = useNavigate();
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Branch Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
  ];
  
  return (
    <div className="py-8">
        <div className=" w-[1008px]">
          <div className="flex">
            <div className="flex flex-col flex-1 mr-10">
              <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
                <span className="text-gray-400">Brand Code:</span>
                <span className="ml-1 font-bold">{record.code}</span>
              </div>
              {record.localized_brand_name.map(obj => <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
                <span className="text-gray-400">Name In ({obj.locale}):</span>
                <span className="ml-1 font-bold">
                  {obj.value}
                </span>
              </div>)}
              <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
                <span className="text-gray-400">Brand Category:</span>
                <span className="ml-1 font-bold">{record?.category?.name}</span>
              </div>
              <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
                <span className="text-gray-400">Brand Vision:</span>
                <span className="ml-1 font-bold">{record?.vision ? record?.vision : "--"}</span>
              </div>
              <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
                <span className="text-gray-400">Brand Mission:</span>
                <span className="ml-1 font-bold">{record?.mission ? record?.mission : "--"}</span>
              </div>
              <h1>Branches</h1>
              <Table columns={columns} data={record?.branches} hasFooter={false} />
            </div>
          <div className="w-1/4 h-[25%]">
            <img src={`${record?.logo ? staticBaseUrl + record.logo : ""}`} alt="" className="max-h-full max-w-full"/>
          </div>
          </div>
          <div className="mt-6 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={destroy}
          >
          Ok
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={()=>{
            setEditBrand(record);
            destroy();
          }}
        >
          Edit
        </button>{" "}
      </div>          
    </div>
    </div>
  );
}
