import { useMutation } from "react-query";
import apiClient from "../../services/apiClient";
import { toast } from "react-toastify";

const auth = async (data) => {
  return await apiClient?.post(`oauth/authorize/client-side`, data);
};

export const useOAuthMutation = () => {
  return useMutation(auth, {
    onSuccess: (data) => {
      window.location.href = data?.data?.redirect;
    },
    onError: (error) => {
      if (
        error?.response?.data?.error === "access_denied" ||
        error?.response?.data?.error === "invalid_grant" ||
        error?.response?.data?.error === "invalid_request" ||
        error?.response?.data?.error === "unauthorized_client" ||
        error?.response?.data?.error === "invalid_client" ||
        error?.response?.data?.error === "unsupported_response_type" ||
        error?.response?.data?.error === "invalid_scope" ||
        error?.response?.data?.error === "insufficient_scope" ||
        error?.response?.data?.error === "invalid_token" ||
        error?.response?.data?.error === "unauthorized_request" ||
        error?.response?.data?.error === "unsupported_grant_type"
      ) {
        toast.error(error.response.data.error_description, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    },
  });
};
