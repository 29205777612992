import React from 'react';
import BackIconArrow from "../../Icons/BackIconArrow/BackIconArrow";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
const BackToHomeAuth = () => {
    const { t, i18n } = useTranslation();
    const navigate=useNavigate();
    return (
        <div className='w-full bg-white drop-shadow py-4 px-4 flex items-center'>
            <BackIconArrow />
            <h4  onClick={()=>{
            navigate('/')
        }} className={`font-bold text-pink-500 cursor-pointer ${
                i18n.resolvedLanguage === "en" ? "ml-2 justify-start" : "mr-2 justify-end"
              } `}>{t("back_to_home")}</h4>
        </div>
    );
}

export default BackToHomeAuth;
