import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addVariantType = async(obj) => {
  return await apiClient?.post(`products/variants/types`, obj);

};
export const useMutateAddVariantType = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addVariantType, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("variants");
    }
  });
};
