import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { useFieldArray, Controller } from "react-hook-form";
import { useFetchMeasurementUnits } from "../../Hooks/reactQuery/useFetchMeasurementUnits";
import ReactSwitch from "react-switch";
import AddBox from "../../Icons/AddBox/AddBox";
import Select from "react-select";
import { getColumns } from "./tableColumnsHeaders";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BiImageAdd, BiPlus } from "react-icons/bi";
import { Table } from "antd";
import { useFetchVariants } from "../../Hooks/reactQuery/useFetchVariants";
import { languages } from "../../utils/constants";
import EditChild from "./editChildModal";
import { CiEdit } from "react-icons/ci";
import apiClient from "../../services/apiClient";
import { MdCancel } from "react-icons/md";
import { useFetchBrands } from "../../Hooks/reactQuery/useFetchBrands";
import Trash from "../../Icons/Trash/Trash";
import { useFetchProductCategories } from "../../Hooks/reactQuery/useFetchProductCategories";
import { lang } from "moment";
import { useFetchProductWasteCategories } from "../../Hooks/reactQuery/useFetchProductWasteCategories";

export default function AddNewProductModal({
  setIsOpen,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  setLocalizedTitle,
  localizedTitle,
  clearErrors,
  children,
  setChildren,
  hasVariations,
  setHasVariations,
  isLoading,
  isValid,
  isDirty,
  validState,
  files,
  setFiles,
}) {
  const { t, i18n } = useTranslation();

  const [editChild, setEditChild] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants",
  });

  const generateDropdownItems = (record) => [
    {
      key: "1",
      label: "Edit",
      icon: <CiEdit />,
      onClick: () => {
        setEditChild(record.customId);
      },
    },
    {
      key: "1",
      label: "Remove",
      icon: <AiOutlineEyeInvisible />,
      onClick: () => {
        setChildren((old) => old.filter((p) => p.customId !== record.customId));
      },
    },
  ];

  const [columnHeaders, setColumnHeaders] = useState(
    getColumns(generateDropdownItems, t, i18n)
  );
  const { data: measurementUnits, isLoading: measurementUnitsLoading } =
    useFetchMeasurementUnits(t, i18n);
  const [language, setLanguage] = useState(languages[0].value);

  const [variantsSelect, setVariantsSelect] = useState([]);

  const { data: allVariants, isLoading: allVariantsLoading } = useFetchVariants(
    t,
    i18n
  );

  useEffect(() => {
    if (allVariants) {
      setVariantsSelect(allVariants.variants);
    }
  }, [allVariants]);

  const title = watch("title");

  useEffect(() => {
    const langTitle = localizedTitle[language] ? localizedTitle[language] : "";
    setValue(`title`, langTitle);
    clearErrors(`title`);
  }, [language]);

  useEffect(() => {
    if (title && title != "")
      setLocalizedTitle((old) => {
        const newLocalizedTitle = { ...old };
        newLocalizedTitle[`${language}`] = title;
        return newLocalizedTitle;
      });
    else
      setLocalizedTitle((old) => {
        const newLocalizedTitle = { ...old };
        delete newLocalizedTitle[`${language}`];
        return newLocalizedTitle;
      });
  }, [title]);

  const generateVariations = () => {
    const selectedVariants = watch("variants");

    let allAvailableVariants = [];
    for (const currentVariant of selectedVariants) {
      for (const option of currentVariant.options) {
        allAvailableVariants.push({
          variant_type_id: currentVariant.variant_type_id,
          option_id: option.value,
        });
      }
    }

    const uniqueVariantTypes = [
      ...new Set(allAvailableVariants.map((obj) => obj.variant_type_id)),
    ];
    const variantTypes = uniqueVariantTypes.map((variantTypeId) => {
      const variantTypesObj = {};
      variantTypesObj[variantTypeId] = [];
      return variantTypesObj;
    });

    for (const v of allAvailableVariants) {
      const variantTypeObj = variantTypes.find((obj) =>
        Array.isArray(obj[v.variant_type_id])
      );
      const newObject = {};
      newObject[v.variant_type_id] = v.option_id;
      variantTypeObj[v.variant_type_id].push(newObject);
    }

    const arrays = variantTypes.map((obj) => obj[Object.keys(obj)[0]]);

    const combineObjects = ([head, ...[headTail, ...tailTail]]) => {
      if (!headTail) return head;

      const combined = headTail.reduce((acc, x) => {
        return acc.concat(head.map((h) => ({ ...h, ...x })));
      }, []);

      return combineObjects([combined, ...tailTail]);
    };

    const unStructuredVariants = combineObjects(arrays);

    const variants = unStructuredVariants.map((obj) => {
      const productVariants = [];
      for (let key in obj) {
        const vObj = {};
        vObj.variant_type_id = key;
        vObj.variant_option_id = obj[key].toString();
        productVariants.push(vObj);
      }
      return productVariants;
    });

    const products = variants.map((vs) => {
      const productTitle = Object.keys(localizedTitle).map((locale) => {
        const variantsTitleArray = [];
        for (const v of vs) {
          const variant_title = allVariants?.variants
            ?.find((obj) => obj.id == v.variant_type_id)
            ?.localized_variant_type?.find(
              (obj) => obj.locale == locale
            )?.value;
          const option_title = allVariants?.variants
            ?.find((obj) => obj.id == v.variant_type_id)
            ?.options?.find((obj) => obj.id == v.variant_option_id)
            ?.localized_variant_option?.find(
              (obj) => obj.locale == locale
            )?.value;
          variantsTitleArray.push(` ${variant_title}: ${option_title} `);
        }

        const vsTitle = `(${variantsTitleArray.join(",")})`;

        return {
          locale,
          value: `${localizedTitle[locale]} ${vsTitle}`,
        };
      });

      return {
        localized_product_title: productTitle,
        title: productTitle,
        sku:
          "id" + Date.now().toString(36) + Math.random().toString(36).substr(2),
        upc:
          "id" + Date.now().toString(36) + Math.random().toString(36).substr(2),
        unit_of_measure_id: getValues("unit_of_measure_id"),
        measurement_unit: measurementUnits?.units?.find(
          (obj) => obj.id == getValues("unit_of_measure_id")
        ),
        variants: vs,
        customId:
          "id" + Date.now().toString(36) + Math.random().toString(36).substr(2),
        disabled: 0,
        images: [],
      };
    });

    setChildren(products);
  };

  const [order, setOrder] = useState("");
  const [columnKey, setColumnKey] = useState("");

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const formatOptionLabel = (data, context) => {
    return (
      <div className="flex justify-between items-center">{data.label}</div>
    );
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      outline: "none",
      height: "36px",
      width: "100%",
      border: "1px solid #CBD5E1",
      marginRight: "-5px",
      borderRadius: "6px",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      position: "absolute",
      width: "100%",
      left: 0,
      zIndex: 1000,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px 10px",
      maxHeight: "100%",
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      position: "absolute",
      left: "47px",
    }),
  };

  const {
    fields: brandFields,
    append: appendBrand,
    remove: removeBrand,
  } = useFieldArray({
    control,
    name: "brands",
  });

  const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(
    t,
    i18n
  );

  const [brandsSelect, setBrandsSelect] = useState([]);

  useEffect(() => {
    if (brandsData) setBrandsSelect(brandsData.brands);
  }, [brandsData]);

  const { data: categoriesData, isLoading: categoriesLoading } =
    useFetchProductCategories(t, i18n);

  const { data: wastCategoriesData, isLoading: wasteCategoriesLoading } =
    useFetchProductWasteCategories(t, i18n);

  return (
    <>
      {editChild ? (
        <EditChild
          children={children}
          setChildren={setChildren}
          current={children.filter((obj) => obj.customId == editChild)[0]}
          childId={editChild}
          destroy={() => setEditChild(null)}
        />
      ) : (
        <div className="relative w-full h-full max-h-full">
          <div className="overflow-y-auto h-auto py-8">
            <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
              Add New Product
            </h1>

            <div className="flex w-full justify-between">
              <div className="bg-[#fff] p-2 flex rounded-md flex-col w-[68%]">
                <div className="w-[48%] relative">
                  <span
                    className={`absolute top-[35%] left-[80%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
                ${
                  Object.keys(localizedTitle).length > 0
                    ? "bg-emerald-300	text-emerald-700"
                    : "bg-red-300 text-red-600"
                }`}
                  >
                    {Object.keys(localizedTitle).length}
                  </span>
                  <SelectMenu
                    name={"language"}
                    fieldName={"language"}
                    hint={"Select Language"}
                    label={"Language"}
                    placeholder={""}
                    defaultValue={{ label: "English", value: "en" }}
                    options={languages}
                    onChangeHandler={(val) => setLanguage(val)}
                    Controller={Controller}
                    control={control}
                  />
                </div>
                <Input
                  register={register}
                  type="text"
                  name="title"
                  fieldName={"title"}
                  label={`Product Title In ${
                    languages.find((lang) => lang.value == language).label
                  }`}
                  placeholder={""}
                  hint={`Enter Product Title In ${
                    languages.find((lang) => lang.value == language).label
                  }`}
                  required={false}
                  errors={errors?.title}
                />
                <div className="w-full flex justify-between">
                  <div className="w-[48%]">
                    <Input
                      register={register}
                      type="text"
                      name="sku"
                      fieldName={"sku"}
                      label={"Stock keeping unit (SKU)"}
                      placeholder={""}
                      hint={"Enter The Stock keeping unit (SKU)"}
                      required={true}
                      validate={{
                        validate: async (value) => {
                          if (value.length < 1)
                            return "Please Enter A Valid SKU";
                          const { data } = await apiClient.get(
                            "/products/sku",
                            { params: { sku: value } }
                          );
                          if (data.data?.exists) return "Sku Must Be Unique";
                          else return null;
                        },
                      }}
                      errors={errors?.sku}
                    />
                  </div>
                  <div className="w-[48%]">
                    <Input
                      register={register}
                      type="text"
                      name="upc"
                      fieldName={"upc"}
                      label={"Barcode (UPC)"}
                      placeholder={""}
                      hint={t("Enter The Barcode (ISBN, UPC, GTIN, etc..)")}
                      required={true}
                      validate={{
                        validate: async (value) => {
                          if (value.length < 1)
                            return "Please Enter A Valid UPC";
                          const { data } = await apiClient.get(
                            "/products/upc",
                            { params: { upc: value } }
                          );
                          if (data.data?.exists) return "UPC Must Be Unique";
                          else return null;
                        },
                      }}
                      errors={errors?.upc}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="w-[48%]">
                    <SelectMenu
                      name={"unit_of_measure_id"}
                      fieldName={"Unit Of Measure"}
                      hint={"Enter The unit of measure"}
                      label={"Unit of measure"}
                      placeholder={""}
                      isLoading={measurementUnitsLoading}
                      placeHolder={""}
                      required={true}
                      options={measurementUnits?.units.map((item) => {
                        return {
                          label: item.title,
                          value: item.id.toString(),
                        };
                      })}
                      Controller={Controller}
                      control={control}
                    />
                  </div>
                  <div className="w-[48%]">
                    <SelectMenu
                      name={"product_category"}
                      fieldName={"category"}
                      hint={"Select The Product Category"}
                      label={"Product Category"}
                      placeholder={""}
                      isLoading={categoriesLoading}
                      placeHolder={""}
                      required={true}
                      options={categoriesData?.categories.map((item) => {
                        return {
                          label: item.name,
                          value: item.id.toString(),
                        };
                      })}
                      Controller={Controller}
                      control={control}
                      errors={errors?.category}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-between">
                  <div className="w-[48%]">
                    <SelectMenu
                      name={"product_tag"}
                      fieldName={"Product Tag"}
                      hint={"Enter The product tag"}
                      label={"Product Tag"}
                      placeholder={""}
                      isLoading={measurementUnitsLoading}
                      required={true}
                      options={[
                        { label: "Final End", value: "final" },
                        { label: "Semi End", value: "semi" },
                        { label: "Raw Material", value: "raw" },
                      ]}
                      Controller={Controller}
                      control={control}
                    />
                  </div>
                  <div className="w-[48%]">
                    <SelectMenu
                      name={"waste_category"}
                      fieldName={"category"}
                      hint={"Select The Waste Category"}
                      label={"Waste Category"}
                      isLoading={wasteCategoriesLoading}
                      placeHolder={""}
                      required={false}
                      options={wastCategoriesData?.wasteCategories.map(
                        (item) => {
                          return {
                            label: item.name,
                            value: item.id.toString(),
                          };
                        }
                      )}
                      Controller={Controller}
                      control={control}
                      errors={errors?.category}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-start w-[28%] p-2">
                <div className="w-full flex-col justify-start items-start">
                  <h5>Product Images ({files.length})</h5>
                  <div className="w-full flex-col border-2 border-gray-200 border-dashed h-[300px] flex justify-start items-center">
                    <div className="w-full p-2 h-auto overflow-hidden justify-self-start align-self-start">
                      <div className="w-auto flex flex-no-wrap h-[70px] overflow-x-auto overflow-y-hidden pt-[10px]">
                        {files.length > 0
                          ? files.map((img) => (
                              <div className="relative mr-[10px] h-[50px] min-w-[50px]">
                                <button
                                  className="absolute top-[-10px] right-[-10px]"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setFiles((old) =>
                                      old.filter((obj) => obj.name != img.name)
                                    );
                                  }}
                                >
                                  <MdCancel size={20} />
                                </button>
                                <img
                                  className="w-[50px] h-[50px] mr-[10px]"
                                  src={URL.createObjectURL(img)}
                                />
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                    <label
                      for="file-upload"
                      className="flex flex-col w-full flex-1 cursor-pointer justify-center items-center"
                    >
                      <BiImageAdd color="#FB2576" size="50" />
                      <h8>Upload File From Your Device</h8>
                    </label>
                  </div>
                  <input
                    type="file"
                    name="file-upload"
                    id={`file-upload`}
                    style={{ display: "none" }}
                    {...register("images_array", {
                      onChange: (e) => {
                        setFiles(Array.from(e.target.files));
                      },
                    })}
                    multiple="multiple"
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-start items-start w-full border-solid border border-neutral-400 rounded p-2">
              <div
                className={`flex items-center justify-center ${
                  hasVariations ? "mb-8" : ""
                }`}
              >
                <ReactSwitch
                  onChange={(show) => setHasVariations(show)}
                  checked={hasVariations}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor={"#FB2576"}
                />
                <p className="m-0 ml-2 font-medium">
                  This Product Has Variations
                </p>
              </div>

              {hasVariations ? (
                <>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col">
                      {fields.map((field, index) => (
                        <div className="w-full flex justify-between">
                          <div className="w-[48%]">
                            <SelectMenu
                              name={`variants[${index}].variant_type_id`}
                              fieldName={"Variant Type"}
                              hint={"Enter Variant Type"}
                              label={"Variant Type"}
                              placeholder={""}
                              isLoading={false}
                              placeHolder={""}
                              options={variantsSelect.map((obj) => {
                                return {
                                  label: obj.title,
                                  value: obj.id,
                                };
                              })}
                              Controller={Controller}
                              control={control}
                              onChangeHandler={(val) => {
                                console.log(val);
                                console.log(variantsSelect);
                              }}
                            />
                          </div>
                          <div className="w-[48%]">
                            <label className="text-sm text-body font-medium min-w-max">
                              Options
                            </label>
                            <div className="mt-2">
                              <Controller
                                name={`variants[${index}].options`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value },
                                }) => {
                                  return (
                                    <Select
                                      placeholder={"options"}
                                      options={variantsSelect
                                        .find(
                                          (obj) =>
                                            obj.id ==
                                            watch(
                                              `variants[${index}].variant_type_id`
                                            )
                                        )
                                        ?.options?.map((obj) => {
                                          return {
                                            label: obj.title,
                                            value: obj.id,
                                          };
                                        })}
                                      isLoading={false}
                                      styles={styles}
                                      onChange={(selectedOption) => {
                                        console.log(selectedOption);
                                        console.log(
                                          variantsSelect.find(
                                            (obj) =>
                                              obj.id ==
                                              watch(
                                                `variants[${index}].variant_type_id`
                                              )
                                          )?.options
                                        );
                                        onChange(selectedOption);
                                      }}
                                      value={value}
                                      isMulti={true}
                                      controlShouldRenderValue={true}
                                      hideSelectedOptions={false}
                                      isSearchable={false}
                                      isDisabled={false}
                                      onBlur={(e) => onBlur(e)}
                                      formatOptionLabel={formatOptionLabel}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <button
                      className="flex"
                      onClick={() => {
                        for (let v of watch(`variants`)) {
                          setVariantsSelect((arr) =>
                            arr.filter((obj) => obj.id !== v.variant_type_id)
                          );
                        }
                        append({ variant_type_id: "", options: [] });
                      }}
                    >
                      <AddBox />
                      <p className="ml-2 font-medium">Add New Variant Type</p>
                    </button>
                    <div className="w-full flex items-center justify-center p-2 mx-2">
                      <button
                        className="border-solid border-primary-color border py-2 px-8 rounded-lg text-primary-color"
                        onClick={generateVariations}
                      >
                        Generate Variations
                      </button>
                    </div>
                  </div>
                  <div className="w-full my-2">
                    <Table
                      rowKey={(record) => record.id}
                      dataSource={[...children]}
                      columns={columnHeaders}
                      pagination={false}
                      onChange={handleTableChange}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <ul className="flex-col border-2 border-[#FB25760D] rounded pb-2">
            <div className="flex items-center justify-between bg-[#FB25760D] p-2">
              <div className="w-8 justify-start items-center">#</div>
              <div className="w-[37%] justify-start items-center">
                Brand Code
              </div>
              <div className="w-[47%] justify-start items-center">
                Brand Name
              </div>
              <div className="w-[5%]"></div>
            </div>
            {brandFields.map((item, index) => {
              return (
                <li key={index} className={"pr-2 pl-2"}>
                  <div className="flex mt-3 items-center justify-between">
                    <div className="w-8 h-8 cursor-pointer flex justify-center items-center border-2 border-primary-color rounded bg-[#FFE8F0] text-primary-color">
                      {index + 1}
                    </div>
                    <div className="w-[37%]">
                      <Controller
                        render={({ field }) => (
                          <SelectMenu
                            {...field}
                            placeholder={"Brand Code"}
                            isLoading={brandsLoading}
                            options={brandsSelect.map((obj) => {
                              return {
                                label: obj.code,
                                value: obj.id.toString(),
                              };
                            })}
                            Controller={Controller}
                            control={control}
                          />
                        )}
                        name={`brands[${index}]`}
                        control={control}
                      />
                    </div>
                    <div className="w-[47%]">
                      <Controller
                        render={({ field }) => (
                          <SelectMenu
                            {...field}
                            placeholder={"Brand Name"}
                            isLoading={brandsLoading}
                            options={brandsSelect.map((obj) => {
                              return {
                                label: obj.name,
                                value: obj.id.toString(),
                              };
                            })}
                            Controller={Controller}
                            control={control}
                          />
                        )}
                        name={`brands[${index}]`}
                        control={control}
                      />
                    </div>
                    <div
                      className="w-[5%] cursor-pointer"
                      onClick={() => removeBrand(index)}
                    >
                      <Trash />
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
          <button
            onClick={() => {
              for (let id of watch("brands")) {
                setBrandsSelect((arr) => arr.filter((obj) => obj.id != id));
              }
              appendBrand("");
            }}
          >
            <div className="flex">
              <div className="bg-primary-color w-6 h-6 rounded-lg flex justify-center items-center text-white text-lg">
                <BiPlus />
              </div>
              <span className="ml-3 text-primary-color font-semibold text-md">
                Add New Brand
              </span>
            </div>
          </button>
          <div className="flex justify-center sticky bottom-0 bg-[#fff] p-2">
            <button
              className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${
                !isDirty || !isValid || !validState || isLoading
                  ? "focus:outline-none disabled:opacity-50"
                  : ""
              }`}
              onClick={handleSubmit(onSubmit)}
              disabled={!isDirty || !isValid || !validState || isLoading}
            >
              {isLoading ? "...Loading" : "Create"}
            </button>
            <button
              className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </button>{" "}
          </div>
        </div>
      )}
    </>
  );
}
