import React from "react";
import { useFetchUser } from "../../Hooks/reactQuery/useFetchUser";
import Table from "../../Components/Table/Table";
import userImg from '../../Icons/user.jpg';
import { useNavigate } from "react-router-dom";
import EditUser from "../EditUser/EditUser";

export default function ViewUser({ userId, setShowViewModal }) {

  const navigate = useNavigate();

  const { data: user, isLoading: userLoading } = useFetchUser(userId);
  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Module",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Permission",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
    },
  ];
  
  return (
    <div className="py-8">
      {user ? (
        <div className=" w-[1008px]">
          <div className="flex">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Code:</span>
              <span className="ml-1 font-bold">{user.code}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Name:</span>
              <span className="ml-1 font-bold">
                {user.first_name} {user.last_name}
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Email:</span>
              <span className="ml-1 font-bold">{user.email}</span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3 ">
              <span className="text-gray-400">Joining Date:</span>
              <span className="ml-1 font-bold">
                {user.created_at.split("T")[0]}
              </span>
            </div>
            <h1>Branches</h1>
            <Table columns={columns} data={user?.authorized_modules} hasFooter={false} pageSize={20} />
          </div>
          <div className="w-1/4 h-[25%]">
            <img src={user?.img ? user.img : userImg} alt="" className="h-full	"/>
          </div>
          </div>
          <div className="mt-6 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={() => setShowViewModal(false)}
          >
          Ok
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={()=>{
            navigate(`/editUser?id=${userId}`);
          }}
        >
          Edit
        </button>{" "}
      </div>          
        </div>
      ):
      <div className="flex w-[1008px]">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Code:</span>
              <span className="ml-1 font-bold"></span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Name:</span>
              <span className="ml-1 font-bold">
               
              </span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Email:</span>
              <span className="ml-1 font-bold"></span>
            </div>
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3 ">
              <span className="text-gray-400">Joining Date:</span>
              <span className="ml-1 font-bold">
           
              </span>
            </div>
            <h1>Branches</h1>
            <Table columns={columns} pageSize={20}  />
          </div>
          <div className="w-1/4 h-[25%]">
            {/* <img src={user.img?user.img:userImg} alt="" className="h-full	"/> */}
          </div>
        </div>
      }
    </div>
  );
}
