import React, { useState, useEffect } from "react";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input/Input";
import ProfileImg from "../../Icons/ProfileImg/ProfileImg";
import { useNavigate } from "react-router-dom";
import { useMutateRegister } from "../../Hooks/reactQuery/useMutateRegister";
export default function Login() {

  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
  } = useForm({
    mode: "all",
  });
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  /*set selected image in its box */
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  /*get token from emailed url  */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlToken = urlParams.get("token");
    localStorage.setItem("request_accepted", urlToken);
    localStorage.removeItem("request_for_access_token")
  }, []);
  /*submit form */
  const {mutate}=useMutateRegister();
  const onSubmit =  (request) => {
    const formData = new FormData();
    formData.append("user_image", request.user_image[0]);
    formData.append(
      "request_for_access_token",
      localStorage.getItem("request_accepted")
    );
    for (let key in request) {
      formData.append(key, request[key]);
    }
    mutate(formData)
  };


  return (
    <div className="min-h-screen">
      <div className="flex flex-col">
        <BackToHomeAuth />
        <div className="flex-1 flex flex-col items-center">
          <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col  ">
            {/* Welcome */}
            <div className="w-full flex flex-col mt-10 items-center">
              <span className="ml-2 text-xl font-bold text-primary-color ">
                {t("welcome_inframodern")}
              </span>
              <span className="text-sm font-medium">
                {t("complete_account")}
              </span>
            </div>
            {/* Profile photo */}
            <div className="flex flex-col items-center mt-5">
              {imageUrl && selectedImage ? (
                <div className="w-14 bg-secondary-color rounded-lg h-14 flex justify-center items-center">
                  <img
                    src={imageUrl}
                    alt={selectedImage.name}
                    className="h-3/5 w-3/5"
                  />
                </div>
              ) : (
                <div className="w-14 bg-secondary-color rounded-lg h-14 flex justify-center items-center">
                  <ProfileImg />
                </div>
              )}
              <div className="flex items-center mt-3 ">
                <label
                  htmlFor="dropzone-file"
                  className=" border-2 border-primary-color text-primary-color text-sm py-1.5 px-6 rounded-lg font-normal cursor-pointer"
                >
                  {t("profile_pic")}
                  <input
                    {...register("user_image", {
                      onChange: (e) => setSelectedImage(e.target.files[0]),
                    })}
                    type="file"
                    className="hidden"
                    id="dropzone-file"
                    accept="image/*"
                  />
                </label>
              </div>
            </div>
            {/* Register form */}
            <div className="border border-gray-300 rounded-lg p-5 mt-6 mb-7">
              <div className="mb-3">
                <Input
                  name="password"
                  type="password"
                  isPassword={true}
                  label={t("password")}
                  fieldName={t("password")}
                  hint={t("login_password_hint")}
                  register={register}
                  errors={errors?.password}
                  required={true}
                  minLength="8"
                  maxLength="16"
                />
              </div>
              <div className="mb-3">
                <Input
                  register={register}
                  type="password"
                  isPassword={true}
                  name="confirm_password"
                  fieldName={t("confirm_password")}
                  label={t("confirm_password")}
                  hint={t("confirm_password_hint")}
                  required={true}
                  minLength="8"
                  maxLength="16"
                  validate={{
                    validateRepetition: (value) => {
                      return (
                        value === getValues("password") ||
                        t("password_mismatch")
                      );
                    },
                  }}
                  errors={errors?.confirm_password}
                />
              </div>
            </div>
            <button
              className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(touchedFields).length < 2 ||
                Object.keys(errors).length > 0
              }
            >
              {t("login")}
            </button>
            <div className="flex flex-col justify-center items-center mt-5">
              <span
                className="text-primary-color text-sm font-bold cursor-pointer"
                onClick={()=>{
                  navigate("/forgetPassword");
                }}
              >
                {t("forget_password")}
              </span>
              <span
                className=" text-sm font-medium mt-5 cursor-pointer"
                onClick={()=>{
                  navigate("/rfa");

                }}
              >
                {t("create_new_account")}
              </span>
            </div>
            {/* /register/token */}
          </div>
        </div>
      </div>
    </div>
  );
}
