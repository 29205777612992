import React, { useState, useEffect } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useTranslation } from "react-i18next";
import { useFetchBranches } from "../../Hooks/reactQuery/useFetchBranches";
import { useMutateEditBranch } from "../../Hooks/reactQuery/useMutateEditBranch";
import AddBranch from "./addBranch";
import EditBranch from "./editBranch";
import ViewBranch from "./viewBranch";
import BranchesFilters from "./branchesFilters";
import UploadBranches from "./uploadModal";

export default function Branches() {
  const { t, i18n } = useTranslation();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const { register, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const searchSelectArray = [
    {
      label: "Code",
      name: "code",
    },
    {
      label: "Name",
      name: "localized_name",
    },
    {
      label: "All",
      name: "all",
    },
  ];

  const [editBranch, setEditBranch] = useState(null);
  const [viewBranch, setViewBranch] = useState(null);

  const { mutate: mutateEditBranch } = useMutateEditBranch();

  const generateDropdownItems = (record) => [
    {
      key: "1",
      label: "Edit",
      icon: <CiEdit className="text-red-500" />,
      onClick: () => {
        setEditBranch(record);
      },
    },
    {
      key: "2",
      label: record.disabled == 1 ? "Enable" : "Disable",
      icon: <AiOutlineEyeInvisible />,
      onClick: () => {
        mutateEditBranch({
          id: record.id,
          edit: {
            status: record.disabled ? "1" : "0",
            locale: i18n.resolvedLanguage,
          },
        });
      },
    },
  ];

  let pageSize = 20;
  const [columnHeaders, setColumnHeaders] = useState(
    getColumns(generateDropdownItems, t, i18n)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [branches, setBranches] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [currentDateRange, setCurrentDateRange] = useState(null);
  const [brandIds, setBrandIds] = useState(null);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const [status, setStatus] = useState(null);

  const onSubmit = (request) => {
    const search = {};
    if (request.status && request.status.length == 1)
      setStatus(request.status[0]);
    else setStatus(null);

    if (request.brands) setBrandIds(request.brands);

    if (request.searchSelect && request.searchinput) {
      let key = request.searchSelect;
      if (Array.isArray(request.searchSelect))
        key = request.searchSelect[0].value;
      if (key.toLowerCase() == "all") {
        for (const field of searchSelectArray
          .map((obj) => obj.name)
          .filter((obj) => obj != "all")) {
          search[field] = request.searchinput;
        }
      } else {
        search[key] = request.searchinput;
      }
    }
    setSearch(JSON.stringify(search));
    if (dateRange) setCurrentDateRange(dateRange[0]);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: branchesData, isLoading: branchesLoading } = useFetchBranches(
    t,
    i18n,
    status,
    currentPage,
    pageSize,
    search,
    columnKey,
    order,
    brandIds,
    currentDateRange?.startDate,
    currentDateRange?.endDate
  );

  useEffect(() => {
    if (branchesData) {
      setBranches(branchesData.branches);
      setTotalCount(branchesData.count);
    }
  }, [branchesData]);

  const [params, setParams] = useSearchParams();

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  return (
    <div className="bg-inner-color h-full px-5 ">
      <TableHeader
        header={"Branches"}
        btnName={"Add New Branch"}
        handleModal={() => {
          setParams((params) => {
            params.set("addBranch", true);
            return params;
          });
        }}
      />
      <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
        <div className="flex justify-between items-center mb-5">
          <div className="flex-1">
            <SearchInput
              register={register}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              searchSelectArray={searchSelectArray}
            />
          </div>
          <div className="flex">
            <FilterButton
              handleFilter={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
            />
            <TableMoreOptions setShowUploadModal={setShowUploadModal} />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          data={branches}
          columns={columnHeaders}
          isLoading={branchesLoading}
          total={totalCount}
          currentPage={currentPage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  event.target.tagName.toLowerCase() != "span" &&
                  event.target.tagName.toLowerCase() != "svg" &&
                  event.target.tagName.toLowerCase() != "li"
                ) {
                  setViewBranch(record);
                }
              },
            };
          }}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onChange={handleTableChange}
        />
      </div>
      <Filters
        setIsOpen={setIsOpenFilter}
        isOpen={isOpenFilter}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      >
        <BranchesFilters register={register} setDateRange={setDateRange} />
      </Filters>
      <Modal
        isOpen={params.get("addBranch") === "true"}
        close={() => setParams()}
      >
        <AddBranch
          setIsOpen={(bool) =>
            setParams((params) => {
              bool ? params.set("addBranch", true) : params.delete("addBranch");
              return params;
            })
          }
          destroy={() =>
            setParams((params) => {
              params.delete("addBranch");
              return params;
            })
          }
        />
      </Modal>
      <Modal
        isOpen={editBranch !== null}
        close={() => {
          setEditBranch(null);
        }}
      >
        <EditBranch destroy={() => setEditBranch(null)} record={editBranch} />
      </Modal>
      <Modal
        isOpen={viewBranch !== null}
        close={() => {
          setViewBranch(null);
        }}
      >
        <ViewBranch
          record={viewBranch}
          destroy={() => setViewBranch(null)}
          setEditBranch={setEditBranch}
        />
      </Modal>
      <Modal
        isOpen={showUploadModal}
        close={() => {
          setShowUploadModal(false);
        }}
      >
        <UploadBranches destroy={() => setShowUploadModal(null)} />
      </Modal>
    </div>
  );
}
