import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";


export default function TaxesFilters({ register, setDateRange }) {
  const [showRange, setShowRange] = useState(true);
  const [showType, setShowType] = useState(true);
  const [showStatus, setShowStatus] = useState(true);



  const handleToggleRange = () => {
    setShowRange(old => !old);
  };
  const handleToggleStatus = () => {
    setShowStatus(!showStatus);
  };
  const handleToggleShowType = () => {
    setShowType(old => !old);
  };
  return (
    <div>
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Type</span>
        {showType ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleShowType}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleShowType}
          />
        )}{" "}
      </div>
      {showType ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("type")}
                    type="checkbox"
                    value={"percentage"}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Percentage</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("type")}
                    type="checkbox"
                    value={"value"}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Value</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}


      <div className="flex justify-between items-center mb-4 p-3">
        <span>Value</span>
        {showRange ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleRange}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleRange}
          />
        )}
      </div>
      {
        showRange ? (<div className="w-full flex justify-between items-center px-2">
          <div className="w-[30%] overflow-hidden">
            <input
              className="w-full border border-[#FB2576] p-2 rounded"
              {...register("startValue")}
              type="number"
            />
          </div>
          <span>TO</span>
          <div className="w-[30%] overflow-hidden">
            <input
              className="border border-[#FB2576] w-full p-2 rounded"
              {...register("endValue")}
              type="number"
            />
          </div>
        </div>) : null
      }

      <div className="flex justify-between items-center mb-4 p-3">
        <span>Status</span>
        {showStatus ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={handleToggleStatus}
          />
        )}{" "}
      </div>
      {showStatus ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={1}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Enable</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={0}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Disable</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}

    </div>
  );
}
