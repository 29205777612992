import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "Title",
      dataIndex: "localized_variant_type",
      key: "title",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.localized_variant_type.find(title => title.locale == i18n.resolvedLanguage).value}
      </div>
    },

    {
      title: "Options",
      dataIndex: "options",
      key: "options_count",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.options.map(obj => obj.localized_variant_option.find(title => title.locale == i18n.resolvedLanguage).value).slice(0, 4).join(", ")}
        {record.options.length - 4 > 0 ? ` ....+${record.options.length - 4}` : ""}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];