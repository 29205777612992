import React from "react";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";

export default function CheckYourEmail() {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();

  return (
    <div className="min-h-screen  ">
      <div className="flex flex-col">
        <BackToHomeAuth />
        <div className="flex-1 flex flex-col items-center">
          {/* Welcome */}
          <div className=" flex flex-col mt-10 items-center">
            <span className="ml-2 text-xl font-bold text-primary-color ">
              Check Your Email
            </span>
            <span className="text-sm font-medium">
              we have sent you an email if your request is accepted we will sent
              you another acception email
            </span>
          </div>
          <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col mt-10 "></div>
        </div>
      </div>
    </div>
  );
}
