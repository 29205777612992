import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BiImageAdd } from "react-icons/bi";
import { MdClear, MdOutlineDownloadForOffline } from "react-icons/md";
import { useMutateUploadBrands } from "../../Hooks/reactQuery/useMutateUploadBrands";

const UploadBrands = ({ destroy }) => {
  const {t, i18n} = useTranslation();
  const [file, setFile] = useState(null);
  const [report, setReport] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    watch,
    setValue,
    setError,
    clearErrors
  } = useForm({
    defaultValues: [],
    mode: "onChange"
  });

  useEffect(() => {
    if(file) {
      if(file.type !== "text/csv") alert("Only csv files are allowed!");
    }
  }, [file]);

  const { mutate, isLoading, isSuccess } = useMutateUploadBrands(setReport);

  const onSubmit = async (request) => {
    if(file) {
      const formData = new FormData();
      formData.append("locale", i18n.resolvedLanguage);
      formData.append("brands", file);
      console.log(formData)
      mutate(formData);
    }
  };

  return (
    <div className="flex-col w-full bg-white py-8 h-auto min-h-[300px] overflow-hidden">
      <h1 className="font-bold text-2xl">Upload Brands</h1>
      {report && isSuccess ? <>
        <h1>Results:</h1>
        <div className="flex-col h-full justify-between">
          <div className="flex-col">
            {report.split(",").map((msg, index) => <h3 className={`${index === 0 ? "text-lime-700" : "text-red-700"}`}>{msg}</h3>)}
          </div>
          <div className="flex justify-center sticky bottom-0 bg-[#fff] p-2 my-4">
            <button
              className={`w-40 border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold justify-self-end mt-auto`}
              onClick={destroy}
            >
              Ok
            </button>
          </div>
        </div>
      </>
      : <>
        <div className="w-full rounded flex justify-between items-center p-3 my-2 bg-[#f9e1ea]">
          <h1 className="m-0">Download upload template</h1>
          <a href={`${process.env.REACT_APP_API_URL}templates/brands?locale=${i18n.resolvedLanguage}`} className="flex justify-center items-center text-[#FB2576] hover:text-[#FB2576]" download="measurement_units.csv" target="_blank">
            <span className="mx-2 font-bold">Download</span>
            <MdOutlineDownloadForOffline size="20" color={"FB2576"}/>
          </a>
        </div>
        {!file ? <div className="w-full flex-col justify-start items-start">
          <h5>Choose Data File</h5>
          <label for="file-upload" className="w-full flex-col border-2 border-gray-200 border-dashed h-[200px] flex justify-center items-center cursor-pointer">
            <BiImageAdd color="#FB2576" size="50"/>
            <h8>Upload File From Your Device</h8>
            <h9 className="text-slate-400">Only Csv Files Allowed</h9>
          </label>
          <input
            type="file"
            name="file-upload"
            id={`file-upload`}
            style={{ display: "none" }}
            {...register("brands", {
              onChange: (e) => {setFile(e.target.files[0]);},
            })}
            disabled={isLoading}
          />
        </div> : <>
            <div className="w-full flex justify-between p-4 border my-4"> 
              <h1 className="m-0">{file.name}</h1>
              <button onClick={() => {
                if(!isLoading) setFile(null)}
              }>
                <MdClear />
              </button>
            </div>
        </>}
        <div className="flex justify-center sticky bottom-0 bg-[#fff] p-2 my-4">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || isLoading || !file ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={onSubmit}
            disabled={!isDirty || !isValid || isLoading || !file}
          >
            {isLoading ? "...Loading" : "Upload"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={destroy}
          >
            Cancel
          </button>{" "}
        </div>
      </>}
    </div>
  );
};

export default UploadBrands;
