const BusinessImg = ({ fillColor }) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.1987 14.1278L37.7033 9.39567C36.9858 4.2365 34.6454 2.13525 29.64 2.13525H25.6083H23.08H17.8866H15.3583H11.2583C6.2358 2.13525 3.91246 4.2365 3.17788 9.44692L2.71663 14.1448C2.5458 15.9728 3.04121 17.7494 4.11746 19.1332C5.4158 20.8244 7.41455 21.7811 9.63538 21.7811C11.7879 21.7811 13.855 20.7048 15.1533 18.9794C16.315 20.7048 18.2966 21.7811 20.5004 21.7811C22.7041 21.7811 24.6345 20.7561 25.8133 19.0478C27.1287 20.739 29.1616 21.7811 31.28 21.7811C33.552 21.7811 35.602 20.7732 36.8833 18.9965C37.9083 17.6298 38.3695 15.9044 38.1987 14.1278Z"
        fill="#FB2576"
      />
      <path
        d="M19.3897 28.4606C17.2201 28.6827 15.5801 30.5277 15.5801 32.7143V37.3952C15.5801 37.8564 15.9559 38.2323 16.4172 38.2323H24.5659C25.0272 38.2323 25.403 37.8564 25.403 37.3952V33.3123C25.4201 29.7418 23.3188 28.0506 19.3897 28.4606Z"
        fill="#FB2576"
      />
      <path
        d="M36.5064 24.5999V29.6907C36.5064 34.4057 32.6797 38.2324 27.9647 38.2324C27.5034 38.2324 27.1276 37.8566 27.1276 37.3953V33.3124C27.1276 31.1257 26.4614 29.4174 25.163 28.2557C24.0184 27.2136 22.4639 26.7011 20.5334 26.7011C20.1064 26.7011 19.6793 26.7182 19.218 26.7695C16.1772 27.077 13.8709 29.6395 13.8709 32.7145V37.3953C13.8709 37.8566 13.4951 38.2324 13.0339 38.2324C8.31885 38.2324 4.49219 34.4057 4.49219 29.6907V24.6341C4.49219 23.4382 5.67094 22.6353 6.78135 23.0282C7.2426 23.182 7.70385 23.3016 8.18219 23.3699C8.38719 23.4041 8.60927 23.4382 8.81427 23.4382C9.0876 23.4724 9.36094 23.4895 9.63427 23.4895C11.6159 23.4895 13.5634 22.7549 15.1009 21.4907C16.5701 22.7549 18.4834 23.4895 20.4993 23.4895C22.5322 23.4895 24.4114 22.7891 25.8805 21.5249C27.418 22.772 29.3314 23.4895 31.2789 23.4895C31.5864 23.4895 31.8939 23.4724 32.1843 23.4382C32.3893 23.4211 32.5772 23.4041 32.7651 23.3699C33.2947 23.3016 33.773 23.1478 34.2514 22.9941C35.3618 22.6182 36.5064 23.4382 36.5064 24.5999Z"
        fill="#FB2576"
      />
    </svg>
  );
};

export default BusinessImg;
