import React, { useEffect, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";
import { useTranslation } from "react-i18next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import DateRangePickerComponent from "../../Components/DateRangePicker/DateRangePicker";
import Input from "../../Components/Input/Input";


export default function VariantTypesFilters({ register }) {
  const { t, i18n } = useTranslation();

  const [showStatus, setShowStatus] = useState(true);

  return (
    <div>
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Status</span>
        {showStatus ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={() => {
              setShowStatus(old => !old)
            }}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={() => {
              setShowStatus(old => !old)
            }}          
          />
        )}
      </div>
      {showStatus ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={1}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Enable</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={0}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Disable</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}
    </div>
  );
}
