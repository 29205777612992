import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addProduct = async(obj) => {
  return await apiClient?.patch(`products/${obj.id}`, obj.edit);

};
export const useMutateEditProduct = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addProduct, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("all-products");
      navigate("/products");
    }
  });
};
