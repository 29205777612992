import React from "react";
import SystemLog from "../SystemLog/index";

const Home = () => {
  
  return (
    <SystemLog />
  );
};

export default Home;
