import React, { useState, useEffect } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import ViewMeasurementUnit from "./viewTax";
import { useMutateEditTax } from "../../Hooks/reactQuery/useMutateEditTax";
import { useFetchTaxes } from "../../Hooks/reactQuery/useFetchTaxes";
import AddTax from "./addTax";
import EditTax from "./editTax";
import ViewTax from "./viewTax";
import TaxesFilters from "./taxesFilters";
import UploadTaxes from "./uploadModal";

export default function Taxes() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

  const [showUploadModal, setShowUploadModal] = useState(false);

  const { register, handleSubmit, control } = useForm({
    mode: "onSubmit",
  });

  const searchSelectArray = [
    {
      label: "Code",
      name: "code",
    },
    {
      label: "Title",
      name: "title",
    },
    {
      label: "Type",
      name: "type",
    },
    {
      label: "Value",
      name: "value",
    },
  ];

  const [editTax, setEditTax] = useState(null);
  const [viewTax, setViewTax] = useState(null);
  const { mutate: mutateEditTax } = useMutateEditTax();

  const generateDropdownItems = (record) => [
    {
      key: "1",
      label: "Edit",
      icon: <CiEdit className="text-red-500" />,
      onClick: () => {
        setEditTax(record);
      },
    },
    {
      key: "2",
      label: record.disabled == 1 ? "Enable" : "Disable",
      icon: <AiOutlineEyeInvisible />,
      onClick: () => {
        mutateEditTax({
          id: record.id,
          edit: {
            status: record.disabled ? "1" : "0",
            locale: i18n.resolvedLanguage,
          },
        });
      },
    },
  ];

  let pageSize = 20;
  const [columnHeaders, setColumnHeaders] = useState(
    getColumns(generateDropdownItems, t, i18n)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [columnKey, setColumnKey] = useState("");
  const [order, setOrder] = useState("");
  const [totalCount, setTotalCount] = useState(1);
  const [taxes, setTaxes] = useState([]);

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
  };

  const [status, setStatus] = useState(null);
  const [startValue, setStartValue] = useState(null);
  const [endValue, setEndValue] = useState(null);
  const [type, setType] = useState(null);

  const onSubmit = (request) => {
    const search = {};
    if (request.status && request.status.length == 1)
      setStatus(request.status[0]);
    else setStatus(null);

    if (request.startValue && request.startValue !== "")
      setStartValue(request.startValue);
    if (request.endValue && request.endValue !== "")
      setEndValue(request.endValue);
    if (request.type && request.type.length == 1) setType(request.type[0]);
    else setType(null);

    if (request.searchSelect && request.searchinput) {
      let key = request.searchSelect;
      if (Array.isArray(request.searchSelect))
        key = request.searchSelect[0].value;
      if (key.toLowerCase() == "all") {
        for (const field of searchSelectArray
          .map((obj) => obj.name)
          .filter((obj) => obj != "all")) {
          search[field] = request.searchinput;
        }
      } else {
        search[key] = request.searchinput;
      }
    }
    setSearch(JSON.stringify(search));
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const { data: taxesData, isLoading: taxesLoading } = useFetchTaxes(
    t,
    i18n,
    search,
    columnKey,
    order,
    type,
    status,
    startValue,
    endValue,
    currentPage,
    pageSize
  );

  useEffect(() => {
    if (taxesData) {
      setTaxes(taxesData.taxes);
      setTotalCount(taxesData.count);
    }
  }, [taxesData]);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [params, setParams] = useSearchParams();

  return (
    <div className="bg-inner-color h-full px-5 ">
      <TableHeader
        header={"Taxes"}
        btnName={"Add New Tax"}
        handleModal={() => {
          setParams((params) => {
            params.set("addTax", true);
            return params;
          });
        }}
      />
      <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
        <div className="flex justify-between items-center mb-5">
          <div className="flex-1">
            <SearchInput
              register={register}
              control={control}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              searchSelectArray={searchSelectArray}
            />
          </div>
          <div className="flex">
            <FilterButton
              handleFilter={() => {
                setIsOpenFilter(!isOpenFilter);
              }}
            />
            <TableMoreOptions setShowUploadModal={setShowUploadModal} />
          </div>
        </div>
        <Table
          rowKey={(record) => record.id}
          data={taxes}
          columns={columnHeaders}
          isLoading={taxesLoading}
          total={totalCount}
          currentPage={currentPage}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                if (
                  event.target.tagName.toLowerCase() != "span" &&
                  event.target.tagName.toLowerCase() != "svg" &&
                  event.target.tagName.toLowerCase() != "li"
                ) {
                  setViewTax(record);
                }
              },
            };
          }}
          pageSize={pageSize}
          onPageChange={onPageChange}
          onChange={handleTableChange}
        />
      </div>
      <Filters
        setIsOpen={setIsOpenFilter}
        isOpen={isOpenFilter}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
      >
        <TaxesFilters register={register} />
      </Filters>
      <Modal isOpen={params.get("addTax") === "true"} close={() => setParams()}>
        <AddTax
          setIsOpen={(bool) =>
            setParams((params) => {
              bool ? params.set("addTax", true) : params.delete("addTax");
              return params;
            })
          }
          destroy={() =>
            setParams((params) => {
              params.delete("addTax");
              return params;
            })
          }
        />
      </Modal>
      <Modal
        isOpen={editTax !== null}
        close={() => {
          setEditTax(null);
        }}
      >
        <EditTax destroy={() => setEditTax(null)} record={editTax} />
      </Modal>
      <Modal
        isOpen={viewTax !== null}
        close={() => {
          setViewTax(null);
        }}
      >
        <ViewTax
          record={viewTax}
          destroy={() => setViewTax(null)}
          setEditTax={setEditTax}
        />
      </Modal>
      <Modal
        isOpen={showUploadModal}
        close={() => {
          setShowUploadModal(false);
        }}
      >
        <UploadTaxes destroy={() => setShowUploadModal(null)} />
      </Modal>
    </div>
  );
}
