
// import { useQuery } from "react-query";
// import apiClient from '../../services/apiClient'

// export const useFetchBusinessIndustries = (t, i18n) => {
//   return useQuery("business-industries", async()=>{
//     await apiClient?.get(`companies/business_industry`).then(({data})=>{
//       const businessData = data?.data?.map((x) => {
//         const localized = x.localized_business_industry;
//         const currentLang = localized.find(
//           (y) => i18n.resolvedLanguage == y.locale
//         );
//         return {
//          id:x.id,
//           name: currentLang.value,
//         };
//       });
//       return  businessData;
//     })
    
//   });
// };

import { useQuery } from "react-query";
import apiClient from '../../services/apiClient'

const fetchBusinessIndustries = async() => {
  return await apiClient?.get(`companies/business_industry`);
};
export const useFetchBusinessIndustries = (t, i18n) => {
  return useQuery("business-industries", fetchBusinessIndustries, {
    select: ({data}) => {
      const businessData = data?.data?.map((x) => {
        const localized = x.localized_business_industry;
        const currentLang = localized.find(
          (y) => i18n.resolvedLanguage == y.locale
        );
        return {
         id:x.id,
          name: currentLang.value,
        };
      });
      return businessData;
      
    },
  });
};
