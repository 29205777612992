import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { Controller, useFieldArray } from "react-hook-form";
import { languages, staticBaseUrl } from "../../utils/constants";
import { useFetchBrandCategories } from "../../Hooks/reactQuery/useFetchBrandCategories";
import TextArea from "../../Components/Input/Textarea";
import Trash from "../../Icons/Trash/Trash";
import { BiImageAdd, BiPlus } from "react-icons/bi";
import { useFetchBranches } from "../../Hooks/reactQuery/useFetchBranches";
import apiClient from "../../services/apiClient";
import { MdCancel } from "react-icons/md";

export default function EditBrandModal({
  destroy,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  setLocalizedTitle,
  localizedTitle,
  clearErrors,
  isLoading,
  files,
  setFiles,
  isValid,
  isDirty,
  record
}) {

  const { fields, append, remove } = useFieldArray({
    control,
    name: "branches",
  });

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(languages[0].value);

  const title = watch('title');

  useEffect(() => {
    const langTitle = localizedTitle[language] ? localizedTitle[language] : "";
    setValue(`title`, langTitle);
    clearErrors(`title`);
  }, [language]);

  useEffect(() => {
    if(title && title != '') setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      newLocalizedTitle[`${language}`] = title;
      return newLocalizedTitle;
    });
    else setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      delete newLocalizedTitle[`${language}`];
      return newLocalizedTitle;
    })
  }, [title]);

  const generateCode = () => {
    clearErrors("code");
    setValue("code", Date.now().toString(36) + Math.random().toString(36).substr(2), { shouldDirty: true, shouldValidate: true });
  }

  const { data: categoriesData, isLoading: categoriesLoading } = useFetchBrandCategories(t, i18n);
  const { data: branchesData, isLoading: branchesLoading } = useFetchBranches(t, i18n);

  return (
    <>
      <div className="py-8">
        <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
          Edit Brand
        </h1>
        <div className="w-full flex justify-between">
          <div className="bg-[#fff] p-2 flex rounded-md flex-col w-[68%]">
            <div className="w-[48%] relative">
              <span className={`absolute top-[35%] left-[85%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
              ${Object.keys(localizedTitle).length > 0 ? "bg-emerald-300	text-emerald-700" : "bg-red-300 text-red-600"}`}>{Object.keys(localizedTitle).length}</span>
              <SelectMenu
                name={"language"}
                fieldName={"language"}
                hint={"Select Language"}
                label={"Language"}
                placeholder={""}
                placeHolder={""}
                defaultValue={{label: "English", value: "en"}}
                options={languages}
                onChangeHandler={(val) => setLanguage(val)}
                Controller={Controller}
                control={control}
              />
            </div>
            <div className="w-full">
              <Input
                register={register}
                type="text"
                name="title"
                fieldName={"title"}
                label={`Brand Title In ${languages.find(lang => lang.value == language).label}`}
                placeholder={""}
                hint={`Enter Brand Title In ${languages.find(lang => lang.value == language).label}`}
                required={true}
                errors={errors?.title}
              />
            </div>
            <div className="w-full flex justify-between">
              <div className="w-[49%] relative">
                <Input
                  register={register}
                  type="text"
                  name="code"
                  fieldName={"code"}
                  label={"Code"}
                  placeholder={""}
                  hint={"Enter The Code Of The Brand"}
                  required={true}
                  validate={
                    {
                      validate: async (value) => {
                        if(record.code !== value) {
                          if(value.length < 1) return "Please Enter A Valid Code";
                          const { data } = await apiClient.get(
                            "/brands/code",
                            { params: { code: value } }
                          );
                          if(data.data?.exists) return "Code Must Be Unique";
                          else return null;
                        }
                        return null;
                      }
                    }
                  }
                  errors={errors?.code}
                />
                <button 
                  className={`absolute top-[35%] text-[#FB2576] ${i18n.resolvedLanguage === "ar" ? "left-[5px]" : "right-[5px]"}`}
                  onClick={generateCode}
                >
                  Generate
                </button>
              </div>
              <div className="w-[49%]">
                <SelectMenu
                  name={"brand_category"}
                  fieldName={"category"}
                  hint={"Select The Brand Category"}
                  label={"Brand Category"}
                  placeholder={""}
                  isLoading={categoriesLoading}
                  placeHolder={""}
                  required={true}
                  options={
                    categoriesData?.categories?.map((item) => {
                      return {
                        label: item.name,
                        value: item.id.toString(),
                      };
                    })
                  }
                  Controller={Controller}
                  control={control}
                  errors={errors?.brand_category}
                />
              </div>
            </div>
            <div className="w-full">
              <TextArea
                register={register}
                type="text"
                name="vision"
                fieldName={"vision"}
                label={"Brand Vision"}
                placeholder={""}
                hint={"Enter The Brand Vision"}
                required={false}
                errors={errors?.vision}
              />
            </div>
            <div className="w-full">
              <TextArea
                register={register}
                type="text"
                name="mission"
                fieldName={"mission"}
                label={"Brand Mission (optional)"}
                placeholder={""}
                hint={"Enter The Brand Mission"}
                required={false}
                errors={errors?.mission}
              />
            </div>

            <ul className="flex-col border-2 border-[#FB25760D] rounded pb-2">
              <div className="flex items-center justify-between bg-[#FB25760D] p-2">
                <div className="w-8 justify-start items-center">#</div>
                <div className="w-[37%] justify-start items-center">Branch Code</div>
                <div className="w-[47%] justify-start items-center">Brach Name</div>
                <div className="w-[5%]"></div>
              </div>
              {fields.map((item, index) => {
                return (
                  <li key={index} className={"pr-2 pl-2"}>
                    <div className="flex mt-3 items-center justify-between">
                      <div className="w-8 h-8 cursor-pointer flex justify-center items-center border-2 border-primary-color rounded bg-[#FFE8F0] text-primary-color">
                        {index + 1}
                      </div>
                      <div className="w-[37%]">
                        <Controller
                          render={({ field }) => (
                            <SelectMenu
                              {...field}
                              placeholder={"Branch Code"}
                              isLoading={branchesLoading}
                              options={
                                branchesData?.branches?.map(obj => {
                                  return {
                                    label: obj.code,
                                    value: obj.id.toString()
                                  }
                                })
                              }
                              Controller={Controller}
                              control={control}
                            />
                          )}
                          name={`branches[${index}]`}
                          control={control}
                        />
                      </div>
                      <div className="w-[47%]">
                        <Controller
                          render={({ field }) => (
                            <SelectMenu
                              {...field}
                              placeholder={"Branch Name"}
                              isLoading={branchesLoading}
                              options={
                                branchesData?.branches?.map(obj => {
                                  return {
                                    label: obj.name,
                                    value: obj.id.toString()
                                  }
                                })
                              }
                              Controller={Controller}
                              control={control}
                            />
                          )}
                          name={`branches[${index}]`}
                          control={control}
                        />
                      </div>
                      <div className="w-[5%] cursor-pointer" onClick={() => remove(index)}>
                        <Trash />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <button
              onClick={() => {
                append("");
              }}
            >
              <div className="flex">
                <div className="bg-primary-color w-6 h-6 rounded-lg flex justify-center items-center text-white text-lg">
                  <BiPlus />
                </div>
                <span className="ml-3 text-primary-color font-semibold text-md">
                  Add New Branch
                </span>
              </div>
            </button>
          </div>
          <div className="flex justify-end items-start w-[28%] p-2">
            <div className="w-full flex-col justify-start items-start">
              <h5>Brand Logo</h5>
              <div className="w-full flex-col border-2 border-gray-200 border-dashed h-[300px] flex justify-start items-center">
                <div className="w-full p-2 h-auto overflow-hidden justify-self-start align-self-start">
                  <div className="w-auto flex flex-no-wrap h-[70px] overflow-x-auto overflow-y-hidden pt-[10px]">
                    {files.length > 0 ? files.map(img => <div className="relative mr-[10px] h-[50px] min-w-[50px]">
                      <button className="absolute top-[-10px] right-[-10px]" onClick={(e) => {
                        e.stopPropagation();
                        setFiles(old => old.filter(obj => obj.name != img.name));
                      }}>
                        <MdCancel size={20} />
                      </button>
                      <img className="w-[50px] h-[50px] mr-[10px]" src={URL.createObjectURL(img)} />
                    </div>) : null}
                    {record.logo && files.length === 0 ? <div className="relative mr-[10px] h-[50px] min-w-[50px]">
                      <img className="w-[50px] h-[50px] mr-[10px]" src={staticBaseUrl + record.logo} />
                    </div> : null}
                  </div>
                </div>
                <label for="file-upload" className="flex flex-col w-full flex-1 cursor-pointer justify-center items-center">
                  <BiImageAdd color="#FB2576" size="50"/>
                  <h8>Upload File From Your Device</h8>
                </label>
              </div>
              <input
                type="file"
                name="file-upload"
                id={`file-upload`}
                style={{ display: "none" }}
                {...register("images_array", {
                  onChange: (e) => {setFiles(Array.from(e.target.files));},
                })}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || isLoading ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? "...Loading" : "Edit"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={() => destroy()}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
    </>   
  );
}
