import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateEditBranch } from "../../Hooks/reactQuery/useMutateEditBranch";
import { languages } from "../../utils/constants";
import AddBranchModal from "./addBranchModal";
import EditBranchModal from "./editBranchModal";

const EditBranch = ({ destroy, record }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: record?.code,
    title: record?.localized_branch_name?.find(obj => obj.locale == languages[0].value)?.value,
    opening_date: record?.opening_date,
    phone_numbers: record?.phone_numbers?.map(obj => ({number: obj.phone_number, type: obj.type})),
    brand_id: record.brands && record.brands.length > 0 ? record?.brands[0]?.id.toString() : "",
    governorate: record?.address?.governorate,
    city: record?.address?.city,
    district: record?.address?.district,
    street: record?.address?.street,
    block: record?.address?.block,
    bul: record?.address?.bul,
    longitude: record?.address?.longitude ? record?.address?.longitude : "",
    latitude: record?.address?.latitude ? record?.address?.latitude : "",
    other_info: record?.address?.other_info ? record?.address?.other_info : ""
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  const { mutate, isLoading, isSuccess } = useMutateEditBranch();

  const onSubmit = async (request) => {
    const data = {
      id: record.id,
      edit: {
        name: Object.keys(localizedTitle).map(locale => {
          return {
            locale, 
            value: localizedTitle[locale]
          }
        }),
        code: request.code,
        brand_id: request.brand_id,
        phone_numbers: request.phone_numbers,
        address: {
          governorate: request.governorate,
          city: request.city,
          district: request.district,
          street: request.street,
          block: request.block,
          bul: request.bul,
          other_info: request.other_info,
          longitude: request.longitude,
          latitude: request.latitude
        },
        locale: i18n.resolvedLanguage
      }
    }

    mutate(data);
    destroy();
  };

  useEffect(() => {
    if(record) {
      setValue("code", record.code, { shouldValidate: true });
      setValue("title", record.localized_branch_name?.find(obj => obj.locale == languages[0].value)?.value);
      setValue("phone_numbers", record.phone_numbers.map(obj => ({number: obj.phone_number, type: obj.type})));
      setValue("opening_date",  record.opening_date);
      setValue("governorate", record.address.governorate);
      setValue("city", record.address.city);
      setValue("district", record.address.district);
      setValue("street", record.address.street);
      setValue("block", record.address.block);
      setValue("bul", record.address.bul);
      setValue("other_info", record.address.other_info ? record.address.other_info : "");
      setValue("longitude", record.address.longitude ? record.address.longitude : "");
      setValue("latitude", record.address.latitude ? record.address.latitude : "");
      setValue("brand_id", record.brands && record.brands.length > 0 ? record?.brands[0]?.id.toString() : "");
    }
  }, [record]);

  return (
    <div>
      <EditBranchModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          isValid,
          isDirty
        }}
        destroy={destroy}
        record={record}
      />
    </div>
  );
};

export default EditBranch;
