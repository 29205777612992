import React, { useState, useEffect, useRef } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

const libraries = ["places"];

const SearchMap = (props) => {
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: props.lat ? props.lat : 37.7749, lng: props.lng ? props.lng : -122.4194 });
  const [marker, setMarker] = useState(null);
  const [search, setSearch] = useState('');
  const searchInputRef = useRef(null);


  useEffect(() => {
    if(window && window.google && window.google.maps) {
      const autoComplete = new window.google.maps.places.Autocomplete(searchInputRef.current);
      autoComplete.addListener('place_changed', () => {
        const place = autoComplete.getPlace();
        if (!place.geometry) return;
        setCenter({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        setMarker(place.geometry.location);
        props.setLongitude(place.geometry.location.lng());
        props.setLatitude(place.geometry.location.lat());
      });
    }

    if(props.lng && props.lat) {
      setMarker({lat: props.lat, lng: props.lng});
      setCenter({lat: props.lat, lng: props.lng});
    }
  }, [props]);

  const onMapLoad = map => {
    setMap(map);
  };

  return (
    <div>
      <input
        className='w-[50%] p-2 border rounded my-2'
        ref={searchInputRef}
        value={search}
        onChange={e => setSearch(e.target.value)}
        placeholder="Enter a location"
      />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        id="script-loader"
        language="en"
        libraries={libraries}
      >
        <GoogleMap
          onLoad={onMapLoad}
          center={center}
          zoom={12}
          mapContainerStyle={{ height: '500px', width: '100%' }}
          onClick={props.onClick}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default SearchMap;