
// import { useQuery } from "react-query";
// import apiClient from '../../services/apiClient'

// export const useFetchBusinessSizes = () => {
//   return useQuery('business-sizes', ()=>{
//     apiClient
//     ?.get(`companies/business_size`).then(({data})=>{
//       return data.data

//     })
//   }
//   )
//   };
import { useQuery } from "react-query";
import apiClient from '../../services/apiClient'

const fetchBusinessSizes = async() => {
  return await apiClient?.get(`companies/business_size`);
};
export const useFetchBusinessSizes=()=>{
  return useQuery('business-sizes',fetchBusinessSizes,{
    select:({data})=>{
      return data.data
    }
  }
  )
}
