import React, { useEffect, useState } from "react";
import Trash from "../../Icons/Trash/Trash";
import { BiPlus } from "react-icons/bi";
import { useFetchJobTitles } from "../../Hooks/reactQuery/useFetchJobTitles";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { useFieldArray, Controller } from "react-hook-form";
import Modal from "../../Components/Modal/Modal";
import AddNewModule from "./AddNewModule";
import { useFetchBrands } from "../../Hooks/reactQuery/useFetchBrands";
export default function AddNewUserModal({
  setIsOpen,
  control,
  register,
  setValue,
  getValues,
  handleSubmit,
  onSubmit,
  errors,
  watch,
  // arrayLength
}) {
    // const [arrayLength, setArrayLength] = useState(0);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "userData",
  });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t, i18n } = useTranslation();
  const { data: jobTitles, isLoading: jobTitlesLoading } = useFetchJobTitles(
    t,
    i18n
  );

  const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(t, i18n);

  const [brandsSelect, setBrandsSelect] = useState([]);

  useEffect(() => {
    if(brandsData) setBrandsSelect(brandsData.brands);
  }, [brandsData]);

  return (
    
    <div className="py-8">
      <h1 className="text-xl font-semibold text-[#0C2248] pb-6">
        Add New User
      </h1>
      <div className="bg-[#F8FAFC] py-5 px-14 flex rounded-md  ">
        <div className="flex flex-col flex-1 h-16">
          <h4>Email</h4>
          <p>Type this user’s email, to add and contact later.</p>
        </div>
        <div className="flex flex-col mx-8  h-16">
          <h4>Jobtitle</h4>
          <p>What’s this new user’s jobtitle?</p>
        </div>
        <div className="flex flex-col mx-8  h-16">
          <h4>Brand</h4>
          <p>What’s this new user’s brand?</p>
        </div>
        <div className="flex flex-col flex-1  h-16">
          <h4>Module</h4>
          <p>Which Inframodern module this user’s authorized to use?</p>
        </div>
      </div>
      <ul>
        {fields.map((item, index) => {
          return (
            <li key={index}>
              <div className="flex mt-3 items-center">
                <div className="w-8 h-8 cursor-pointer flex justify-center items-center border-2 border-primary-color rounded bg-[#FFE8F0] text-primary-color">
                  {index + 1}
                </div>
                <div className="flex-1 ml-3">
                  <Input
                    register={register}
                    type="text"
                    name={`userData[${index}].email`}
                    placeHolder={"Example@example.com"}
                    fieldName={"email"}
                    required={true}
                  />
                </div>
                <div className="mx-5 w-1/5">
                  <Controller
                    render={({ field }) => (
                      <SelectMenu
                        {...field}
                        placeholder={"CFO,Accountant,etc"}
                        isLoading={jobTitlesLoading}
                        options={
                          jobTitles &&
                          jobTitles.map((item) => {
                            return {
                              label: item.name,
                              value: item.id.toString(),
                            };
                          })
                        }
                        placeHolder={"CFO, Accountant, etc"}
                        Controller={Controller}
                        control={control}
                      />
                    )}
                    name={`userData[${index}].job_title_id`}
                    control={control}
                    defaultValue={item.job_title_id} // make sure to set up defaultValue
                  />
                </div>
                <div className="mx-5 w-1/5">
                  <Controller
                    render={({ field }) => (
                      <SelectMenu
                        {...field}
                        placeholder={"Choose Brand"}
                        isLoading={jobTitlesLoading}
                        options={
                          brandsSelect &&
                          brandsSelect.map((item) => {
                            return {
                              label: item.name,
                              value: item.id.toString()
                            };
                          })
                        }
                        placeHolder={"Choose Brand"}
                        Controller={Controller}
                        control={control}
                      />
                    )}
                    name={`userData[${index}].brand_id`}
                    control={control}
                    defaultValue={item.job_title_id} // make sure to set up defaultValue
                  />
                </div>

                <div
                  className={`cursor-pointer flex-1  mr-3 border rounded-md border-primary-color p-1.5 flex justify-center items-center text-md font-bold text-primary-color ${
                    i18n.resolvedLanguage === "en"
                      ? "pl-3 text-left"
                      : "pr-3 text-right"
                  } `}
                  onClick={() => {
                    setIsOpenModal(true);
                  }}
                >
                  Add Modules ({getValues(`userData[${index}].authorized_modules`).length})
                </div>
                {isOpenModal ? (
                  <Modal
                    isOpen={isOpenModal}
                    close={() => {
                      setIsOpenModal(false);
                    }}
                  >
                    <AddNewModule
                      setIsOpenModal={setIsOpenModal}
                      nestIndex={index}
                      {...{ control }}
                    />
                  </Modal>
                ) : (
                  ""
                )}
                <div className="cursor-pointer" onClick={() => remove(index)}>
                  <Trash />
                </div>
              </div>




            </li>
          );
        })}
      </ul>

      <button
        onClick={() => {
          append({ email: "", job_title_id: "", authorized_modules: [] });
        }}
      >
        <div className="flex">
          <div className="bg-primary-color w-6 h-6 rounded-lg flex justify-center items-center text-white text-lg">
            <BiPlus />
          </div>
          <span className="ml-3 text-primary-color font-semibold text-md">
            {" "}
            Add New User
          </span>
        </div>
      </button>
      <div className="mt-10 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={handleSubmit(onSubmit)}
        >
          Create
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </button>{" "}
      </div>
    </div>
  );
}
