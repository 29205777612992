import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient'
const resetPassword = async(email) => {
  return await apiClient?.post(`users/reset_password`, email);

};
export const useMutateResetPassword = () => {
  let navigate = useNavigate();
  return useMutation(resetPassword, {
    onSuccess: (data) => {
      localStorage.setItem("update_password_token", data?.data.data.token);
    },
 
  });
};
