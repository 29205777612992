import React, { useTransition } from "react";
import { VscSearch } from "react-icons/vsc";
import { Controller } from "react-hook-form";
import Input from "./Input";
import { useForm } from "react-hook-form";
import SelectMenu from "./Select";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useTranslation } from "react-i18next";
export default function SearchInput({register,control,handleSubmit,onSubmit, searchSelectArray}) {

  const {t, i18n} = useTranslation();
  return (
    <div>
      {" "}
      <div className="flex justify-between">
        <div className="relative w-2/5">
          <form onChange={handleSubmit(onSubmit)}>
            <Input
              name="searchinput"
              register={register}
              type="text"
              icon={<VscSearch className={`text-gray-500`} />}
              placeHolder={t("can_i_help_you")}
              onChange={onSubmit}
            />

            <div className={`absolute ${i18n.resolvedLanguage === "ar" ? "left-0" : "right-0"} w-1/4 bottom-0 top-2`}>
              <SelectMenu
                defaultValue={
                  searchSelectArray &&
                  searchSelectArray.slice(0, 1).map((item) => {
                    return {
                      label: item.label,
                      value: item.name,
                    };
                  })
                }
                icon={<MdKeyboardArrowDown className="text-xl" />}
                name="searchSelect"
                fieldName={"searchselect"}
                options={
                  searchSelectArray &&
                  searchSelectArray.map((item) => {
                    return {
                      label: item.label,
                      value: item.name,
                    };
                  })
                }
                Controller={Controller}
                control={control}
              />{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
