import React, { useState, useEffect } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { useMutateEditBrand } from "../../Hooks/reactQuery/useMutateEditBrand";
import { useFetchBrands } from "../../Hooks/reactQuery/useFetchBrands";
import EditBrand from "./editBrand";
import BrandsFilters from "./brandsFilters";
import ViewBrand from "./viewBrand";
import AddBrand from "./addBrand";
import UploadBrands from "./uploadModal";

export default function Brands() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const { register, handleSubmit, control } = useForm({
        mode: "onSubmit",
    });

    const searchSelectArray = [
        {
            label: "Code",
            name: "code",
        },
        {
            label: "Name",
            name: "localized_name",
        },
        {
            label: "All",
            name: "all",
        },
    ]

    const [editBrand, setEditBrand] = useState(null);
    const [viewBrand, setViewBrand] = useState(null);

    const {mutate: mutateEditBrand} = useMutateEditBrand();


    const generateDropdownItems = (record) => [
        {
            key: "1",
            label: "Edit",
            icon: <CiEdit className="text-red-500" />,
            onClick: () => {
                setEditBrand(record);
            },
        },
        {
            key: "2",
            label: record.disabled == 1 ? "Enable" : "Disable",
            icon: <AiOutlineEyeInvisible />,
            onClick: () => {
                mutateEditBrand({id: record.id, edit: {
                    status: record.disabled ? "1" : "0",
                    locale: i18n.resolvedLanguage
                } });
            },
        },
    ];

    let pageSize = 20;
    const [columnHeaders, setColumnHeaders] = useState(getColumns(generateDropdownItems, t, i18n));
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [columnKey, setColumnKey] = useState("");
    const [order, setOrder] = useState("");
    const [totalCount, setTotalCount] = useState(1);
    const [brands, setBrands] = useState([]);
    const [dateRange, setDateRange] = useState(null);
    const [currentDateRange, setCurrentDateRange] = useState(null);
    const [branchesStart, setBranchesStart] = useState(null);
    const [branchesEnd, setBranchesEnd] = useState(null);


    const handleTableChange = (pagination, filters, sorter) => {
        setOrder(sorter?.order);
        setColumnKey(sorter?.columnKey);
    };

    const [status, setStatus] = useState(null);

    const onSubmit = (request) => {
        const search = {};
        if(request.status && request.status.length == 1) setStatus(request.status[0]);
        else setStatus(null);

        if(request.branchesStart) setBranchesStart(request.branchesStart);
        if(request.branchesEnd) setBranchesEnd(request.branchesEnd);

        if(request.searchSelect && request.searchinput) {
            let key = request.searchSelect;
            if(Array.isArray(request.searchSelect)) key = request.searchSelect[0].value;
            if(key.toLowerCase() == 'all') {
                for(const field of searchSelectArray.map(obj => obj.name).filter(obj => obj != 'all')) {
                    search[field] = request.searchinput;
                }
            }
            else {
                search[key] = request.searchinput;
            }
        }
        setSearch(JSON.stringify(search));
        if(dateRange) setCurrentDateRange(dateRange[0]);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(
        t,
        i18n,
        status,
        currentPage,
        pageSize,
        search,
        columnKey,
        order,
        branchesStart,
        branchesEnd,
        currentDateRange?.startDate,
        currentDateRange?.endDate
    );

    useEffect(() => {
        if(brandsData) {
            setBrands(brandsData.brands);
            setTotalCount(brandsData.count);
        }
    }, [brandsData]);

    const [isAddBrandOpen, setIsAddBrandOpen] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    return (
        <div className="bg-inner-color h-full px-5 ">
        <TableHeader
            header={"Brands"}
            btnName={"Add New Brand"}
            handleModal={() => {
                setIsAddBrandOpen(true);
            }}
        />
        <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
            <div className="flex justify-between items-center mb-5">
                <div className="flex-1">
                    <SearchInput
                    register={register}
                    control={control}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    searchSelectArray={searchSelectArray}
                    />
                </div>
                <div className="flex">
                    <FilterButton
                    handleFilter={() => {
                        setIsOpenFilter(!isOpenFilter);
                    }}
                    />
                    <TableMoreOptions setShowUploadModal={setShowUploadModal} />
                </div>
            </div>
            <Table
            rowKey={(record) => record.id}
            data={brands}
            columns={columnHeaders}
            isLoading={brandsLoading}
            total={totalCount}
            currentPage={currentPage}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        if(event.target.tagName.toLowerCase() != "span" && event.target.tagName.toLowerCase() != "svg" && event.target.tagName.toLowerCase() != "li") {
                            setViewBrand(record);
                        }
                    },
                };
            }}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onChange={handleTableChange}
            />
        </div>
        <Filters
            setIsOpen={setIsOpenFilter}
            isOpen={isOpenFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            <BrandsFilters register={register} setDateRange={setDateRange} />
        </Filters>
        <Modal
            isOpen={isAddBrandOpen}
            close={() => {
                setIsAddBrandOpen(false);
            }}
        >
            <AddBrand setIsOpen={setIsAddBrandOpen} destroy={ () => setIsAddBrandOpen(false) }/>
        </Modal>
        <Modal
            isOpen={editBrand !== null}
            close={() => {
                setEditBrand(null);
            }}
        >
            <EditBrand destroy={() => setEditBrand(null)} record={editBrand} />
        </Modal>
        <Modal
            isOpen={viewBrand !== null}
            close={() => {
                setViewBrand(null);
            }}
        > 
            <ViewBrand record={viewBrand} destroy={() => setViewBrand(null)} setEditBrand={setEditBrand} />
        </Modal>
        <Modal
            isOpen={showUploadModal}
            close={() => {
                setShowUploadModal(false);
            }}
        > 
            <UploadBrands destroy={() => setShowUploadModal(null)} />
        </Modal>
        </div> 
    );
}
