import React, { useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import RequestForAccess from "./Pages/RequestForAcess/RequestForAccess";
import Home from "./Pages/Home/Home";
import Login from "./Pages/Login/Login";
import Register from "./Pages/Register/Register";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
import ForgetPasswordConfirm from "./Pages/ForgetPasswordConfirm/ForgetPasswordConfirm";
import InvitationAccepted from "./Pages/InvitationAccepted/InvitationAccepted";
import CheckYourEmail from "./Pages/CheckYourEmail/CheckYourEmail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Users from "./Pages/Users/Users";
import Sidebar from "./Components/Sidebar/Sidebar";
import Topbar from "./Components/Topbar/Topbar";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import EditUser from "./Pages/EditUser/EditUser";
import Products from "./Pages/Products";
import { useFetchUserData } from "./Hooks/reactQuery/useFetchUserData";
import VariationsTypes from "./Pages/VariantTypes";
import VariationsOptions from "./Pages/VariantOptions";
import Customers from "./Pages/Customers";
import Brands from "./Pages/brands";
import Branches from "./Pages/branches";
import MeasurementUnits from "./Pages/measurementUnits";
import Taxes from "./Pages/taxes";
import SystemLog from "./Pages/SystemLog/index";
import Auth from "./Pages/auth/auth";
import { FaSpinner } from "react-icons/fa";

const Layout = () => {
  const { t, i18n } = useTranslation();
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

  return (
    !userData ? <div className="flex w-[100vw] h-[100vh] justify-center items-center">
      <FaSpinner size={50} className="animate-spin"/>
    </div> :
    <>
      <div className="flex" dir={i18n.resolvedLanguage === "ar" ? "rtl" : "ltr"}>
        <div className="min-h-screen">
          <Sidebar />
        </div>
        <div className="grid grid-cols-1 w-full">
          <div className="col-span-1">
            <div>
              <Topbar />
            </div>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default function App() {
  const [token, setToken] = useState(localStorage.getItem("userToken"));

  return (
    <div className="relative">
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="users" element={<Users />} />
          <Route path="products" element={<Products />} />
          <Route path="units" element={<MeasurementUnits />} />
          <Route path="taxes" element={<Taxes />} />
          <Route path="brands" element={<Brands />} />
          <Route path="branches" element={<Branches />} />
          <Route path="customers" element={<Customers />} />
          <Route path="variantTypes" element={<VariationsTypes />} />
          <Route path="variantOptions" element={<VariationsOptions />} />
          <Route path="editUser" element={<EditUser/>}/>
          <Route path="systemLog" element={<SystemLog />} />
        </Route>
        <Route path="login" element={<Login />} />
        <Route path="auth" element={<Auth />} />
        <Route path="rfa" element={<RequestForAccess />} />
        <Route path="login" element={<Login />} />
        <Route path="rfaAccepted" element={<Register />} />
        <Route path="forgetPassword" element={<ForgetPassword />} />
        <Route path="forgetPasswordConfirm" element={<ForgetPasswordConfirm />} />
        <Route path="userInvitationAccept" element={<InvitationAccepted />} />
        <Route path="checkEmail" element={<CheckYourEmail />} />
      </Routes>
      <ToastContainer />
    </div> 
  );
}
