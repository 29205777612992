import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("code")}
      </div>,
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: <div className={`flex w-full items-center justify-start`}>
        {t("title")}
      </div>,
      dataIndex: "localized_variant_option",
      key: "title",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.localized_variant_option.find(title => title.locale == i18n.resolvedLanguage).value}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];