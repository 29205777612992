import React from "react";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input/Input";
import {useMutateResetPassword} from '../../Hooks/reactQuery/useMutateResetPassword'
export default function ForgetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
  } = useForm({
    mode: "all",
  });

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
    /*submit reset password form */
    const {mutate}=useMutateResetPassword();
    const onSubmit =  (request) => {
      mutate(request)
    };
  
  return (
    <div className="min-h-screen  ">
      <div className="flex flex-col">
        <BackToHomeAuth />
        <div className="flex-1 flex flex-col items-center">
              {/* Welcome */}
              <div className=" flex flex-col mt-10 items-center">
              <span className="ml-2 text-xl font-bold text-primary-color ">
                {t("forget_password")}
              </span>
              <span className="text-sm font-medium">
                {t("forget_password_hint")}
              </span>
            </div>
          <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col mt-10 ">
          
           
            {/* forget password form */}
            <div className="border border-gray-300 rounded-lg p-5 mt-6 mb-7">
            <div className="mb-3">
                <Input
                  name="email"
                  type="text"
                  label={t("email")}
                  fieldName={t("email")}
                  hint={t("login_email_hint")}
                  register={register}
                  errors={errors?.email}
                  required={true}
                  pattern={{
                    regex:
                      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                    errorMessage: t("invalid_email"),
                  }}
                />
              </div>
            </div>
            <button
              className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(touchedFields).length < 1 ||
                Object.keys(errors).length > 0
              }
            >
              {t("next_page")}
            </button>
            
            {/* /register/token */}
          </div>
        </div>
      </div>
    </div>
  );
}
