import axios from "axios";
import { toast } from "react-toastify";
const baseUrl = process.env.REACT_APP_API_URL;
// Request interceptor
axios.interceptors.request.use(
  function (config) {
    // const accessToken = document.cookie.includes("Bearer")
    //   ? document.cookie
    //       .split("; ")
    //       .find((row) => row.startsWith("Bearer"))
    //       .split("=")[1]
    //   : null;
const accessToken=localStorage.getItem("userToken")?localStorage.getItem("userToken"):null
    // const CSRFToken = document.cookie.includes("CSRFToken")
    //   ? document.cookie
    //       .split("; ")
    //       .find((row) => row.startsWith("CSRFToken"))
    //       .split("=")[1]
    //   : localStorage.getItem("CSRFToken");

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "Bearer " + accessToken,
      // "X-CSRF-Token": CSRFToken,
    };

    config.headers.common = headers;
    config.baseURL = baseUrl;
    // config.withCredentials = true;
    // config.params = {
    //   ...config.params,
    //   company_id: JSON.parse(localStorage.getItem("activeCompanyId")),
    // };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(error.response) {
      toast.error(error.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER
      });
      if(error.response?.status === 401 || error.response?.status === 403) {
        localStorage.removeItem("userToken");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
