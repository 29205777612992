import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addBrand = async(obj) => {
  return await apiClient?.post(`brands`, obj);

};
export const useMutateAddBrand = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addBrand, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("brands");
    }
  });
};
