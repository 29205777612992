import React from "react";
import { useFetchUser } from "../../Hooks/reactQuery/useFetchUser";
import Table from "../../Components/Table/Table";
import userImg from '../../Icons/user.jpg';
import { useNavigate } from "react-router-dom";
import EditUser from "../EditUser/EditUser";
import { useTranslation } from "react-i18next";

export default function ViewVariantType({ record, destroy, setEditVariantType }) {

  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const columns = [
    {
      title: "#",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.name - b.name,
    },
    {
      title: "Option Title",
      dataIndex: "localized_variant_option",
      key: "localized_variant_option",
      render: (text, record) => <div>
        {record.localized_variant_option.find(obj => obj.locale == i18n.resolvedLanguage).value}
      </div>,
      sorter: (a, b) => a.name - b.name,
    },
  ];
  
  return (
    <div className="py-8">
      <div className=" w-[1008px]">
        <div className="flex">
          <div className="flex flex-col flex-1 mr-10">
            <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Code:</span>
              <span className="ml-1 font-bold">{record?.code}</span>
            </div>
            {record?.localized_variant_type?.map(obj => <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
              <span className="text-gray-400">Title In ({obj.locale}):</span>
              <span className="ml-1 font-bold">
                {obj.value}
              </span>
            </div>)}
            <h1>Options</h1>
            <Table columns={columns} data={record?.options} hasFooter={false} />
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <button
            className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
            >
            Ok
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={()=>{
              destroy();
              setEditVariantType(record);
            }}
          >
            Edit
          </button>{" "}
        </div>          
      </div>
    </div>
  );
}
