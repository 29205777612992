import React, {  useEffect } from "react";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input/Input";
import { useMutateAcceptInvitation } from "../../Hooks/reactQuery/useMutateAcceptInvitation";
import { useNavigate } from "react-router-dom";
export default function InvitationAccepted() {
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  const {
    register,
    handleSubmit,
    formState: { isValid, errors, touchedFields },
    getValues,
  } = useForm({
    mode: "all",
  });

  const navigate = useNavigate();

  /*get token from emailed url  */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlToken = urlParams.get("token");
    if(!urlToken) navigate("/");
    localStorage.setItem("invitationToken", urlToken);
  }, []);
  
  /*submit form */
  const {mutate}=useMutateAcceptInvitation();
  const onSubmit = async (request) => {
      const formData = new FormData();
      formData.append(
        "invitation_token",
        localStorage.getItem("invitationToken")
      );
      for (let key in request) {
        formData.append(key, request[key]);
      }
      mutate(formData)
   
  };

  return (
    <div className="py-8">
      <div className="min-h-screen  ">
        <div className="flex flex-col">
          <BackToHomeAuth />
          <div className="flex-1 flex flex-col items-center mb-5">
            {/* Welcome */}
            <div className=" flex flex-col mt-10 items-center">
              <span className="ml-2 text-xl font-bold text-primary-color ">
                {t("welcome_inframodern")}
              </span>
              <span className="text-sm font-medium">
                {t("complete_account")}
              </span>
            </div>
            <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col mt-5">
              {/* invitation form */}
              <div className="border border-gray-300 rounded-lg p-5 mt-6 mb-7">
                <div className="flex w-full mb-3">
                  <div
                    className={`flex-1 ${
                      i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
                    }`}
                  >
                    <Input
                      name="first_name"
                      type={"text"}
                      label={t("first_name")}
                      fieldName={t("first_name")}
                      register={register}
                      errors={errors?.first_name}
                      required={true}
                      maxLength={20}
                      minLength={3}
                      hint={t("first_name_hint")}
                    />
                  </div>

                  <div className="flex-1">
                    <Input
                      name="last_name"
                      type={"text"}
                      label={t("last_name")}
                      fieldName={t("last_name")}
                      hint={t("last_name_hint")}
                      register={register}
                      errors={errors?.last_name}
                      required={true}
                      maxLength={20}
                      minLength={3}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <Input
                    register={register}
                    type="text"
                    name="phone_number"
                    fieldName={t("phone")}
                    label={t("phone")}
                    hint={t("rfa_phone_hint")}
                    required={true}
                    maxLength="50"
                    pattern={{
                      regex: /^[0-9]*$/,
                      errorMessage: t("no_spaces_just_numbers"),
                    }}
                    errors={errors?.phone_number}
                  />
                </div>
                <div className="mb-3">
                  <Input
                    name="password"
                    type="password"
                    isPassword={true}
                    label={t("password")}
                    fieldName={t("password")}
                    hint={t("login_password_hint")}
                    register={register}
                    errors={errors?.password}
                    required={true}
                    minLength="8"
                    maxLength="16"
                  />
                </div>
                <div className="mb-3">
                  <Input
                    register={register}
                    type="password"
                    isPassword={true}
                    name="confirm_password"
                    fieldName={t("confirm_password")}
                    label={t("confirm_password")}
                    hint={t("confirm_password_hint")}
                    required={true}
                    minLength="8"
                    maxLength="16"
                    validate={{
                      validateRepetition: (value) => {
                        return (
                          value === getValues("password") ||
                          t("password_mismatch")
                        );
                      },
                    }}
                    errors={errors?.confirm_password}
                  />
                </div>
              </div>
              <button
                className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid}

              >
                {t("login")}
              </button>

              {/* /register/token */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
