import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const uploadUsers = async(data) => {
  return await apiClient?.post(`users/upload`, data);

};
export const useMutateUploadUsers = (setUsers) => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(uploadUsers, {
    onSuccess: (data) => {
      setUsers(data?.data?.data);
      queryClient.invalidateQueries("users");
    }
  });
};
