import { useState } from "react";
import "antd/es/pagination/style/css";
import { Table,Pagination } from "antd";
import "antd/es/table/style/css";

const TableComponent = ({
  rowKey,
  columns,
  data,
  total,
  pageSize,
  currentPage,
  onChange,
  onPageChange,
  isloading,
  onTableRowExpand,
  expandedRows,
  expandedRow,
  onRow,
  size,
  rowSelection,
  showFooter=true,
  hasFooter=true,
  isPaginationDisabled,
  


}) => {

  return (

    <Table
      tableLayout="fixed"
      onChange={onChange}
      // pagination={{
      //   position: ["bottomLeft"],
      // }}
      pagination={false}
      loading={isloading}
      scroll={{ x: true }}
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      size={size}
      expandedRowKeys={expandedRows}
      onExpand={onTableRowExpand}
      expandable={{
        expandedRowRender: expandedRow,
        rowExpandable: (record) => record.name !== "Not Expandable",
      }}

      onRow={onRow}
      
      footer={() =>
        hasFooter ? (
            <Pagination
              onChange={onPageChange}
              total={total}
              current={currentPage}
              pageSize={pageSize}
              showSizeChanger={false}
              hideOnSinglePage={false}
              />
        ):""
      }
    />
  );
};

export default TableComponent;
