import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateEditVariantOption } from "../../Hooks/reactQuery/useMutateEditVariantOption";
import { languages } from "../../utils/constants";
import EditVariantOptionModal from "./editVariantOptionModal";

const EditVariantOption = ({ destroy, record }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    title: "",
    code: "",
    variant_type_id: ""
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  useEffect(() => {
    if(record) {
      setValue("title", record.localized_variant_option?.find(obj => obj.locale == languages[0].value)?.value);
      setValue("code", record.code, { shouldValidate: true });
      setValue("variant_type_id", record.variant_type_id.toString());

      setLocalizedTitle(old => {
        const newLocalizedTitle = {...old};
        for(const t of record.localized_variant_option) {
          newLocalizedTitle[t.locale] = t.value;
        }
        return newLocalizedTitle;
      });
    }
  }, [record]);


  const { mutate, isLoading, isSuccess } = useMutateEditVariantOption();

  const onSubmit = async (request) => {
    const data = {
      title: Object.keys(localizedTitle).map(locale => {
        return {
          locale, 
          value: localizedTitle[locale]
        }
      }),
      code: request.code,
      status: 1,
      variant_type_id: request.variant_type_id,
      locale: i18n.resolvedLanguage
    }

    mutate({id: record.id, edit: data});
    destroy();
  };

  return (
    <div>
      <EditVariantOptionModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          record,
          isValid,
          isDirty
        }}
        destroy={destroy}
      />
    </div>
  );
};

export default EditVariantOption;
