import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchUserData = () => {

  return useQuery(["userData"], () => {
    return apiClient
      ?.get(`users/data`)
      .then(({ data }) => {
        return data?.data
      }).catch(error => {
        console.log(error);
      });
  });
};
