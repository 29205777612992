import PasswordHide from "../../Icons/PasswordHide/PasswordHide";
import PasswordShow from "../../Icons/PasswordShow/PasswordShow";
import { useTranslation } from "react-i18next";
import { useState } from "react";
const Input = ({
  fieldName,
  register,
  errors,
  placeHolder,
  required,
  maxLength,
  minLength,
  isPassword,
  type,
  name,
  pattern,
  label,
  hint,
  confirm,
  icon,
  validate,
  onChange,
  onBlur,
  value,
  isDisabled
}) => {
  const { t, i18n } = useTranslation();
  const [textPassword, reverseTextPassword] = useState(true);

  return (
    //Input field
    <div className="">
      {label && (
        <label htmlFor={name} className="text-sm text-body font-medium ">
          {label}
        </label>
      )}
      <div className={`relative ${label||icon?"mt-2":""} `}>
        <div
          className={`input-icon pointer-events-none absolute ${
            i18n.resolvedLanguage === "ar" ? "right-px right-3" : "left-px left-3"
          } top-3`}
        >
          {icon && icon}
        </div>
        <input
          type={
            isPassword && textPassword ? "password" : isPassword ? "text" : type
          }
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeHolder}
          disabled={isDisabled}
          {...(register &&
          register(name, {
            value,
            required: required && {
              value: required,
              message: t("field_required", {field_name: fieldName ? fieldName : ""})
            },
            minLength: minLength && {
              value: minLength,
              message: t("min_chars_validation", {field_name: fieldName, number: minLength}),
            },
            maxLength: maxLength && {
              value: maxLength,
              message: t("max_chars_validation", {field_name: fieldName, number: maxLength}),
            },
            pattern: pattern && {
              value: pattern.regex,
              message: pattern.errorMessage,
            },
            validate: validate && validate,
          }))}
          id={name}
          className={`block w-full focus:outline-none border rounded text-sm text-body font-medium p-2 ${
            i18n.resolvedLanguage === "ar"
              ? `${icon ? "pr-8" : "pr-2"} text-right`
              : `${icon ? "pl-8" : "pl-2"} text-left`
          } ${
            errors
              ? "focus:border-red-500 focus:ring-red-500 text-red-900 border-red-300"
              : "border-outline"
          } 
          ${
            icon ? "pl-8 border-[2.1px] border-gray-400 ":" "
          }
          ${
            placeHolder ? "text-normal" : ""
          }
          ${isDisabled && "cursor-not-allowed disabled opacity-50 bg-[#CBD5E1]"}
          `}
       
        />

        {isPassword && (
          <div
            className={`cursor-pointer absolute inset-y-0 ${
              i18n.resolvedLanguage === "en" ? "right-2" : "left-2"
            } pl-3 flex items-center`}
            onClick={() => reverseTextPassword((prevState) => !prevState)}
          >
            {textPassword ? <PasswordHide /> : <PasswordShow />}
          </div>
        )}
      </div>

      {errors && (
        <p className="text-xs leading-5 font-medium text-error">
          {errors.message}
        </p>
      )}
      {hint && !errors && (
        <p className="mt-1 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </div>
  );
};

export default Input;
