import React, { useEffect, useState } from "react";
import { DateRange, DateRangePicker } from "react-date-range";
import { useTranslation } from "react-i18next";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import DateRangePickerComponent from "../../Components/DateRangePicker/DateRangePicker";
import Input from "../../Components/Input/Input";


export default function ProductsFilters({ register, setDateRange, measurementUnits }) {
  const { t, i18n } = useTranslation();

  const [showType, setShowType] = useState(true);
  const [showMeasurementUnits, setShowMeasurementUnits] = useState(true);
  const [showStatus, setShowStatus] = useState(true);

  const [selectionRange, setSelectionRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: 'selection'
    }
  ]);

  useEffect(() => {
    if(selectionRange[0].startDate && selectionRange[0].endDate) {
      setDateRange(selectionRange.map(obj => {
        return {
          ...obj,
          startDate: new Date(obj.startDate).toISOString().slice(0, 19).replace('T', ' '),
          endDate: new Date(obj.endDate).toISOString().slice(0, 19).replace('T', ' '),
        }
      }));
    }
  }, [selectionRange]);

  return (
    <div>
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Type</span>
        {showType ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={() => {
              setShowType(old => !old)
            }}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={() => {
              setShowType(old => !old)
            }}          
          />
        )}
      </div>
      {showType ? (
        <ul className="">
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="">
                <input
                  className="accent-pink-500"
                  {...register("type")}
                  type="checkbox"
                  value={'fixed'}
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Fixed</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
            <div className="">
                <input
                  className="accent-pink-500"
                  {...register("type")}
                  type="checkbox"
                  value={'hasVariants'}
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Has Variants</label>
              </div>
            </div>
          </li>
        </ul>
      ) : (
        ""
      )}
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Measurement Units</span>
        {showMeasurementUnits ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={() => {
              setShowMeasurementUnits(old => !old)
            }}        
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={() => {
              setShowMeasurementUnits(old => !old)
            }}    
          />
        )}
      </div>
      {showMeasurementUnits ? (
        <ul>
          {measurementUnits.map(obj => <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("measurement_units")}
                    type="checkbox"
                    value={obj.id}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">{obj.localized_measurement_unit.find(u => u.locale === i18n.resolvedLanguage).value}</label>
              </div>
            </div>
          </li>)}
        </ul>
      ) : null}
      <div className="flex justify-between items-center mb-4 p-3">
        <span>Status</span>
        {showType ? (
          <IoIosArrowUp
            className="cursor-pointer"
            onClick={() => {
              setShowStatus(old => !old)
            }}
          />
        ) : (
          <IoIosArrowDown
            className="cursor-pointer"
            onClick={() => {
              setShowStatus(old => !old)
            }}          
          />
        )}
      </div>
      {showStatus ? (
        <ul>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={1}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Enable</label>
              </div>
            </div>
          </li>
          <li className="border-b-2 border-secondary-color">
            <div className="flex items-center  px-3 py-3">
              <div className="flex h-5 items-center">
                <div className=" border-gray-300 rounded">
                  <input
                    className="accent-pink-500"
                    {...register("status")}
                    type="checkbox"
                    value={0}
                  />
                </div>
              </div>
              <div className="ml-3 text-sm">
                <label className="font-medium text-gray-700">Disable</label>
              </div>
            </div>
          </li>
        </ul>
      ) : null}
    </div>
  );
}
