import React, { useEffect, useTransition } from "react";
import { useFetchUser } from "../../Hooks/reactQuery/useFetchUser";
import Table from "../../Components/Table/Table";
import userImg from '../../Icons/user.jpg';
import { useNavigate } from "react-router-dom";
import EditUser from "../EditUser/EditUser";
import { useFetchSingleProduct } from "../../Hooks/reactQuery/useFetchSingleProduct";
import { useTranslation } from "react-i18next";
import { staticBaseUrl } from "../../utils/constants";

export default function ViewProduct({ productId, destroy, setEditProduct }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { data: product, isLoading: userLoading } = useFetchSingleProduct(productId, t, i18n);
  
  return (
    <div className=" w-full overflow-hidden">
      <div className="w-full flex justify-between items-start">
        <div className={`flex flex-col flex-1 ${ product?.images?.length > 0 ? "w-[68%]" : "w-full"}`}>
          <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
            <span className="text-gray-400">Name:</span>
            <span className="ml-1 font-bold">{product?.title}</span>
          </div>
          <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
            <span className="text-gray-400">SKU:</span>
            <span className="ml-1 font-bold">
              {product?.sku}
            </span>
          </div>
          <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3">
            <span className="text-gray-400">UPC:</span>
            <span className="ml-1 font-bold">{product?.upc}</span>
          </div>
          <div className="bg-[#FFF4F8] p-3 rounded-md flex items-center mb-3 ">
            <span className="text-gray-400">measurement Unit</span>
            <span className="ml-1 font-bold">
              {product?.measurement_unit_title}
            </span>
          </div>
        </div>
        {product?.images?.length > 0 ? <div className="flex justify-center items-start w-[28%]">
          <img src={staticBaseUrl + product?.images[0]?.img} className="max-w-[95%] rounded"/>
        </div> : null }
      </div>

      <div className="w-full flex justify-center items-center my-2">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={destroy}
          >
          Ok
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={()=>{
            destroy();
            setEditProduct(productId);
          }}
        >
          Edit
        </button>{" "}
      </div>
    </div>
  );
}
