import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateAddBranch } from "../../Hooks/reactQuery/useMutateAddBranch";
import { languages } from "../../utils/constants";
import AddBranchModal from "./addBranchModal";

const AddBranch = ({ destroy }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: "",
    title: "",
    opening_date: new Date(),
    phone_numbers: [],
    brand_id: "",
    governorate: "",
    city: "",
    district: "",
    street: "",
    block: "",
    bul: "",
    longitude: "",
    latitude: "",
    other_info: ""
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const { mutate, isLoading, isSuccess } = useMutateAddBranch();

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);

  const onSubmit = async (request) => {
    const data = {
      name: Object.keys(localizedTitle).map(locale => {
        return {
          locale, 
          value: localizedTitle[locale]
        }
      }),
      code: request.code,
      brand_id: request.brand_id,
      phone_numbers: request.phone_numbers,
      address: {
        governorate: request.governorate,
        city: request.city,
        district: request.district,
        street: request.street,
        block: request.block,
        bul: request.bul,
        other_info: request.other_info,
        longitude: request.longitude,
        latitude: request.latitude,
      },
      locale: i18n.resolvedLanguage
    }

    mutate(data);
    destroy();
  };

  return (
    <div>
      <AddBranchModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          isValid, 
          isDirty
        }}
        destroy={destroy}
      />
    </div>
  );
};

export default AddBranch;
