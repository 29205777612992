import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchAllProducts = (
    t,
    i18n,
    type,
    measurementUnitIds,
    status,
    pageNo, 
    pageSize, 
    search, 
    columnKey, 
    order, 
  ) => {
  return useQuery([
    "all-products", 
      type,
      measurementUnitIds,
      status,
      pageNo, 
      pageSize, 
      search,
      columnKey, 
      order,
    ], () => {
    const params = { 
      type,
      measurement_unit_ids: measurementUnitIds && measurementUnitIds.length > 0 ? JSON.stringify(measurementUnitIds): JSON.stringify([]),
      status,
      pageNo, 
      pageSize, 
      search, 
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
      locale: i18n.resolvedLanguage
    };
    return apiClient
      ?.get(`products`, {
        params,
      })
      .then(({ data }) => {
        return {
          count: data?.data?.count,
          products: data?.data?.rows?.map(obj => {
            return {
              ...obj,
              title: 
              obj.localized_product_title?.find(title => title.locale == i18n.resolvedLanguage)?.value ? 
              obj.localized_product_title?.find(title => title.locale == i18n.resolvedLanguage)?.value : 
              obj.localized_product_title[0]?.value ? obj.localized_product_title[0]?.value : "",
              measurement_unit_title:
              obj.measurement_unit?.localized_measurement_unit?.find(title => title.locale == i18n.resolvedLanguage)?.value ? 
              obj.measurement_unit?.localized_measurement_unit?.find(title => title.locale == i18n.resolvedLanguage)?.value : 
              obj.measurement_unit?.localized_measurement_unit[0]?.value ? obj.measurement_unit?.localized_measurement_unit[0]?.value : ""
            }
          })
        }
      });
  });
};
