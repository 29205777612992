import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from 'react-toastify';

const editVariantOption = async(obj) => {
    return await apiClient?.patch(`products/variants/options/${obj.id}`, obj.edit);
};

export const useMutateEditVariantOption = (cb = (data) => {}) => {
const queryClient = useQueryClient();
  return useMutation(editVariantOption, {
    onSuccess: (data) => {
        cb(data);
        toast.info(data.data.message, {
          position: toast.POSITION.TOP_CENTER
        }); 
        queryClient.invalidateQueries("variant-options");
    },
  });
};
