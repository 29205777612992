import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchUser = (id) => {

  return useQuery(["user",id], () => {
    return apiClient
      ?.get(`users/${id}`)
      .then(({ data }) => {
        return data?.data
      }).catch(error => {
        console.log(error);
      });
  });
};
