import { useTranslation } from "react-i18next";

const TextArea = ({
rows,
  register,
  name,
  label,
  placeholder,
  hint,
  fieldName,
  defaultValue,
  required,
  minLength,
  maxLength,
  pattern,
  validate,
  errors,
}) => {
    const { t, i18n } = useTranslation();

  return (
    <div>
      <div className="flex justify-between items-center mb-1">
        {label && (
          <label htmlFor={name} className="text-sm text-body font-medium">
            {label}
          </label>
        )}
      </div>
      <div className="mt-2 relative rounded-md shadow-sm">
        <textarea
          rows={rows}
          {...register(name, {
            required: {
                value: required,
                message: t("required_field", {
                  field_name: fieldName ? fieldName : "",
                }),
              },
            minLength: minLength && {
                value: minLength,
                message: t("min_chars_validation", {
                  field_name: fieldName,
                  number: minLength,
                }),
              },
              maxLength: maxLength && {
                value: maxLength,
                message: t("max_chars_validation", {
                  field_name: fieldName,
                  number: maxLength,
                }),
              },
            pattern: pattern && {
              value: pattern.regex,
              message: pattern.errorMessage,
            },
            validate: validate && validate,
          })}
          id={name}
        
          className={`block w-full focus:outline-none border rounded text-sm text-body font-medium p-2 ${
            i18n.resolvedLanguage === "en"
              ? "pl-3 text-left"
              : "pr-3 text-right"
          } ${
            errors
              ? "focus:border-red-500 focus:ring-red-500 text-red-900 border-red-300 pl-10"
              : "border-outline"
          } `}
          placeholder={placeholder}
          defaultValue={defaultValue}
        ></textarea>
   
   
      </div>

      {errors && (
        <p className="text-xs leading-5 font-medium text-error">
          {errors.message}
        </p>
      )}
      {hint && !errors && (
        <p className="mt-1 text-xs leading-5 font-normal text-hints">{hint}</p>
      )}
    </div>
  );
};

export default TextArea;
