import React, { useState, useEffect } from "react";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import Input from "../../Components/Input/Input";
import { useMutateUpdatePassword } from "../../Hooks/reactQuery/useMutateUpdatePassword";

export default function ResetPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    getValues,
  } = useForm({
    mode: "all",
  });

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  /*get token from emailed url  */
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlToken = urlParams.get("token");
    localStorage.setItem("update_password_token", urlToken);
    localStorage.removeItem("request_for_access_token");
  }, []);
  /*submit update password form */
  const { mutate } = useMutateUpdatePassword();
  const onSubmit =  (request) => {
    // const formData = new FormData();
    // formData.append("token", localStorage.getItem("update_password_token"));
    // for (let key in request) {
    //   formData.append(key, request[key]);
    // }
    let updatePasswordToken=localStorage.getItem("update_password_token");
    let data={...request,token:updatePasswordToken};
    mutate(data)
  };

  return (
    <div className="min-h-screen  ">
      <div className="flex flex-col">
        <BackToHomeAuth />
        <div className="flex-1 flex flex-col items-center">
          {/* Welcome */}
          <div className=" flex flex-col mt-10 items-center">
            <span className="ml-2 text-xl font-bold text-primary-color ">
              {t("forget_password")}
            </span>
            <span className="text-sm font-medium">
              {t("forget_password_hint")}
            </span>
          </div>
          <div className="w-4/5 lg:w-1/4 md:w-1/2 h-auto flex flex-col mt-10 ">
            {/* reset password form */}
            <div className="border border-gray-300 rounded-lg p-5 mt-6 mb-7">
            <div className="mb-3">
                <Input
                  name="password"
                  type="password"
                  isPassword={true}
                  label={t("password")}
                  fieldName={t("password")}
                  hint={t("login_password_hint")}
                  register={register}
                  errors={errors?.password}
                  required={true}
                  minLength="8"
                  maxLength="16"
                />
              </div>
              <div className="mb-3">
                <Input
                  register={register}
                  type="password"
                  isPassword={true}
                  name="confirm_password"
                  fieldName={t("confirm_password")}
                  label={t("confirm_password")}
                  hint={t("confirm_password_hint")}
                  required={true}
                  minLength="8"
                  maxLength="16"
                  validate={{
                    validateRepetition: (value) => {
                      return (
                        value === getValues("password") ||
                        t("password_mismatch")
                      );
                    },
                  }}
                  errors={errors?.confirm_password}
                />
              </div>
            </div>
            <button
              className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
              onClick={handleSubmit(onSubmit)}
              disabled={
                Object.keys(touchedFields).length < 2 ||
                Object.keys(errors).length > 0
              }
            >
              {t("confirm_new_password")}
            </button>

            {/* /register/token */}
          </div>
        </div>
      </div>
    </div>
  );
}
