import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchTaxes = (
  t,
  i18n,
  search = null,
  columnKey = "id",
  order = null,
  type = null, 
  status = null,
  startValue = null,
  endValue = null,
  pageNo = null,
  pageSize = null
) => {
  return useQuery([
    "taxes",
    status,
    pageNo,
    pageSize,
    search,
    columnKey,
    order,
    startValue,
    endValue,
    type
  ], () => {
    const params = { 
      status,
      pageNo, 
      pageSize, 
      search, 
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
      type,
      startValue,
      endValue
    };
    return apiClient
      ?.get(`taxes`, { params })
      .then(({ data }) => {
        return {
          count: data?.data?.count,
          taxes: data?.data?.rows.map(obj => {
            return {
              ...obj,
              title: obj.localized_tax_title.find(obj => obj.locale == i18n.resolvedLanguage).value
            }
          })
        }
      });
  });
};
