import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import { Controller } from "react-hook-form";
import Textarea from "../../Components/Input/Textarea";
import { BiCheck } from "react-icons/bi";
import line from "../../Icons/Indigo.png";
import BusinessLogoInput from "../../Components/Input/BusinessLogoInput";
import SelectMenu from "../../Components/Input/Select";
import { useFetchBusinessSizes } from "../../Hooks/reactQuery/useFetchBusinessSizes";
import { useFetchBusinessIndustries } from "../../Hooks/reactQuery/useFetchBusinessIndustries";
export default function BusinessDetails({
  register,
  errors,
  prevStep,
  handleSubmit,
  onSubmit,
  control,
  isLoading,
  isValid,
  getValues,
  watch,
  setValue,
}) {
  const { t, i18n } = useTranslation();
  const [other, setOther] = useState(false);
  useEffect(() => {
    console.log(getValues("how_did_you_hear_about_us"));
    if (getValues("how_did_you_hear_about_us") === "Other") {
      setOther(true);
    } else {
      setOther(false);
      setValue("other", null);
    }
  }, [watch("how_did_you_hear_about_us")]);
  const how_did_you_hear_about_us_options = [
    {
      id: 1,
      option: "Facebook",
    },
    {
      id: 2,
      option: "Instagram",
    },
    {
      id: 5,
      option: "LinkedIn",
    },
    {
      id: 4,
      option: "Google",
    },
    {
      id: 5,
      option: "Other",
    },
  ];
  document.body.dir = i18n.dir();
  const { data: businessIndustries, isLoading: businessIndustriesLoading } =
    useFetchBusinessIndustries(t, i18n);
  const { data: businessSizes, isLoading: businessSizesLoading } =
    useFetchBusinessSizes();
  return (
    <div className="flex-1 flex flex-col h-full items-center pb-10">
      <div className="lg:w-1/2 w-4/5 h-auto flex flex-col   ">
        {/* step indicator */}

        <div className="lg:w-1/2 md:w-3/5	w-4/5  m-auto mt-8 flex relative justify-between mb-20">
          <div className="flex flex-col items-center">
            <div className="bg-pink-500 border-pink-500 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer">
              <BiCheck className="text-white text-2xl" />
            </div>
            <h4 className="font-semibold text-pink-500 ">
              {t("personal_info")}
            </h4>
          </div>
          <div
            className={`absolute right-10 left-10 top-3 -z-50 ${
              i18n.resolvedLanguage === "ar" ? "right-20" : ""
            }`}
          >
            <img src={line} alt="" className="w-full" />
          </div>
          <div className="flex flex-col items-center">
            <div className="border-2 border-pink-500 bg-white rounded-full w-7 h-7 flex justify-center items-center cursor-pointer">
              <div className="bg-pink-600 w-2 h-2 rounded-full"></div>
            </div>
            <h4 className="font-semibold text-pink-500 ">
              {t("business_info")}
            </h4>
          </div>
        </div>
        <BusinessLogoInput register={register} />

        {/* Business Details Form */}
        <div className="border border-gray-300 rounded-lg p-5 mt-5 mb-7">
          <div className="flex w-full mb-3">
            <div
              className={`flex-1 ${
                i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
              }`}
            >
              <Input
                name="business_name"
                type={"text"}
                label={t("business_name")}
                fieldName={t("business_name")}
                register={register}
                errors={errors?.business_name}
                required={true}
                maxLength={20}
                minLength={2}
                hint={t("business_name_hint")}
              />
            </div>
            <div className="flex-1">
              <SelectMenu
                name="business_size_id"
                label={t("business_size")}
                fieldName={t("business_size")}
                isLoading={businessSizesLoading}
                hint={t("business_size_hint")}
                options={
                  businessSizes &&
                  businessSizes.map((item) => {
                    return { label: item.name, value: item.id };
                  })
                }
                Controller={Controller}
                control={control}
                required={true}
                errors={errors?.business_size_id}
              />
            </div>
          </div>
          <div className="flex w-full mb-3">
            <div
              className={`flex-1 ${
                i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
              }`}
            >
              <SelectMenu
                name="business_industry_id"
                label={t("business_industry")}
                fieldName={t("business_industry")}
                hint={t("business_industry_hint")}
                isLoading={businessIndustriesLoading}
                options={
                  businessIndustries &&
                  businessIndustries.map((item) => {
                    return {
                      label: item.name,
                      value: item.id,
                    };
                  })
                }
                Controller={Controller}
                control={control}
                required={true}
                errors={errors?.business_industry_id}
              />
            </div>
            <div className="flex-1">
              <Input
                name="location"
                type={"text"}
                label={t("business_location")}
                fieldName={t("business_location")}
                hint={t("business_location_hint")}
                register={register}
                errors={errors?.location}
                required={true}
              />
            </div>
          </div>
          <div className="mb-3">
            <Textarea
              rows={4}
              name="business_tools"
              type={"text"}
              label={t("business_tools")}
              fieldName={t("business_tools")}
              hint={t("business_tools_hint")}
              register={register}
              errors={errors?.business_tools}
              required={true}
            />
          </div>
          <div className="mb-3">
            <Textarea
              rows={2}
              name="tell_us_more_about_your_business"
              type={"text"}
              label={t("tell_us_more_about_your_business")}
              fieldName={t("tell_us_more_about_your_business")}
              hint={t("tell_us_more_about_your_business_hint")}
              register={register}
              errors={errors?.tell_us_more_about_your_business}
              required={true}
            />
          </div>
          <div className="mb-3">
            <div
              className={`flex-1 ${
                i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
              }`}
            >
              <SelectMenu
                name={"how_did_you_hear_about_us"}
                label={t("how_did_you_hear_about_us")}
                fieldName={t("how_did_you_hear_about_us")}
                hint={t("how_did_you_hear_about_us_hint")}
                options={how_did_you_hear_about_us_options.map((item) => {
                  return {
                    label: item.option,
                    value: item.option,
                  };
                })}
                Controller={Controller}
                control={control}
                required={true}
                errors={errors?.how_did_you_hear_about_us}
              />
            </div>
            {other ? (
              <div className="mb-3">
                <Textarea
                  rows={4}
                  name="other"
                  type={"text"}
                  register={register}
                  required={true}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="mb-5"></div>
        <div className="flex">
          <button
            className={`flex-1 border border-primary-color text-primary-color  py-2 rounded-lg ${
              i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
            }`}
            onClick={prevStep}
          >
            {t("back_page")}
          </button>
          <button
            className={`flex-1 bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
            disabled={!isValid || isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {t("rfa_submit")}
          </button>
        </div>
      </div>
    </div>
  );
}
