import React, { useEffect } from "react";
import ArrowUpDownSvg from "../../Icons/ArrowUpDown/ArrowUpDownSvg";
import Logo from "../../Icons/Logo/Logo";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { staticBaseUrl } from "../../utils/constants";
const WorkSpaceSwitcher = ({ isExpandedSideBar }) => {
  const {t,i18n} = useTranslation();
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

  return (
    <div>
      {isExpandedSideBar ? (
        <div className={`${i18n.resolvedLanguage==='ar'?"flex-row-reverse	":""} flex items-center justify-between border-2 p-2 border-gray-300 rounded-lg font-semibold w-56`}>
          <div className={`flex ${i18n.resolvedLanguage==='ar'? "flex-row-reverse	":""} justify-center items-center`}>
            <span className="mr-2">
              {/*<Logo />*/}
              <img
              className="w-[30px] h-[30px] rounded-full"
              src={userData?.companies[0]?.business_image && userData?.companies[0]?.business_image !== "" ? staticBaseUrl + userData?.companies[0]?.business_image : ""} />
            </span>
            <span>{userData?.companies[0]?.name ? userData?.companies[0]?.name : "Inframodern"}</span>
          </div>
          <span>
            <ArrowUpDownSvg />
          </span>
        </div>
      ) : (
        <div className="flex justify-center  border-2 p-2 border-gray-300 rounded-lg font-semibold w-10 px-3 py-1">
          <span>
            <Logo />
          </span>
        </div>
      )}
    </div>
  );
};

export default WorkSpaceSwitcher;
