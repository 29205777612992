import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { useFieldArray, Controller } from "react-hook-form";
import { useFetchMeasurementUnits } from "../../Hooks/reactQuery/useFetchMeasurementUnits";
import ReactSwitch from "react-switch";
import AddBox from "../../Icons/AddBox/AddBox";
import Select from "react-select";
import { getColumns } from "./tableColumnsHeaders";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { BiCaretDownCircle, BiImageAdd } from "react-icons/bi";
import { Table } from "antd";
import { useFetchVariants } from "../../Hooks/reactQuery/useFetchVariants";
import { languages, staticBaseUrl } from "../../utils/constants";
import EditChild from "./editChildModal";
import { CiBookmarkRemove, CiCircleRemove, CiEdit, CiSquareRemove } from "react-icons/ci";
import apiClient from "../../services/apiClient";
import { FaRemoveFormat } from "react-icons/fa";
import { MdCancel } from "react-icons/md";

export default function EditProductModal({
  destroy,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  setLocalizedTitle,
  localizedTitle,
  clearErrors,
  children,
  setChildren,
  hasVariations,
  setHasVariations,
  isLoading,
  isValid,
  isDirty,
  validState,
  files,
  setFiles,
  product,
  removedImagesIds,
  setRemovedImagesIds,
  addVariants
}) {

  const {t, i18n} = useTranslation();

  const [editChild, setEditChild] = useState(null);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "variants",
  });

  const generateDropdownItems = (record) => [
    {
      key: "1",
      label: "Edit",
      icon: <CiEdit />,
      onClick: () => {
        setEditChild(record.customId)
      },
    },
    {
        key: "1",
        label: "Remove",
        icon: <AiOutlineEyeInvisible />,
        onClick: () => {
          setChildren(old => old.filter(p => p.customId !== record.customId))
        },
    }
  ];

  const [columnHeaders, setColumnHeaders] = useState(getColumns(generateDropdownItems, t, i18n));
  const { data: measurementUnits, isLoading: measurementUnitsLoading } = useFetchMeasurementUnits(t, i18n);
  const [language, setLanguage] = useState(languages[0].value);

  const [variantsSelect, setVariantsSelect] = useState([]);

  const { data: allVariants, isLoading: allVariantsLoading } = useFetchVariants(t, i18n);

  useEffect(() => {
    if(allVariants) {
      setVariantsSelect(allVariants.variants);
    }
  }, [allVariants]);

  const title = watch('title');

  useEffect(() => {
    const langTitle = localizedTitle[language] ? localizedTitle[language] : "";
    setValue(`title`, langTitle);
    clearErrors(`title`);
  }, [language]);

  useEffect(() => {
      if(title && title != '') setLocalizedTitle(old => {
        const newLocalizedTitle = {...old};
        newLocalizedTitle[`${language}`] = title;
        return newLocalizedTitle;
      });
      else setLocalizedTitle(old => {
        const newLocalizedTitle = {...old};
        delete newLocalizedTitle[`${language}`];
        return newLocalizedTitle;
      })
  }, [title]);

  const [order, setOrder] = useState("");
  const [columnKey, setColumnKey] = useState("");

  const handleTableChange = (pagination, filters, sorter) => {
    setOrder(sorter?.order);
    setColumnKey(sorter?.columnKey);
};

  const formatOptionLabel = (data, context) => {
    return (
      <div className="flex justify-between items-center">
        {data.label}
      </div>
    );
  };

  const styles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "unset",
      outline: "none",
      height: "36px",
      width: "100%",
      border: "1px solid #CBD5E1",
      marginRight: "-5px",
      borderRadius: "6px",
      boxShadow: state.menuIsOpen ? "0 0 0 2px #2563eb" : "",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "inherit",
      backgroundColor: (state.isFocused || state.isSelected) && "#F8FAFC",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    menu: (provided) => ({
      ...provided,
      padding: 0,
      position: "absolute",
      width: "100%",
      left: 0,
      zIndex: 1000
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px 10px",      
      maxHeight: "100%",
      overflowY: "auto",
      "::-webkit-scrollbar": {
        width: "10px",
        height: "10px",
      },
      "::-webkit-scrollbar-track": {
        backgroundColor: "#f8fafc",
        borderRadius: "8px 0 0 8px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#cbd5e1",
        border: "1px solid #e7f5ff",
        borderRadius: "10px",
      },
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      position: "absolute",
      left: "47px",
    }),
  }; 

  return (
    <>
      {
      editChild ? 
      <EditChild 
      children={children} 
      setChildren={setChildren}
      current={children.filter(obj => obj.customId == editChild)[0]}
      childId={editChild} 
      destroy={() => setEditChild(null)} />
      : 
      <div className="relative w-full h-full max-h-full">
        <div className="overflow-y-auto h-auto py-8">
          <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
            Edit Product
          </h1>

          <div className="flex w-full justify-between">
            <div className="bg-[#fff] p-2 flex rounded-md flex-col w-[68%]">
              <div className="w-[48%] relative">
                <span className={`absolute top-[35%] left-[80%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
                ${Object.keys(localizedTitle).length > 0 ? "bg-emerald-300	text-emerald-700" : "bg-red-300 text-red-600"}`}>{Object.keys(localizedTitle).length}</span>
                <SelectMenu
                  name={"language"}
                  fieldName={"language"}
                  hint={"Select Language"}
                  label={"Language"}
                  placeholder={""}
                  defaultValue={{label: "English", value: "en"}}
                  options={languages}
                  onChangeHandler={(val) => setLanguage(val)}
                  Controller={Controller}
                  control={control}
                />
              </div>
              <Input
                register={register}
                type="text"
                name="title"
                fieldName={"title"}
                label={`Product Title In ${languages.find(lang => lang.value == language).label}`}
                placeholder={""}
                hint={`Enter Product Title In ${languages.find(lang => lang.value == language).label}`}
                required={false}
                errors={errors?.title}
              />
              <div className="w-full flex justify-between">
                <div className="w-[48%]">
                  <Input
                    register={register}
                    type="text"
                    name="sku"
                    fieldName={"sku"}
                    label={"Stock keeping unit (SKU)"}
                    placeholder={""}
                    hint={"Enter The Stock keeping unit (SKU)"}
                    required={false}
                    validate={{
                      validate: async (value) => {
                        if(value != product.sku) {
                          if(value.length < 1) return "Please Enter A Valid SKU";
                          const { data } = await apiClient.get(
                            "/products/sku",
                            { params: { sku: value } }
                          );
                          if(data.data?.exists) return "Sku Must Be Unique";
                          else return null;
                        }
                        else return null;
                      }
                    }}
                    errors={errors?.sku}
                  />
                </div>
                <div className="w-[48%]">
                  <Input
                    register={register}
                    type="text"
                    name="upc"
                    fieldName={"upc"}
                    label={"Barcode (UPC)"}
                    placeholder={""}
                    hint={t("Enter The Barcode (ISBN, UPC, GTIN, etc..)")}
                    required={false}
                    validate={{
                      validate: async (value) => {
                        if(value != product.upc) {
                          if(value.length < 1) return "Please Enter A Valid UPC";
                          const { data } = await apiClient.get(
                            "/products/upc",
                            { params: { upc: value } }
                          );
                          if(data.data?.exists) return "UPC Must Be Unique";
                          else return null;
                        }
                        else return null;
                      }
                    }}
                    errors={errors?.upc}
                  />
                </div>
              </div>
              <div className="w-[48%]">
                <SelectMenu
                  name={"unit_of_measure_id"}
                  fieldName={"Unit Of Measure"}
                  hint={"Enter The unit of measure"}
                  label={"Unit of measure"}
                  placeholder={""}
                  isLoading={measurementUnitsLoading}
                  placeHolder={""}
                  required={true}
                  options={
                    measurementUnits?.units.map((item) => {
                      return {
                        label: item.title,
                        value: item.id.toString(),
                      };
                    })
                  }
                  Controller={Controller}
                  control={control}
                />
              </div>
            </div>
            <div className="flex justify-end items-start w-[28%] p-2 relative">
              <div className="flex w-full flex-col justify-start items-start">
                <h5>Product Images ({files.length})</h5>
                <div className="w-full flex-col border-2 border-gray-200 border-dashed h-[300px] flex justify-start items-center">
                  <div className="w-full p-2 h-auto overflow-hidden justify-self-start align-self-start">
                    <div className="w-auto flex flex-no-wrap h-[70px] overflow-x-auto overflow-y-hidden pt-[10px]">
                      {product?.images?.length > 0 ? product?.images.filter(obj => !removedImagesIds.includes(obj.id.toString())).map(img => <div className="relative mr-[10px] h-[50px] min-w-[50px]">
                        <button className="absolute top-[-10px] right-[-10px]" onClick={(e) => {
                          e.stopPropagation();
                          setRemovedImagesIds(old => [...old, img.id.toString()]);
                        }}>
                          <MdCancel size={20} />
                        </button>
                        <img className="w-[50px] h-[50px]" src={staticBaseUrl + img?.img} />
                      </div>
                      ) : null}
                      {files.length > 0 ? files.map(img => <div className="relative mr-[10px] h-[50px] min-w-[50px]">
                        <button className="absolute top-[-10px] right-[-10px]" onClick={(e) => {
                          e.stopPropagation();
                          setFiles(old => old.filter(obj => obj.name != img.name));
                        }}>
                          <MdCancel size={20} />
                        </button>
                        <img className="w-[50px] h-[50px] mr-[10px]" src={URL.createObjectURL(img)} />
                      </div>) : null}
                    </div>
                  </div>
                  <label for="file-upload" className="flex flex-col w-full flex-1 cursor-pointer justify-center items-center">
                    <BiImageAdd color="#FB2576" size="50"/>
                    <h8>Upload File From Your Device</h8>
                  </label>
                </div>
                <input
                  type="file"
                  name="file-upload"
                  id={`file-upload`}
                  style={{ display: "none" }}
                  {...register("images_array", {
                    onChange: (e) => {setFiles(old => old.length > 0 ? [...old, ...Array.from(e.target.files)] : Array.from(e.target.files));},
                  })}
                  multiple="multiple"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-start items-start w-full border-solid border border-neutral-400 rounded p-2">
                <div className={`flex items-center justify-center ${hasVariations ? "mb-8" : ""}`}>
                  <ReactSwitch 
                    onChange={(show) => setHasVariations(show)} 
                    checked={hasVariations} 
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor={"#FB2576"}
                  />
                  <p className="m-0 ml-2 font-medium">This Product Has Variations</p>
                </div>

                {hasVariations ? <>
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col">
                      {fields.map((field, index) =>                 
                      <div className="w-full flex justify-between">
                        <div className="w-[48%]">
                          <SelectMenu
                            name={`variants[${index}].variant_type_id`}
                            fieldName={"Variant Type"}
                            hint={"Enter Variant Type"}
                            label={"Variant Type"}
                            placeholder={""}
                            isLoading={false}
                            placeHolder={""}
                            options={
                              variantsSelect.map(obj => {
                                return {
                                  label: obj.title,
                                  value: obj.id
                                }
                              })
                            }
                            Controller={Controller}
                            control={control}
                          />
                        </div>
                        <div className="w-[48%]">
                          <div>
                            <SelectMenu
                              name={`variants[${index}].variant_option_id`}
                              fieldName={"Variant Option"}
                              hint={"Enter Variant Option"}
                              label={"Variant Option"}
                              placeholder={""}
                              isLoading={false}
                              placeHolder={""}
                              options={variantsSelect.find(obj => obj.id == watch(`variants[${index}].variant_type_id`))?.options?.map(obj => {
                                return {
                                  label: obj.title,
                                  value: obj.id
                                }
                              })}
                              Controller={Controller}
                              control={control}
                            />
                          </div>
                        </div>
                      </div>)}
                    </div>
                    <button 
                      className="flex"
                      onClick={() => {
                        for(let v of watch(`variants`)) {
                          setVariantsSelect(arr => arr.filter(obj => obj.id !== v.variant_type_id));
                        }
                        append({ variant_type_id: "", options: [] });
                      }}
                    >
                      <AddBox />
                      <p className="ml-2 font-medium">Add New Variant Type</p>
                    </button>
                    <div className="w-full flex items-center justify-center p-2 mx-2">
                      <button 
                        className="border-solid border-primary-color border py-2 px-8 rounded-lg text-primary-color"
                        onClick={addVariants}
                      >
                        Add Variants
                      </button>
                    </div>
                  </div>
                  <div className="w-full my-2">
                  <Table
                  rowKey={(record) => record.id}
                  dataSource={[...children]}
                  columns={columnHeaders}
                  pagination={false}
                  onChange={handleTableChange}
                  />
                  </div>
                </> : null}
          </div>
        </div>
        <div className="flex justify-center sticky bottom-0 bg-[#fff] p-2 z-1000">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || !validState || isLoading ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || !validState || isLoading}
          >
            {isLoading ? "...Loading" : "Edit"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={() => destroy()}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
      }
    </>   
  );
}
