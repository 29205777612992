import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const addMeasurementUnit = async(obj) => {
  return await apiClient?.post(`/products/measurement_units`, obj);

};
export const useMutateAddMeasurementUnit = () => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(addMeasurementUnit, {
    onSuccess: (data) => {
      toast.info(data.data.message, {
        position: toast.POSITION.TOP_CENTER
      }); 
      queryClient.invalidateQueries("measurement_units");
    }
  });
};
