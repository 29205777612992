import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useMutateAddCustomer } from "../../Hooks/reactQuery/useMutateAddCustomer";
import { useMutateAddVariantType } from "../../Hooks/reactQuery/useMutateAddVariantType";
import { useMutateEditCustomer } from "../../Hooks/reactQuery/useMutateEditCustomer";
import AddCustomerModal from "./addCustomerModal";
import AddVariantTypeModal from "./addCustomerModal";
import EditCustomerModal from "./editCustomerModal";

const EditCustomer = ({ destroy, record }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: record?.code,
    name: record?.name,
    phone_numbers: [],
    emails: [],
    governorate: record?.address?.governorate,
    city: record?.address?.city,
    district: record?.address?.district,
    street: record?.address?.street,
    block: record?.address?.block,
    bul: record?.address?.bul,
    other_info: record?.address?.other_info
  }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });


  useEffect(() => {
    if(record) {
      setValue("code", record.code, { shouldValidate: true });
      setValue("name", record.name);
      setValue("phone_numbers", record.customer_phone_numbers.map(obj => ({number: obj.phone_number, type: obj.type})));
      setValue("emails", record.customer_emails.map(obj => ({value: obj.email})));
      setValue("governorate", record.address.governorate);
      setValue("city", record.address.city);
      setValue("district", record.address.district);
      setValue("street", record.address.street);
      setValue("block", record.address.block);
      setValue("bul", record.address.bul);
      setValue("other_info", record.address.other_info ? record.address.other_info : "");
    }
  }, [record]);

  const { mutate, isLoading, isSuccess } = useMutateEditCustomer();

  const onSubmit = async (request) => {
    if(getValues("emails").length < 1) {
      toast.error("At least one email should be provided", {
        position: toast.POSITION.TOP_CENTER
      }); 
      return;
    }
    
    if(getValues("phone_numbers").length < 1) {
      toast.error("At least one phone number should be provided", {
        position: toast.POSITION.TOP_CENTER
      }); 
      return;
    }

    const data = {
      id: record.id,
      edit: {
        code: request.code,
        name: request.name,
        phone_numbers: request.phone_numbers,
        emails: request.emails.map(obj => obj.value),
        address: {
          governorate: request.governorate,
          city: request.city,
          district: request.district,
          street: request.street,
          block: request.block,
          bul: request.bul,
          other_info: request.other_info
        }
      }
    }
    mutate(data);
    destroy();
  };

  return (
    <div>
      <EditCustomerModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          clearErrors,
          isLoading,
          isValid,
          isDirty
        }}
        record={record}
        destroy={destroy}
      />
    </div>
  );
};

export default EditCustomer;
