import { useSearchParams } from "react-router-dom";
import apiClient from "../../services/apiClient";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";
import { apps } from "../../utils/apps";

const addBranch = async (obj) => {
  return await apiClient?.post(`branches`, obj);
};

export const useMutateAddBranch = () => {
  const queryClient = useQueryClient();
  const [params] = useSearchParams();

  return useMutation(addBranch, {
    onSuccess: (data) => {
      const app = params.get("app");
      const next = params.get("next");

      if (apps[app]) {
        window.location.href = `${apps[app]}${next || ""}`;
      } else {
        toast.info(data.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        queryClient.invalidateQueries("branches");
      }
    },
  });
};
