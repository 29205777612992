import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import {Controller, useForm } from "react-hook-form";
import { useFetchMeasurementUnits } from "../../Hooks/reactQuery/useFetchMeasurementUnits";
import { languages } from "../../utils/constants";

export default function EditChild({
  destroy,
  childId,
  children,
  current,
  setChildren,
}) {

  const {t, i18n} = useTranslation();

  const defaultValues = {
    title: "",
    sku: current.sku,
    upc: current.upc,
    unit_of_measure_id: current.unit_of_measure_id,
  }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
  });

  const { data: measurementUnits, isLoading: measurementUnitsLoading } = useFetchMeasurementUnits(t, i18n);
  const [language, setLanguage] = useState(languages[0].value);
  const [localizedTitle, setLocalizedTitle] = useState({});

  const title = watch('title');

  useEffect(() => {
    setValue(`title`, localizedTitle[language] ? localizedTitle[language] : "");
    clearErrors(`title`);
  }, [localizedTitle, language]);

  useEffect(() => {
    setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      for(const t of current.title) {
        newLocalizedTitle[t.locale] = t.value;
      }
      return newLocalizedTitle;
    });
  }, [current]);

  useEffect(() => {
    if(title && title != '') setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      newLocalizedTitle[`${language}`] = title;
      return newLocalizedTitle;
    });
  }, [title]);


  const onSubmit = async (request) => {
    const newTitle = Object.keys(localizedTitle).map(locale => {
      return {
        locale, 
        value: `${localizedTitle[locale]}`
      }
    });
    
    const newChild = {
      ...current,
      localized_product_title: newTitle,
      title: newTitle,
      sku: request.sku,
      upc: request.upc,
      unit_of_measure_id: request.unit_of_measure_id,
      measurement_unit: measurementUnits?.units?.find(obj => obj.id == getValues("unit_of_measure_id")),
    }

    setChildren(old => {
      return [
        ...old.filter(obj => obj.customId !== current.customId),
        newChild
      ]
    });
    destroy();
  };

  return (
    
    <div>
      <button className="flex justify-center item-center" onClick={destroy}>
        <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
          Back To The Product
        </h1>
      </button>

      <div className="bg-[#fff] p-2 flex rounded-md flex-col">
        <div className="w-[48%] relative">
          <span className={`absolute top-[35%] left-[85%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
          ${Object.keys(localizedTitle).length > 0 ? "bg-emerald-300	text-emerald-700" : "bg-red-300 text-red-600"}`}>{Object.keys(localizedTitle).length}</span>
          <SelectMenu
            name={"language"}
            fieldName={"language"}
            hint={"Select Language"}
            label={"Language"}
            placeholder={""}
            isLoading={measurementUnitsLoading}
            placeHolder={""}
            defaultValue={{label: "English", value: "en"}}
            options={languages}
            onChangeHandler={(val) => setLanguage(val)}
            Controller={Controller}
            control={control}
          />
        </div>
        <Input
          register={register}
          type="text"
          name="title"
          defaultValue={localizedTitle[language]}
          fieldName={"title"}
          label={`Product Title In ${languages.find(lang => lang.value == language).label}`}
          placeholder={""}
          hint={`Enter Product Title In ${languages.find(lang => lang.value == language).label}`}
          required={true}
          errors={errors?.title}
        />

        <div className="w-full flex justify-between">
          <div className="w-[48%]">
            <Input
              register={register}
              type="text"
              name="sku"
              fieldName={"sku"}
              label={"Stock keeping unit (SKU)"}
              placeholder={""}
              hint={"Enter The Stock keeping unit (SKU)"}
              required={true}
              validate={{
                validate: (value) => {
                  return null
                }

              }}
              errors={errors?.sku}
            />
          </div>
          <div className="w-[48%]">
            <Input
              register={register}
              type="text"
              name="upc"
              fieldName={"upc"}
              label={"Barcode (UPC)"}
              placeholder={""}
              hint={t("Enter The Barcode (ISBN, UPC, GTIN, etc..)")}
              required={true}
              validate={{
                validate: (value) => {
                  return null
                }

              }}
              errors={errors?.upc}
            />
          </div>
        </div>

        <div className="w-[48%]">
          <SelectMenu
            name={"unit_of_measure_id"}
            fieldName={"Unit Of Measure"}
            hint={"Enter The unit of measure"}
            label={"Unit of measure"}
            placeholder={""}
            isLoading={measurementUnitsLoading}
            placeHolder={""}
            defaultValue={current?.unit_of_measure_id}
            options={
              measurementUnits?.units.map((item) => {
                return {
                  label: item.title,
                  value: item.id.toString(),
                };
              })
            }
            Controller={Controller}
            control={control}
          />
        </div>
      </div>
      <div className="mt-10 flex justify-center">
        <button
          className={`w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold`}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </button>
        <button
          className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
          onClick={destroy}
        >
          Cancel
        </button>{" "}
      </div>
    </div>
  );
}
