
import React from 'react';

const TableMoreOptions = ({setShowUploadModal}) => {
    return (
        <button 
            className='ml-3 mx-2 cursor-pointer flex justify-center items-center border-2 border-primary-color py-1 px-3 rounded-lg text-primary-color text-md font-bold'
            onClick={() => setShowUploadModal(true)}
        >
        Upload
        </button>
    );
}

export default TableMoreOptions;
