import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";

const editUser = async(obj) => {
    return await apiClient?.patch(`users/${obj.userId}`, obj.edit);
};

export const useMutateEditUser = (cb = (data) => {}) => {
const queryClient = useQueryClient();
  return useMutation(editUser, {
    onSuccess: (data) => {
        cb(data);
        queryClient.invalidateQueries("all-users");
    },
  });
};
