import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { Controller, useFieldArray } from "react-hook-form";
import { languages } from "../../utils/constants";
import { HiPlus } from "react-icons/hi";
import { MdRemoveCircleOutline } from "react-icons/md";
import apiClient from "../../services/apiClient";

export default function EditCustomerModal({
  destroy,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  clearErrors,
  isLoading,
  record,
  isValid,
  isDirty
}) {

  const {t, i18n} = useTranslation();

  const { fields: phoneFields, append: appendPhoneNumber, remove: removePhoneNumber } = useFieldArray({
    control,
    name: "phone_numbers",
  });

  const { fields: emailFields, append: appendEmail, remove: removeEmail } = useFieldArray({
    control,
    name: "emails",
  });

  const generateCode = () => {
    clearErrors("code");
    setValue("code", Date.now().toString(36) + Math.random().toString(36).substr(2), { shouldDirty: true, shouldValidate: true });
  }

  return (
    <>
      <div className="py-8">
        <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
          Edit Customer
        </h1>
        <div className="bg-[#fff] p-2 flex rounded-md flex-col">
          <div className="w-[50%] relative">
            <Input
              register={register}
              type="text"
              name="code"
              fieldName={"Code"}
              label={`Customer Code`}
              placeholder={""}
              hint={`Enter Customer Code`}
              required={true}
              validate={
                {
                  validate: async (value) => {
                    if(record.code !== value) {
                      if(value.length < 1) return "Please Enter A Valid Code";
                      const { data } = await apiClient.get(
                        "/customers/code",
                        { params: { code: value } }
                      );
                      if(data.data?.exists) return "Code Must Be Unique";
                      else return null;
                    }
                    return null;
                  }
                }
              }
              errors={errors?.code}
            />
            <button 
              className={`absolute top-[35%] text-[#FB2576] ${i18n.resolvedLanguage === "ar" ? "left-[5px]" : "right-[5px]"}`}
              onClick={generateCode}
            >
              Generate
            </button>
          </div>
          <div className="w-full">
            <Input
              register={register}
              type="text"
              name="name"
              fieldName={"name"}
              label={"Customer Name"}
              placeholder={""}
              hint={"Enter Customer Name"}
              required={true}
              errors={errors?.name}
            />
          </div>

          <div className="w-full flex justify-between">
            <div className="w-[49%] flex-col my-2">
              <div className="w-full flex-col">
                <div className="w-full flex justify-between items-center my-2">
                  <div className="w-[20%] flex justify-start items-center">
                    <h1>Label</h1>
                  </div>
                  <div className="w-[60%] flex justify-start items-center">
                    <h1>Phone Number</h1>
                  </div>
                  <div className="w-[10%] flex justify-end">
                    <button className="bg-primary-color w-[30px] h-[30px] rounded flex justify-center items-center" onClick={() => appendPhoneNumber()}>
                      <HiPlus color="#fff" />
                    </button>
                  </div>
                </div>
                {phoneFields.map((field, index) =>                 
                  <div className="w-full flex justify-between items-center my-2">
                    <div className="w-[20%]">
                      <SelectMenu
                        name={`phone_numbers[${index}].type`}
                        fieldName={"phone_type"}
                        isLoading={false}
                        options={
                          [
                            {
                              label: "Landline",
                              value: "landline"
                            },
                            {
                              label: "Mobile",
                              value: "mobile"
                            }
                          ]
                        }
                        Controller={Controller}
                        control={control}
                      />
                    </div>
                    <div className="w-[60%]">
                      <Input
                        register={register}
                        type="text"
                        name={`phone_numbers[${index}].number`}
                        fieldName={"phone_number"}
                        required={true}
                      />
                    </div>
                    <div className="w-[10%] h-full flex justify-end">
                      <button className="w-full h-full flex justify-end items-center" onClick={() => removePhoneNumber(index)}>
                        <MdRemoveCircleOutline color="#fb2576" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-[49%] flex-col my-2">
              <div className="w-full flex-col">
                  <div className="w-full flex justify-between items-center my-2">
                    <div className="w-[80%] flex justify-start items-center">
                      <h1>Email</h1>
                    </div>
                    <div className="w-[20%] flex justify-end">
                      <button className="bg-primary-color w-[30px] h-[30px] rounded flex justify-center items-center" onClick={() => appendEmail()}>
                        <HiPlus color="#fff" />
                      </button>
                    </div>
                  </div>
                  {emailFields.map((field, index) =>                 
                    <div className="w-full flex justify-between items-center my-2">
                      <div className="w-[80%]">
                        <Input
                          register={register}
                          type="text"
                          name={`emails[${index}].value`}
                          fieldName={"email"}
                          required={true}
                          validate={{
                            validate: (value) => {
                              return null
                            }
                          }}
                        />
                      </div>
                      <div className="w-[20%] h-full flex justify-end">
                        <button className="w-full h-full flex justify-end items-center" onClick={() => removeEmail(index)}>
                          <MdRemoveCircleOutline color="#fb2576" />
                        </button>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>

          <div className="w-full flex-col border-t-[1px] border-gray-300 py-2">
            <h1>Address</h1>
            <div className="w-full flex justify-between items-start my-2">
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="governorate"
                  fieldName={"governorate"}
                  placeHolder={"Governorate"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "governorate is required" : null;
                    }
                  }}
                  errors={errors?.governorate}
                />
              </div>
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="city"
                  fieldName={"city"}
                  placeHolder={"City"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "city is required" : null;
                    }
                  }}
                  errors={errors?.city}
                />
              </div>
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="district"
                  fieldName={"district"}
                  placeHolder={"District"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "district is required" : null;
                    }
                  }}
                  errors={errors?.district}
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div className="w-[49%]">
                <Input
                  register={register}
                  type="text"
                  name="street"
                  fieldName={"street"}
                  placeHolder={"Street"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "street is required" : null;
                    }
                  }}
                  errors={errors?.street}
                />
              </div>
              <div className="w-[24%]">
                <Input
                  register={register}
                  type="text"
                  name="block"
                  fieldName={"block"}
                  placeHolder={"Block"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "block is required" : null;
                    }
                  }}
                  errors={errors?.block}
                />
              </div>
              <div className="w-[24%]">
                <Input
                  register={register}
                  type="text"
                  name="bul"
                  fieldName={"bul"}
                  placeHolder={"Bul."}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "bul is required" : null;
                    }
                  }}
                  errors={errors?.bul}
                />
              </div>
            </div>
            <div className="w-full mt-2">
              <Input
                register={register}
                type="text"
                name="other_info"
                fieldName={"other_info"}
                placeHolder={"Other Info"}
                required={false}
                errors={errors?.other_info}
              />
            </div>
          </div>
        </div>
        <div className="mt-10 flex justify-center">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || isLoading ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? "...Loading" : "Edit"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={() => destroy()}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
    </>   
  );
}
