import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchVariantOptions = (
  t, 
  i18n,
  status = null,
  pageNo = 1,
  pageSize = 100,
  search = null,
  columnKey = null,
  order = null
  ) => {
  return useQuery([
    "variant-options",
    status,
    pageNo,
    pageSize,
    search,
    columnKey,
    order
  ], () => {
    const params = {
      status,
      pageNo, 
      pageSize, 
      search, 
      orderBy: columnKey && columnKey !== '' ? columnKey : "id", 
      isAsc: order == 'ascend' ? 1 : 0,
      locale: i18n.resolvedLanguage
    };
    return apiClient
      ?.get(`products/variants/options`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          options: data?.data.rows.map(obj => {
            return {
              ...obj,
              title: obj.localized_variant_option.find(obj => obj.locale == i18n.resolvedLanguage).value,
              variant_type_title: obj.variant_type.localized_variant_type.find(obj => obj.locale == i18n.resolvedLanguage).value
            }
          })
        }
      });
  });
};
