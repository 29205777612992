import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient';
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

const uploadCustomers = async(data) => {
  return await apiClient?.post(`customers/upload`, data);

};
export const useMutateUploadCustomers = (setReport) => {
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation(uploadCustomers, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("customers");
      setReport(data.data.message);
    }
  });
};
