import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "../../utils/constants";
const LangsDropDown = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="absolute -top-[120%] right-0 left-0 border-2  border-secondary-color  rounded">
      <ul className=" w-full">
        <div>
          {languages.map((lang) => {
            return (
              <li
                key={lang.id}
                className="bg-white p-3 border-2 rounded border-secondary-color border-r-0 border-l-0 border-t-0 last:border-b-0 cursor-pointer hover:bg-pink-200 font-semibold"
                onClick={() => {
                  i18n.changeLanguage(lang.value);
                  window.location.href = "/";
                }}
              >
                {lang.label}
              </li>
            );
          })}
        </div>
      </ul>
    </div>
  );
};

export default LangsDropDown;
