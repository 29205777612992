import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import apiClient from '../../services/apiClient'
const requestForAccess = async(user) => {
  return await apiClient?.post(`/request_for_access`,user);

};
export const useMutateRequestForAcess = () => {
  let navigate = useNavigate();

  return useMutation(requestForAccess, {
    onSuccess: (data) => {
      localStorage.setItem("request_for_access_token",data?.data.data.token);
      navigate("/checkEmail");
    },
  });
};