
import { useQuery } from "react-query";
import apiClient from '../../services/apiClient'

const fetchJobTitles = async() => {
  return await apiClient?.get(`companies/job_title`);
};
export const useFetchJobTitles = (t, i18n) => {
  return useQuery("job-titles", fetchJobTitles, {
    select: ({data}) => {
      const jobTitle = data?.data?.map((x) => {
        const localized = x.localized_job_title;
        const currentLang = localized.find(
          (y) => i18n.resolvedLanguage == y.locale
        );
        return {
         id:x.id,
          name: currentLang.value,
        };
      });
      return jobTitle;
      
    },
  });
};