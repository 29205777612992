import React, { useState, useEffect, useTransition } from "react";
import FilterButton from "../../Components/Buttons/FilterButton";
import TableMoreOptions from "../../Components/Buttons/TableMoreOptions";
import SearchInput from "../../Components/Input/SearchInput";
import TableHeader from "../../Components/TableHeader/TableHeader";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import Table from "../../Components/Table/Table";
import Modal from "../../Components/Modal/Modal";
import Filters from "../../Components/Filter/Filters";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getColumns } from "./tableColumnsHeaders";
import { useTranslation } from "react-i18next";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import VariantTypesFilters from "../VariantTypes/variantTypesFilters";
import ViewVariantType from "../VariantTypes/viewVariantType";
import AddVariantType from "./addVariantOption";
import { useMutateEditVariantOption } from "../../Hooks/reactQuery/useMutateEditVariantOption";
import { useFetchVariantOptions } from "../../Hooks/reactQuery/useFetchVariantOptions";
import EditVariantOption from "./editVariantOption";
import VariantOptionsFilters from "./variantOptionsFilters";
import ViewVariantOption from "./viewVariantOption";
import UploadVariantOptions from "./uploadModal";

export default function VariationsOptions() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const { register, handleSubmit, control } = useForm({
        mode: "onSubmit",
    });


    const searchSelectArray = [
        {
            label: t("code"),
            name: "code",
        },
        {
            label: t("title"),
            name: "title",
        },
        {
            label: t("all"),
            name: "all",
        },
    ]

    const [editVariantOption, setEditVariantOption] = useState(null);
    const [viewVariantOption, setViewVariantOption] = useState(null);

    const {mutate: mutateEditVariantOption} = useMutateEditVariantOption();


    const generateDropdownItems = (record) => [
        {
            key: "1",
            label: "Edit",
            icon: <CiEdit className="text-red-500" />,
            onClick: () => {
                setEditVariantOption(record);
            },
        },
        {
            key: "2",
            label: record.disabled == 1 ? "Enable" : "Disable",
            icon: <AiOutlineEyeInvisible />,
            onClick: () => {
                mutateEditVariantOption({id: record.id, edit: {
                    status: record.disabled ? "1" : "0",
                    locale: i18n.resolvedLanguage
                } });
            },
        },
    ];

    let pageSize = 20;
    const [columnHeaders, setColumnHeaders] = useState(getColumns(generateDropdownItems, t, i18n));
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState(null);
    const [columnKey, setColumnKey] = useState("");
    const [order, setOrder] = useState("");
    const [variantOptions, setVariantOptions] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const handleTableChange = (pagination, filters, sorter) => {
        setOrder(sorter?.order);
        setColumnKey(sorter?.columnKey);
    };

    const [status, setStatus] = useState(null);

    const onSubmit = (request) => {
        const search = {};
        if(request.status && request.status.length == 1) setStatus(request.status[0]);
        else setStatus(null);

        if(request.searchSelect && request.searchinput) {
            let key = request.searchSelect;
            if(Array.isArray(request.searchSelect)) key = request.searchSelect[0].value;
            if(key.toLowerCase() == 'all') {
                for(const field of searchSelectArray.map(obj => obj.name).filter(obj => obj != 'all')) {
                    search[field] = request.searchinput;
                }
            }
            else {
                search[key] = request.searchinput;
            }
        }
        setSearch(JSON.stringify(search));
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const { data: variantOptionsData, isLoading: variantOptionsLoading } = useFetchVariantOptions(
        t,
        i18n,
        status,
        currentPage,
        pageSize,
        search,
        columnKey,
        order
    );

    useEffect(() => {
        if(variantOptionsData) {
            setVariantOptions(variantOptionsData.options);
            setTotalCount(variantOptionsData.count);
        }
    }, [variantOptionsData]);

    const [isAddVariantOptionOpen, setIsAddVariantOptionOpen] = useState(false);
    const [isOpenFilter, setIsOpenFilter] = useState(false);

    return (
        <div className="bg-inner-color h-full px-5 ">
        <TableHeader
            header={"Variations Options"}
            btnName={"Add New Variant Option"}
            handleModal={() => {
                setIsAddVariantOptionOpen(true);
            }}
        />
        <div className="w-full h-auto bg-white rounded-lg pt-6 pb-3 pr-4 pl-4">
            <div className="flex justify-between items-center mb-5">
            <div className="flex-1">
                <SearchInput
                register={register}
                control={control}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
                searchSelectArray={searchSelectArray}
                />
            </div>
            <div className="flex">
                <FilterButton
                handleFilter={() => {
                    setIsOpenFilter(!isOpenFilter);
                }}
                />
                <TableMoreOptions setShowUploadModal={setShowUploadModal} />
            </div>
            </div>
            <Table
            rowKey={(record) => record.id}
            data={variantOptions}
            columns={columnHeaders}
            isLoading={variantOptionsLoading}
            total={totalCount}
            currentPage={currentPage}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        if(event.target.tagName.toLowerCase() != "span" && event.target.tagName.toLowerCase() != "svg" && event.target.tagName.toLowerCase() != "li") {
                            setViewVariantOption(record);
                        }
                    },
                };
            }}
            pageSize={pageSize}
            onPageChange={onPageChange}
            onChange={handleTableChange}
            />
        </div>
        <Filters
            setIsOpen={setIsOpenFilter}
            isOpen={isOpenFilter}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
        >
            <VariantOptionsFilters register={register} />
        </Filters>
        <Modal
            isOpen={isAddVariantOptionOpen}
            close={() => {
                setIsAddVariantOptionOpen(false);
            }}
        >
            <AddVariantType setIsOpen={setIsAddVariantOptionOpen} destroy={() => setIsAddVariantOptionOpen(false)} />
        </Modal>
        <Modal
            isOpen={editVariantOption !== null}
            close={() => {
                setEditVariantOption(null);
            }}
        >
            <EditVariantOption destroy={() => setEditVariantOption(null)} record={editVariantOption} />
        </Modal>
        <Modal
            isOpen={viewVariantOption !== null}
            close={() => {
                setViewVariantOption(null);
            }}
        > 
            <ViewVariantOption record={viewVariantOption} destroy={() => setViewVariantOption(null)} setEditVariantOption={setEditVariantOption} />
        </Modal>
        <Modal
            isOpen={showUploadModal}
            close={() => {
                setShowUploadModal(false);
            }}
        > 
            <UploadVariantOptions destroy={() => setShowUploadModal(null)} />
        </Modal>
        </div> 
    );
}
