import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
import SelectMenu from "../../Components/Input/Select";
import { Controller, useFieldArray } from "react-hook-form";
import { languages } from "../../utils/constants";
import { useFetchBrands } from "../../Hooks/reactQuery/useFetchBrands";
import { HiPlus } from "react-icons/hi";
import { MdRemoveCircleOutline } from "react-icons/md";
import apiClient from "../../services/apiClient";
import Geocode from "react-geocode";
import SearchMap from "../../Components/Map/SearchMap";

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

Geocode.setApiKey(apiKey);
Geocode.setLanguage("en");

export default function AddBranchModal({
  destroy,
  control,
  register,
  setValue,
  handleSubmit,
  getValues,
  onSubmit,
  errors,
  watch,
  setLocalizedTitle,
  localizedTitle,
  clearErrors,
  isLoading,
  isValid, 
  isDirty
}) {

  const { fields: phoneFields, append: appendPhoneNumber, remove: removePhoneNumber } = useFieldArray({
    control,
    name: "phone_numbers",
  });

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(languages[0].value);

  const title = watch('title');

  useEffect(() => {
    const langTitle = localizedTitle[language] ? localizedTitle[language] : "";
    setValue(`title`, langTitle);
    clearErrors(`title`);
  }, [language]);

  useEffect(() => {
    if(title && title != '') setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      newLocalizedTitle[`${language}`] = title;
      return newLocalizedTitle;
    });
    else setLocalizedTitle(old => {
      const newLocalizedTitle = {...old};
      delete newLocalizedTitle[`${language}`];
      return newLocalizedTitle;
    })
  }, [title]);

  const { data: brandsData, isLoading: brandsLoading } = useFetchBrands(t, i18n);

  const generateCode = () => {
    clearErrors("code");
    setValue("code", Date.now().toString(36) + Math.random().toString(36).substr(2), { shouldDirty: true, shouldValidate: true });
  }

  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  const mapClicked = (e) => {
    setLongitude(e.latLng.lng());
    setLatitude(e.latLng.lat());
  }

  useEffect(() => {
    if(longitude && latitude) {      
      setValue("longitude", longitude);
      setValue("latitude", latitude);

      Geocode.fromLatLng(latitude, longitude).then(
        (response) => {
          const governorate = 
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_1") ? true : false))?.long_name ?
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_1") ? true : false))?.long_name : "N/A";

          const city = 
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_2") ? true : false))?.long_name ?
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_2") ? true : false))?.long_name : "N/A";

          
          const district = 
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_3") ? true : false))?.long_name ?
          response.results[0].address_components.find(obj => (obj.types.find(str => str == "administrative_area_level_3") ? true : false))?.long_name : "N/A";

          const street = 
          response.results[0].address_components.find(obj => obj.types.find(str => str == "route"))?.long_name ?
          response.results[0].address_components.find(obj => obj.types.find(str => str == "route"))?.long_name : "N/A";

          const block = 
          response.results[0].address_components.find(obj => obj.types.find(str => str == "street_number"))?.long_name ?
          response.results[0].address_components.find(obj => obj.types.find(str => str == "street_number"))?.long_name : "N/A";

          const bul = 
          response.results[0].address_components.find(obj => obj.types.find(str => str == "street_number"))?.long_name ?
          response.results[0].address_components.find(obj => obj.types.find(str => str == "street_number"))?.long_name : "N/A";

          setValue("governorate", governorate);
          setValue("city", city);
          setValue("district", district);
          setValue("street", street);
          setValue("block", block);
          setValue("bul", bul);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [longitude, latitude]);

  return (
    <>
      <div className="py-8">
        <h1 className="text-xl font-semibold text-[#0C2248] pb-2">
          Add Branch
        </h1>
        <div className="bg-[#fff] p-2 flex rounded-md flex-col">
          <div className="w-[48%] relative">
            <span className={`absolute top-[35%] left-[85%] z-50 w-6 h-6 flex justify-center items-center rounded-full 
            ${Object.keys(localizedTitle).length > 0 ? "bg-emerald-300	text-emerald-700" : "bg-red-300 text-red-600"}`}>{Object.keys(localizedTitle).length}</span>
            <SelectMenu
              name={"language"}
              fieldName={"language"}
              hint={"Select Language"}
              label={"Language"}
              placeholder={""}
              placeHolder={""}
              defaultValue={{label: "English", value: "en"}}
              options={languages}
              onChangeHandler={(val) => setLanguage(val)}
              Controller={Controller}
              control={control}
            />
          </div>
          <div className="w-full">
            <Input
              register={register}
              type="text"
              name="title"
              fieldName={"title"}
              label={`Brand Title In ${languages.find(lang => lang.value == language).label}`}
              placeholder={""}
              hint={`Enter Brand Title In ${languages.find(lang => lang.value == language).label}`}
              required={true}
              errors={errors?.title}
            />
          </div>
          <div className="w-full flex justify-between">
            <div className="w-[49%] relative">
              <Input
                register={register}
                type="text"
                name="code"
                fieldName={"code"}
                label={"Code"}
                placeholder={""}
                hint={"Enter The Code Of The Branch"}
                required={true}
                validate={
                  {
                    validate: async (value) => {
                      if(value.length < 1) return "Please Enter A Valid Code";
                      const { data } = await apiClient.get(
                        "/branches/code",
                        { params: { code: value } }
                      );
                      if(data.data?.exists) return "Code Must Be Unique";
                      else return null;
                    }
                  }
                }
                errors={errors?.code}
              />
              <button 
                className={`absolute top-[35%] text-[#FB2576] ${i18n.resolvedLanguage === "ar" ? "left-[5px]" : "right-[5px]"}`}
                onClick={generateCode}
              >
                Generate
              </button>
            </div>
            <div className="w-[49%]">
              <Input
                register={register}
                type="date"
                name="opening_date"
                fieldName={"opening_date"}
                label={"Opening Date"}
                placeholder={""}
                hint={"Enter The Branch Opening Date"}
                required={false}
                validate={{
                  validate: (value) => {
                    return null
                  }

                }}
                errors={errors?.opening_date}
              />
            </div>
          </div>
          <div className="w-full flex justify-between my-2">
            <div className="w-[48%] flex-col">
              <div className="w-full flex-col">
                <div className="w-full flex justify-between items-center my-2">
                  <div className="w-[20%] flex justify-start items-center">
                    <h1>Label</h1>
                  </div>
                  <div className="w-[60%] flex justify-start items-center">
                    <h1>Phone Number</h1>
                  </div>
                  <div className="w-[10%] flex justify-end">
                    <button className="bg-primary-color w-[30px] h-[30px] rounded flex justify-center items-center" onClick={() => appendPhoneNumber()}>
                      <HiPlus color="#fff" />
                    </button>
                  </div>
                </div>
                {phoneFields.map((field, index) =>                 
                  <div className="w-full flex justify-between items-center my-2">
                    <div className="w-[20%]">
                      <SelectMenu
                        name={`phone_numbers[${index}].type`}
                        fieldName={"phone_type"}
                        isLoading={false}
                        options={
                          [
                            {
                              label: "Landline",
                              value: "landline"
                            },
                            {
                              label: "Mobile",
                              value: "mobile"
                            }
                          ]
                        }
                        Controller={Controller}
                        control={control}
                      />
                    </div>
                    <div className="w-[60%]">
                      <Input
                        register={register}
                        type="text"
                        name={`phone_numbers[${index}].number`}
                        fieldName={"phone_number"}
                        required={true}
                        validate={{
                          validate: (value) => {
                            return value.length < 2 ? "Phone Number Is Required" : null
                          }
                        }}
                      />
                    </div>
                    <div className="w-[10%] h-full flex justify-end">
                      <button className="w-full h-full flex justify-end items-center" onClick={() => removePhoneNumber(index)}>
                        <MdRemoveCircleOutline color="#fb2576" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-[48%]">
              <SelectMenu
                name={"brand_id"}
                fieldName={"Brand"}
                hint={"Select The Branch Brand"}
                label={"Brand"}
                placeholder={""}
                isLoading={brandsLoading}
                placeHolder={""}
                required={true}
                options={
                  brandsData?.brands.map((item) => {
                    return {
                      label: item.name,
                      value: item.id.toString(),
                    };
                  })
                }
                Controller={Controller}
                control={control}
              />
            </div>
          </div>

          <div className="w-full flex-col border-t-[1px] border-gray-300 py-2">
            <h1>Address</h1>
            <div className="w-full flex justify-between items-start my-2">
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="governorate"
                  fieldName={"governorate"}
                  placeHolder={"Governorate"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "Governorate Is Required" : null
                    }
                  }}
                  errors={errors?.governorate}
                />
              </div>
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="city"
                  fieldName={"city"}
                  placeHolder={"City"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "City Is Required" : null
                    }
                  }}
                  errors={errors?.city}
                />
              </div>
              <div className="w-[33%]">
                <Input
                  register={register}
                  type="text"
                  name="district"
                  fieldName={"district"}
                  placeHolder={"District"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "District Is Required" : null
                    }
                  }}
                  errors={errors?.district}
                />
              </div>
            </div>
            <div className="w-full flex justify-between">
              <div className="w-[49%]">
                <Input
                  register={register}
                  type="text"
                  name="street"
                  fieldName={"street"}
                  placeHolder={"Street"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "Street Is Required" : null
                    }
                  }}
                  errors={errors?.street}
                />
              </div>
              <div className="w-[24%]">
                <Input
                  register={register}
                  type="text"
                  name="block"
                  fieldName={"block"}
                  placeHolder={"Block"}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.Block < 2 ? "Vision Is Required" : null
                    }
                  }}
                  errors={errors?.block}
                />
              </div>
              <div className="w-[24%]">
                <Input
                  register={register}
                  type="text"
                  name="bul"
                  fieldName={"bul"}
                  placeHolder={"Bul."}
                  required={true}
                  validate={{
                    validate: (value) => {
                      return value.length < 2 ? "Bul Is Required" : null
                    }
                  }}
                  errors={errors?.bul}
                />
              </div>
            </div>
            <div className="w-full mt-2">
              <Input
                register={register}
                type="text"
                name="other_info"
                fieldName={"other_info"}
                placeHolder={"Other Info"}
                required={false}
                errors={errors?.other_info}
              />
            </div>
          </div>

          <div className="flex-col w-full h-auto">
            <SearchMap setLongitude={setLongitude} setLatitude={setLatitude} onClick={mapClicked} lng={longitude} lat={latitude} />
          </div>

        </div>
        <div className="mt-10 flex justify-center">
          <button
            className={
              `w-40 bg-primary-color text-white py-2 rounded-lg mr-4 font-semibold ${!isDirty || !isValid || isLoading ? "focus:outline-none disabled:opacity-50" : ""}`
            }
            onClick={handleSubmit(onSubmit)}
            disabled={!isDirty || !isValid || isLoading}
          >
            {isLoading ? "...Loading" : "Add"}
          </button>
          <button
            className={`w-40  border border-[#D1D5DB] bg-white text-black py-2 rounded-lg font-semibold`}
            onClick={() => destroy()}
          >
            Cancel
          </button>{" "}
        </div>
      </div>
    </>   
  );
}
