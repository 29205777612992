import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../Components/Input/Input";
export default function PersonalDetails({
  register,
  errors,
  nextStep,
  isValid,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <div className="flex-1 flex flex-col h-full items-center">
      <div className="lg:w-1/2 w-4/5 h-auto flex flex-col  ">
        {/* step indicator */}
        <div className="lg:w-1/2 md:w-2/3	w-4/5  m-auto mt-8 flex justify-between ">
          <div className="flex flex-col justify-center items-center">
            <div className="border-2 border-pink-500 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer">
              <div className="bg-pink-600 w-2 h-2 rounded-full"></div>
            </div>
            <h4 className="font-semibold text-pink-500 ">
              {t("personal_info")}
            </h4>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div
              className="border-2 border-gray-300 rounded-full w-7 h-7 flex justify-center items-center cursor-pointer"
              onMouseEnter={() => {
                setIsHovered(true)
              }}
              onMouseLeave={()=>{
                setIsHovered(false);
              }}
            >
              <div
                className={`bg-gray-300 w-2 h-2 rounded-full  ${
                  isHovered ? "block" : "hidden"
                } `}
              ></div>
            </div>
            <h4 className="font-semibold text-gray-300 ">
              {t("business_info")}
            </h4>
          </div>
        </div>
        {/* Personal Details Form */}
        <div className="border border-gray-300 rounded-lg p-5 mt-14 mb-7">
          <div className="flex w-full mb-3">
            <div
              className={`flex-1 ${
                i18n.resolvedLanguage === "en" ? "mr-2" : "ml-2"
              }`}
            >
              <Input
                name="first_name"
                type={"text"}
                label={t("first_name")}
                fieldName={t("first_name")}
                register={register}
                errors={errors?.first_name}
                required={true}
                maxLength={20}
                minLength={3}
                hint={t("first_name_hint")}
              />
            </div>
            <div className="flex-1">
              <Input
                name="last_name"
                type={"text"}
                label={t("last_name")}
                fieldName={t("last_name")}
                hint={t("last_name_hint")}
                register={register}
                errors={errors?.last_name}
                required={true}
                maxLength={20}
                minLength={3}
              />
            </div>
          </div>
          <div className="mb-3">
            <Input
              name="email"
              type="text"
              label={t("email")}
              fieldName={t("email")}
              hint={t("rfa_email_hint")}
              register={register}
              errors={errors?.email}
              required={true}
              pattern={{
                regex:
                  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                errorMessage: t("invalid_email"),
              }}
            />
          </div>
          <div className="mb-3">
            <Input
              register={register}
              type="text"
              name="phone_number"
              fieldName={t("phone")}
              label={t("phone")}
              hint={t("rfa_phone_hint")}
              required={true}
              maxLength="50"
              pattern={{
                regex: /^[0-9]*$/,
                errorMessage: t("no_spaces_just_numbers"),
              }}
              errors={errors?.phone_number}
            />
          </div>
         
        </div>
        <button
          className={`w-full bg-primary-color text-white disabled:text-black disabled:bg-disabled-color disabled:cursor-not-allowed py-2 rounded-lg`}
          disabled= {!isValid}
          onClick={nextStep}
        >
          {t("next_page")}
        </button>
      </div>
    </div>
  );
}
