export const languages = [
  {
    id: 1,
    label: "English",
    value: "en",
  },
  {
    id: 2,
    label: "Arabic",
    value: "ar",
  },
];

export const staticBaseUrl = process.env["REACT_APP_STATIC_BASE_PATH"];
