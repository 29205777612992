import React, { useState } from "react";
import PersonalDetails from "./PersonalDetails";
import BusinessDetails from "./BusinessDetails";
import { useForm } from "react-hook-form";
import BackToHomeAuth from "../../Components/Buttons/BackToHomeAuth";
import { useMutateRequestForAcess } from "../../Hooks/reactQuery/useMutateRequestForAcess";


export default function RequestForAccess() {
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    control,
    watch,
    getValues,
    setValue
  } = useForm({
    mode: "all",
    reValidateMode: "all",
  });

  /*handle prev and next pages */
  const [step, setStep] = useState(1);
  const prevStep = () => {
    setStep(step - 1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };
  /*submit form */

  const { mutate, isLoading } = useMutateRequestForAcess();
  const onSubmit = async (request) => {
    let newreq = { ...request };
    if(request?.other){
      newreq["how_did_you_hear_about_us"] = newreq.other
    }
    delete newreq['other'];
    // console.log("after changes",newreq)
    //console.log("after changes",newreq)
    const formData = new FormData();
    formData.append("business_image", newreq.business_image[0]);
    for (let key in newreq) {
      formData.append(key, newreq[key]);
    }
    mutate(formData);

  };

  return (
    <div className="min-h-screen  ">
      <div className="flex flex-col">
        <BackToHomeAuth />
        {step === 1 ? (
          <div className="flex">
            <PersonalDetails
              prevStep={prevStep}
              nextStep={nextStep}
              register={register}
              errors={errors}
              isValid={isValid}
              touchedFields={touchedFields}
            />
          </div>
        ) : (
          <div>
            <BusinessDetails
              prevStep={prevStep}
              register={register}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              errors={errors}
              isValid={isValid}
              touchedFields={touchedFields}
              control={control}
              isLoading={isLoading}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
            />
          </div>
        )}
      </div>
    </div>
  );
}
