const Logo = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F1F5F9" />
      <g clipPath="url(#clip0_1_805)">
        <rect
          x="11.8722"
          y="6.24423"
          width="2.38027"
          height="9.71944"
          rx="1.19014"
          transform="rotate(45 11.8722 6.24423)"
          fill="#FB2576"
        />
        <rect
          x="16.3607"
          y="10.7326"
          width="2.38027"
          height="9.71944"
          rx="1.19014"
          transform="rotate(45 16.3607 10.7326)"
          fill="#FB2576"
        />
        <rect
          x="16.3607"
          y="6.24423"
          width="2.38027"
          height="9.71944"
          rx="1.19014"
          transform="rotate(45 16.3607 6.24423)"
          fill="#FB2576"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_805">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(4 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
