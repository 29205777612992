import { Dropdown } from "antd";
import { FaUserAlt } from "react-icons/fa";
import { FiMoreVertical } from "react-icons/fi";

export const getColumns = (generateDropdownItems, t, i18n) => [
    {
      title: "code",
      dataIndex: "code",
      key: "code",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {text}
      </div>
    },
    {
      title: "phone",
      dataIndex: "customer_phone_numbers",
      key: "phone_number_count",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.customer_phone_numbers[0].phone_number}
        {record.customer_phone_numbers.length - 1 > 0 ? ` ....+${record.customer_phone_numbers.length - 1}` : ""}
      </div>
    },
    {
      title: "email",
      dataIndex: "customer_emails",
      key: "email_count",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.customer_emails[0]?.email}
        {record.customer_emails?.length - 1 > 0 ? ` ....+${record.customer_emails?.length - 1}` : ""}
      </div>
    },
    {
      title: "Source",
      dataIndex: "module",
      key: "module_name",
      sorter: true,
      render: (text, record) => <div style={{opacity: record.disabled == "0" ? "1" : "0.5"}}>
        {record.module.name}
      </div>
    },
    {
      title: "",
      render(text, record) {
        return (
          <Dropdown
            menu={{
              items: generateDropdownItems(record),
            }}
          >
            <FiMoreVertical className="text-primary-color text-xl cursor-pointer" />
          </Dropdown>
        );
      },
    },
];