import { useQuery } from "react-query";
import apiClient from "../../services/apiClient";

export const useFetchBrandCategories = (t, i18n) => {
  return useQuery(["brand_categories", ], () => {
    const params = { };
    return apiClient
      ?.get(`brands/categories`, { params })
      .then(({ data }) => {
        return {
          count: data?.data.count,
          categories: data?.data.rows
        }
      });
  });
};
