import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutateAddBrand } from "../../Hooks/reactQuery/useMutateAddBrand";
import { languages } from "../../utils/constants";
import AddBrandModal from "./addBrandModal";

const AddBrand = ({ destroy }) => {
  const {t, i18n} = useTranslation();

  const defaultValues = {
    code: "",
    title: "",
    vision: "",
    mission: "",
    brand_category: "",
    branches: []
  }

  const [localizedTitle, setLocalizedTitle] = useState([]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: {errors, isDirty, isValid},
    setError,
    watch,
    setValue,
    clearErrors
  } = useForm({
    defaultValues,
    mode: "onChange"
  });

  const { mutate, isLoading, isSuccess } = useMutateAddBrand();

  const [files, setFiles] = useState([]);

  useEffect(() => {
    if(isDirty) {
      if(!Object.keys(localizedTitle).find(key => key === i18n.resolvedLanguage)) setError('title', { type: 'custom', message: `You need to add a title in the currently selected language (${languages.find(obj => obj.value === i18n.resolvedLanguage).label})`});
    }
  }, [localizedTitle]);


  const onSubmit = async (request) => {
    const data = {
      title: JSON.stringify(Object.keys(localizedTitle).map(locale => {
        return {
          locale, 
          value: localizedTitle[locale]
        }
      })),
      code: request.code,
      branch_ids: JSON.stringify(request.branches),
      category_id: request.brand_category,
      vision: request.vision,
      mission: request.mission,
      locale: i18n.resolvedLanguage
    }

    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }

    formData.append("brand_logo", files[0]);
  

    mutate(formData);
    destroy();
  };

  return (
    <div>
      <AddBrandModal
        {...{
          control,
          register,
          defaultValues,
          getValues,
          setValue,
          errors,
          handleSubmit,
          onSubmit,
          watch,
          setLocalizedTitle,
          clearErrors,
          localizedTitle,
          isLoading,
          isValid,
          isDirty
        }}
        destroy={destroy}
        files={files}
        setFiles={setFiles}
      />
    </div>
  );
};

export default AddBrand;
