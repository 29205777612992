import React, { useRef, useState } from "react";
import user from "../../Icons/person.jpg";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { IoMdNotificationsOutline } from "react-icons/io";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetchUserData } from "../../Hooks/reactQuery/useFetchUserData";
import { staticBaseUrl } from "../../utils/constants";
import DropdownMenu from "../Dropdown";
import { BiLogOut, BiMessageSquareEdit } from "react-icons/bi";

const Topbar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: userData, isLoading: isUserDataLoading } = useFetchUserData();

  const buttonRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div className={`bg-white shadow-md py-3 flex justify-end ${i18n.resolvedLanguage==='ar'?"pl-5":"pr-5"} relative`}>
      <div className={`flex items-center  ${i18n.resolvedLanguage==='ar'?"flex-row-reverse":""}`}>
        <div>
          <img className="w-7 h-7 rounded-full" src={userData?.img ? staticBaseUrl + userData.img : ""} alt="Rounded avatar" />
        </div>
        <div className={`flex items-center justify-center ml-2 ${i18n.resolvedLanguage==='ar'?"flex-row-reverse":""}`}>
          <span>{userData?.first_name + " " +userData?.last_name}</span>
          <button ref={buttonRef} className="ml-1 text-black" onClick={(e) => {
            e.stopPropagation();
            setShowMenu(show => !show);
          }}>
            <MdOutlineKeyboardArrowDown />
          </button>
        </div>
        <button onClick={() => navigate("/systemLog")}>
          <IoMdNotificationsOutline className="ml-3 text-2xl" />
        </button>
      </div>
      {showMenu && <DropdownMenu buttonRef={buttonRef} showMenu={showMenu} setShowMenu={setShowMenu} options={[
        {
          icon: <BiMessageSquareEdit />,
          text: "Edit Profile",
          action: () => {
            navigate(`/editUser?id=${userData?.id}`);
          }
        },
        {
          icon: <BiLogOut />,
          text: "Logout",
          action: () => {
            localStorage.removeItem("userToken");
            navigate("/login");
          }
        }
      ]} />}
    </div>
  );
};

export default Topbar;
